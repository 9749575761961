import ColumnHeader from "../ColumnHeader/ColumnHeader";
import styles from './Ratios.module.scss';
import BorderedCard from "../BorderedCard/BorderedCard";
import { Select, Table, Tooltip } from "antd";
import { formatAmount, formatDuration } from '../../../../../../utils/index';
import ENBarChart from "./components/ENBarChart/ENBarChart";
import ENPieChart from "./components/ENPieChart/ENPieChart";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCollaboratorRatios } from "../../../../../../api";
import { useEffect, useState } from "react";
import { getTableColumns } from "./services";
import { ChartIcon, TableIcon } from "../../../../../common/Icons";

const DISPLAY_OPTIONS = [
  {
    value: 'en',
    label: 'Encodage/Nettoyage'
  },
  {
    value: 'nb',
    label: 'Nettoyage/Bilan'
  },
  {
    value: 'ns',
    label: 'Nettoyage/Situation'
  },
];

const TABLE_VIEW = 'TABLE_VIEW';
const CHART_VIEW = 'CHART_VIEW';

export default function Ratios({
  collaboratorId,
  period,
  setDeserveBonus
}) {
  const { token, navCommunity} = useSelector((state) => state.auth);
  const [dataToDisplay, setDataToDisplay] = useState(DISPLAY_OPTIONS[0].value);
  const [dataView, setDataView] = useState(TABLE_VIEW);
  const [ratiosData, setRatiosData] = useState([]);
  const [pieChartsData, setPieChartsData] = useState({
    en: [],
    nb: [],
    es: []
  });
  const [percentages, setPercentages] = useState({
    en: 0,
    nb: 0,
    ns: 0
  });
  const [percentageTotal, setPercentageTotal] = useState(0);

  const getCollaboratorRatiosQuery =  useQuery(
    ["getCollaboratorRatios", token, navCommunity, collaboratorId, period],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorRatios({
            token,
            organizationId: navCommunity.id,
            collaboratorId,
            period
          });
          let data = response.data.data;
          setRatiosData(data);

          const allItems = data.length;
          const clientsRespectingENRatio = data.filter(item => item.isRespectingEncodageNettoyageRatio).length;
          const clientsRespectingNBRatio = data.filter(item => item.isRespectingNettoyageBilanRatio).length;
          const clientsRespectingNSRatio = data.filter(item => item.isRespectingNettoyageSituationRatio).length;

          setPercentages({
            en: allItems > 0 ? clientsRespectingENRatio * 100 / allItems : 0,
            nb: allItems > 0 ? clientsRespectingNBRatio * 100 / allItems : 0,
            ns: allItems > 0 ? clientsRespectingNSRatio * 100 / allItems : 0
          })
          const enPieChartData = [
            {
              name: 'Les dossiers respectant le ratio 2:1',
              value: Math.round(allItems > 0 ? clientsRespectingENRatio * 100 / allItems : 0)
            },
            {
              name: 'les dossiers ne respectant pas le ratio 2:1',
              value: Math.round(allItems > 0 ?  (allItems - clientsRespectingENRatio) * 100 / allItems : 0)
            }
          ];
          const nbPieChartData = [
            {
              name: 'Les dossiers respectant le ratio 4:1',
              value: Math.round(allItems > 0 ?  clientsRespectingNBRatio * 100 / allItems : 0)
            },
            {
              name: 'les dossiers ne respectant pas le ratio 4:1',
              value: Math.round(allItems > 0 ?  (allItems - clientsRespectingNBRatio) * 100 / allItems : 0)
            }
          ];
          const nsPieChartData = [
            {
              name: 'Les dossiers respectant le ratio 4:1',
              value: Math.round(allItems > 0 ?  clientsRespectingNSRatio * 100 / allItems : 0)
            },
            {
              name: 'les dossiers ne respectant pas le ratio 4:1',
              value: Math.round(allItems > 0 ?  (allItems - clientsRespectingNSRatio) * 100 / allItems : 0)
            }
          ];

          setPieChartsData({
            en: enPieChartData,
            nb: nbPieChartData,
            ns: nsPieChartData
          })
        } catch (e) {
          return null;
        }
      }
    }
  );

  useEffect(() => {
    getCollaboratorRatiosQuery.refetch();
  }, [collaboratorId, period]);

  useEffect(() => {
    if (percentages) {
      const pt = (0.8 * percentages.en / 100) + (0.1 * percentages.nb / 100) + (0.1 * percentages.ns / 100);
      setPercentageTotal(pt * 100);
      setDeserveBonus(percentages.en >= 80 && percentages.nb === 100 && percentages.ns === 100);
    }
  }, [percentages]);

  const renderEncodageNettoyageTable = () => {
    return (
      <Table
        className='deposit_docs_by_document_table'
        columns={getTableColumns("nettoyage")}
        dataSource={ratiosData.map(item => ({
          clientName: item.clientName,
          encodage: formatDuration(item.encodageRealized),
          nettoyage: formatDuration(item.nettoyageRealized),
          ratio: formatAmount(item.encodageNettoyageRatio, false),
        }))}
        size='small'
        bordered
        style={{ width: '100%' }}
      />
    );
  };

  const renderNettoyageBilanTable = () => {
    return (
      <Table
        className='deposit_docs_by_document_table'
        columns={getTableColumns("bilan")}
        dataSource={ratiosData.map(item => ({
          clientName: item.clientName,
          nettoyage: formatDuration(item.nettoyageRealized),
          bilan: formatDuration(item.bilanRealized),
          ratio: formatAmount(item.nettoyageBilanRatio, false),
        }))}
        size='small'
        bordered
        style={{ width: '100%' }}
      />
    );
  };

  const renderNettoyageSituationTable = () => {
    return (
      <Table
        className='deposit_docs_by_document_table'
        columns={getTableColumns("situation")}
        dataSource={ratiosData.map(item => ({
          clientName: item.clientName,
          nettoyage: formatDuration(item.nettoyageRealized),
          situation: formatDuration(item.situationRealized),
          ratio: formatAmount(item.nettoyageSituationRatio, false),
        }))}
        size='small'
        bordered
        style={{ width: '100%' }}
      />
    );
  };

  const renderEncodageNettoyageCharts = () => {
    return (
      <div className={styles.charts}>
        <div className={styles.bar_chart}>
          <ENBarChart
            data={ratiosData.map(item => ({ name: item.clientName, ratio: item.encodageNettoyageRatio }))}
            chartName="Encodage/Nettoyage Ratio"
            referenceLineY={2}
          />
        </div>
        <div className={styles.pie_chart}>
          <ENPieChart
            data={pieChartsData.en}
          />
        </div>
      </div>
    );
  };

  const renderNettoyageBilanCharts = () => {
    return (
      <div className={styles.charts}>
        <div className={styles.bar_chart}>
          <ENBarChart
            data={ratiosData.map(item => ({ name: item.clientName, ratio: item.nettoyageBilanRatio }))}
            chartName="Nettoyage/Bilan Ratio"
            referenceLineY={4}
          />
        </div>
        <div className={styles.pie_chart}>
          <ENPieChart
            data={pieChartsData.nb}
          />
        </div>
      </div>
    );
  };

  const renderNettoyageSituationCharts = () => {
    return (
      <div className={styles.charts}>
        <div className={styles.bar_chart}>
          <ENBarChart
            data={ratiosData.map(item => ({ name: item.clientName, ratio: item.nettoyageSituationRatio }))}
            chartName="Nettoyage/Situation Ratio"
            referenceLineY={4}
          />
        </div>
        <div className={styles.pie_chart}>
          <ENPieChart
            data={pieChartsData.ns}
          />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (dataToDisplay === 'en') {
      switch(dataView) {
        case TABLE_VIEW: return renderEncodageNettoyageTable();
        case CHART_VIEW: return renderEncodageNettoyageCharts();
        default: return null;
      }
    } else if (dataToDisplay === 'nb') {
      switch(dataView) {
        case TABLE_VIEW: return renderNettoyageBilanTable();
        case CHART_VIEW: return renderNettoyageBilanCharts();
        default: return null;
      }
    } else if (dataToDisplay === 'ns') {
      switch(dataView) {
        case TABLE_VIEW: return renderNettoyageSituationTable();
        case CHART_VIEW: return renderNettoyageSituationCharts();
        default: return null;
      }
    }

    return null;
  }

  return (
    <div className={styles.container}>
      <ColumnHeader
        titleValue={percentageTotal}
        title="Ratio"
        isTitleValuePositif={(percentages.en >= 80) && (percentages.nb === 100) && (percentages.ns === 100)}
        price="500"
        subtitles={[
          {
            value: percentages.en === 0 ? 0 : formatAmount(percentages.en, false) + '%',
            subtitle: 'Encodage/Nettoyage',
            desc: '2:1',
            isSubtitleValuePositif: percentages.en >= 80
          },
          {
            value: percentages.nb === 0 ? 0 : formatAmount(percentages.nb, false) + '%',
            subtitle: 'Nettoyage/Bilan',
            desc: '4:1',
            isSubtitleValuePositif: percentages.nb === 100
          },
          {
            value: percentages.ns === 0 ? 0 : formatAmount(percentages.ns, false) + '%',
            subtitle: 'Nettoyage/Situation',
            desc: '4:1',
            isSubtitleValuePositif: percentages.ns === 100
          }
        ]}
        />

      <BorderedCard>
        <div className={styles.content}>
          <h4>Respect du temps de traitement par type de documents comptables</h4>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            margin: '12px 0'
          }}>
            <div style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              justifyContent: 'flex-start',
              textAlign: 'left'
            }}>
              <Tooltip title="Tableau">
                <TableIcon
                  size={18}
                  stroke={dataView === TABLE_VIEW ? '#1a90ff' : "#000"}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setDataView(TABLE_VIEW)}
                />
              </Tooltip>
              <Tooltip title="Graphes">
                <ChartIcon
                  size={18}
                  stroke={dataView === CHART_VIEW ? '#1a90ff' : "#000"}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setDataView(CHART_VIEW)}
                />
              </Tooltip>
            </div>
            <Select
              closeMenuOnSelect
              placeholder="Partner"
              options={DISPLAY_OPTIONS}
              value={dataToDisplay}
              onChange={(inputValue) => setDataToDisplay(inputValue)}
              size="small"
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  border: 0,
                  backgroundColor: "transparent",
                }),
              }}
            />
          </div>

          {renderContent()}
        </div>
      </BorderedCard>
    </div>
  )
}
