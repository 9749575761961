import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { PAGINATION_CURRENT_PAGE, PAGINATION_START, SUB_TABS, SUB_TABS_COLUMNS } from "./constants";
import { DatePicker, Input, Switch, Tooltip } from "antd";
import moment from "moment";
import { Button } from "primereact/button";
import './index.scss';
import _, { isEmpty } from 'lodash';
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CheckIcon, IconClose, IconPencil } from "../../common/Icons";
import { formatAmount, renderAvatar } from "../../../utils";
import AnimatedLoadingIcon from "../../common/Icons/AnimatedLoadingIcon";
import Modal from "../Components/Modal/Modal";
import ClientsOutFilters from "../Components/ClientsOutFilters/ClientsOutFilters";
import { getOldClients, saveOldClients } from "../../../api";

export default function CroissanceClientsOutPage() {
  const { token, navCommunity } = useSelector((state) => state.auth);
  const [selectedSubTab, setSelectedSubTab] = useState(SUB_TABS[0]);
  const [bindedSubTabsColumns, setBindedSubTabsColumns] = useState(SUB_TABS_COLUMNS);
  const [showObservationModal, setShowObservationModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [modifiedRows, setModifiedRows] = useState([]);
  const modifiedRowsRef = useRef(modifiedRows);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [currentPage, setCurrentPage] = useState(PAGINATION_CURRENT_PAGE);
  const [pageStart, setPageStart] = useState(PAGINATION_START);
  const [isSaving, setIsSaving] = useState({
    observation: false,
    rowData: false
  });
  const [totalsData, setTotalsData] = useState([{
    'id': 0,
    'date': '-',
    'clientName': 'Total',
    'rdv': '--',
    'prestaTs': 0,
    'orderSigned': 0,
    'lmProposition': 0,
    'client.type': '--',
    'client.billingType': '--',
    'compensation': '€0,00',
    'reasonForDeparture': '--',
    'budgetPreviousYear': '€0,00',
    'budgetCurrentYear': '€0.00',
    'impactCurrentYear': '€0.00',
    'budgetNextYear': '€0.00',
    'observation': '--',
    'mandats': '--',
    'horus': '--',
    'appDeg': '--',
    'coda': '--',
    'cloudbizz': '--',
    'twintaxx': '--',
    'emasphere': '--',
    'exitMeeting': '--',
    'checkFacturation': '--',
    'sendNewAccountant': '--',
    'adminIsDisconfiguration': '--',
    'departCategories': '--'
  }]);
  const [filters, setFilters] = useState({
    clientGroup: [],
    bu: [],
    partner: null,
    collaborator: null,
    role: null,
    pageSize: 10,
    year: new Date().getFullYear().toString(),
  });

  let getOldClientsQuery = useQuery(
    ["getOldClients", token, navCommunity, currentPage, pageStart, filters],
    async () => {
      if (token && navCommunity) {
        setIsLoadingData(true);
        try {
          const response = await getOldClients({
            token,
            organizationId: navCommunity.id,
            start: pageStart,
            filters
          });
          let data = response.data.data.items
          const totals = response.data.data.totals;

          // add attribute to control display/hide of prestatToBeDone
          data = data.map(item => ({
            ...item,
            displayAllPrestatToBeDone: false,
            updateCompensationField: false,
            updateReasonForDepartureField: false,
          }));

          setData(data);
          setDisplayedData(data);
          setTotalDataCount(response.data.data.totalCount);
          setTotalsData(prevState => {
            const newVal = {...prevState[0], ...totals};
            return [newVal];
          });
        } catch (e) {
          console.error("Error while fetching old clients", {e})
          return null;
        } finally {
          setIsLoadingData(false);
        }
      }
    }
  );

  useEffect(() => {
    // bind body templates to columns
    const BINDED_SUB_TABS_COLUMNS = {};

    BINDED_SUB_TABS_COLUMNS.admin = SUB_TABS_COLUMNS.admin.map((col) => {
      if (col.field === 'date') {
        col.body = dateColumnBodyTemplate;
      } else if (col.field === 'clientName') {
        col.body = clientColumnBodyTemplate;
      } else if (col.field === 'rdv') {
        col.body = rdvColumnBodyTemplate;
      } else if (col.field === 'prestaTs') {
        col.body = durationColumnBodyTemplate;
      } else if (col.field === 'todo') {
        col.body = inputFieldColumnBodyTemplate;
      } else if (col.field === 'orderSigned') {
        col.body = checkUncheckColumnBodyTemplate;
      } else if (col.field === 'lmProposition') {
        col.body = checkUncheckColumnBodyTemplate;
      } else if (col.field === 'compensation') {
        col.body = compensationFieldColumnBodyTemplate;
      } else if (col.field === 'reasonForLeaving' || col.field === 'billingType' || col.field === 'selectedPlan') {
        col.body = textColumnBodyTemplate;
      } else if (
        col.field === 'budgetCurrentYear' ||
        col.field === 'budgetPreviousYear' ||
        col.field === 'budgetNextYear' ||
        col.field === 'impactCurrentYear') {
        col.body = amountColumnsBodyTemplate;
      } else if (col.field === 'observation') {
        col.body = observationColumnBodyTemplate;
      }
      return col;
    });

    BINDED_SUB_TABS_COLUMNS.todo = SUB_TABS_COLUMNS.todo.map((col) => {
      if (['mandats', 'horus', 'appDeg', 'coda', 'cloudbizz', 'twintaxx', 'sdWorx', 'emasphere', 'exitMeeting', 'checkFacturation', 'sendNewAccountant', 'adminIsDisconfiguration'].includes(col.field)) {
        col.body = switchColumnsBodyTemplate
      } else if (col.field === 'departCategories') {
        col.body = prestaToBeDoneColumnBodyTemplate;
      }
      return col;
    });
    setBindedSubTabsColumns(BINDED_SUB_TABS_COLUMNS);
  }, []);

  useEffect(() => {
    modifiedRowsRef.current = modifiedRows;
  }, [modifiedRows]);

  const renderSelectedColumns = () => {
    return bindedSubTabsColumns[selectedSubTab.key];
  }

  const dateColumnBodyTemplate = (rowData) => {
    if (rowData.id === 0) return (<span>--</span>);
    if (moment(rowData.disabledDate).isValid()) {
      return <div className="date-cell">{moment(rowData.disabledDate).format("D/M")}</div>;
    }

    if (rowData.isDeleted === true && moment(rowData.deletedAt).isValid()) {
      return <div className="date-cell">{moment(rowData.deletedAt).format("D/M")}</div>;
    }

    if (rowData?.departDates && moment(rowData.departDates?.communicated_departure, "DD-MM-YYYY").isValid()) {
      return <div className="date-cell">
        {moment(rowData.departDates.communicated_departure, "DD-MM-YYYY").format("D/M")}
      </div>;
    }
    return '--';
  }

  const clientColumnBodyTemplate = (rowData) => {
    if (rowData.id === 0) return (<span className="total-title">Total</span>);

    const clientName = rowData.clientName;
    const clientGroupDescription = rowData.groupDescription;
    const managerName = rowData.managerName;
    const managerBU = rowData.managerBu;
    const managerAvatarUrl = rowData.managerAvatarUrl;

    return (
      <div className="client-name-cell">
        <span className="client-name">{clientName}</span>
        <div className="client-other-info">
          <div className="client-type">
            {clientGroupDescription} - {managerBU}
          </div>
          <div className="client-manager-avatar">
            <Tooltip title={managerName}>
              {renderAvatar(managerAvatarUrl, managerName)}
            </Tooltip>
          </div>
        </div>
      </div>
    )
  }

  const durationColumnBodyTemplate = (rowData, args) => {
    if (rowData[args.field]) {
      const totalHours = rowData[args.field];
      const wholeHours = Math.floor(totalHours); // Extract whole hours
      const minutes = Math.round((totalHours - wholeHours) * 60); // Convert fractional hours to minutes
      if (wholeHours !== 0 && minutes !== 0) {
        return `${wholeHours}h ${minutes}m`;
      } else if (wholeHours === 0) {
        return `${minutes}m`;
      } else {
        return `${wholeHours}h`;
      }
    }
    return '0m';
  }

  const rdvColumnBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) return (<span>--</span>);

    let date = null
    const keys = args.field.split('.');
    const hasValue = keys.reduce((obj, key) => obj && obj[key], rowData) &&
    keys.reduce((obj, key) => obj && obj[key], rowData) !== 'x';

    if (hasValue) {
      const [year, month, day] = rowData.rdv.split("-");
      date = new Date(year, month - 1, day);
    }

    return (
      <DatePicker
        value={!hasValue ? null : moment(date, "DD/MM")}
        onChange={(date) => {
          const year = date._d.getFullYear();
          const month = date._d.getMonth() + 1;
          const day = date._d.getDate();
          handleRowModification(rowData, 'rdv', `${year}-${month}-${day}`);
        }}
        format={"DD/MM"}
        picker="day"
        className="cell-datepicker-input"
      />
    )
  }

  const switchColumnsBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) return (<span>--</span>);

    let classes = 'cell-switch-input';
    const keys = args.field.split('.');
    const isChecked = keys.reduce((obj, key) => obj && obj[key], rowData) === true || keys.reduce((obj, key) => obj && obj[key], rowData) === 'x';

    if (isChecked) {
      classes += ' cell-switch-input-checked';
    }

    return (
      <Switch
        size="small"
        checked={isChecked}
        className={classes}
        onChange={(checked) => {
          handleRowModification(rowData, args.field, checked);
        }}
      />
    )
  }

  const inputFieldColumnBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) return (<span>--</span>);
    const fieldValue = rowData[args.field]

    return (
      <div className="cell-input-field">
        <Input
          className="input"
          value={fieldValue}
          name={args.field}
          onChange={(e) => {
            handleRowModification(rowData, args.field, e.target.value);
          }}
        />
      </div>
    )
  }

  const checkUncheckColumnBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) {
      switch(args.field) {
        case "orderSent": return (<span>{rowData.orderSent}</span>);
        case "orderSigned": return (<span>{rowData.orderSigned}</span>);
        case "governance": return (<span>{rowData.governance}</span>);
        case "lmProposition": return (<span>{rowData.lmProposition}</span>);
        default: return (<span>--</span>);
      }
    }

    if (rowData[args.field]) {
      return <div class="centered-cell">
        <CheckIcon width="20" height="12" fill="#15B392" />
      </div>
    } else {
      return <div class="centered-cell">
        <IconClose size="11" fill="#C7253E" />
      </div>
    }
  }

  const compensationFieldColumnBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) {
      return (
        <span>{formatAmount(rowData.compensation, true)}</span>
      );
    };

    if (!rowData.updateCompensationField) {
      return (
        <div className="cell-compensation-field">
          <span>
            {rowData.compensation !== null && rowData.compensation !== undefined ? formatAmount(rowData.compensation, true) : '--'}
          </span>
          <IconPencil
            size={11}
            className="cell-compensation-field-edit-icon"
            onClick={() => {
              handleUpdateCompensationField(rowData);
            }}
          />
        </div>
      );
    } else {
      return (
        <div className="cell-compensation-field">
          <Input
            className="input"
            value={rowData.compensation}
            name={args.field}
            onChange={(e) => {
              handleRowModification(rowData, args.field, e.target.value);
            }}
          />
          <CheckIcon
            width={20}
            height={20}
            className="cell-compensation-field-edit-icon"
            onClick={() => {
              handleUpdateCompensationField(rowData);
            }}
          />
        </div>
      );
    }
  }

  const textColumnBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) {
      return (
        <span>--</span>
      );
    };

    let textValue = null;


    if (!isEmpty(rowData[args.field])) {
      textValue = rowData[args.field];
    }

    return (
      <div className="cell-compensation-field">
        <span>
          {textValue != null ? textValue : '--'}
        </span>
      </div>
    )
  }


  const reasonForLeavingColumnBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) {
      return (
        <span>--</span>
      );
    };

    let reasonForLeavingText = null;


    if (!isEmpty(rowData?.reasonForLeaving)) {
      reasonForLeavingText = rowData?.reasonForLeaving;
    }

    if (!rowData.updateReasonForDepartureField) {
      return (
        <div className="cell-compensation-field">
          <span>
            {reasonForLeavingText != null ? reasonForLeavingText : '--'}
          </span>
          {/* <IconPencil
            size={11}
            className="cell-compensation-field-edit-icon"
            onClick={() => {
              handleUpdateReasonForDepartureField(rowData);
            }}
          /> */}
        </div>
      );
    } else {
      return (
        <div className="cell-compensation-field">
          <Input
            className="input"
            value={rowData.reasonForDeparture}
            name={args.field}
            onChange={(e) => {
              handleRowModification(rowData, args.field, e.target.value);
            }}
          />
          <CheckIcon
            width={20}
            height={20}
            className="cell-compensation-field-edit-icon"
            onClick={() => {
              handleUpdateReasonForDepartureField(rowData);
            }}
          />
        </div>
      );
    }
  }

  const prestaToBeDoneColumnBodyTemplate = (rowData) => {
    if (rowData.id === 0) {
      return '--';
    }

    if (!rowData.departCategories || rowData.departCategories.length === 0) {
      return null;
    }
    let prestaToDisplay = [];
    let restToDisplay = [];
    let rest = null;
    if (rowData.departCategories.length > 3) {
      prestaToDisplay = rowData.departCategories.slice(0, 3);
      restToDisplay = rowData.departCategories.slice(3);
      rest = (
        <div>
          {rowData.departCategories.slice(3).map(item => (<p key={item.id_category}>{item.label}</p>))}
        </div>
      )
    } else {
      prestaToDisplay = rowData.departCategories;
    }

    return (
      <div className="presta-to-be-done">
        {prestaToDisplay.map((item) => (
          <Tooltip key={item.id_category} title={item.label} placement="right">
            <p className="presta-to-be-done-item">{item.label}</p>
          </Tooltip>
        ))}

        {rowData.displayAllPrestatToBeDone && (
          restToDisplay.map((item) => (
            <Tooltip key={item.id_category} title={item.label} placement="right">
              <p className="presta-to-be-done-item">{item.label}</p>
            </Tooltip>
          ))
        )}

        {rest !== null && !rowData.displayAllPrestatToBeDone && (
          <Tooltip title={rest} placement="right">
            <p
              className="presta-to-be-done-item-rest"
              onClick={() => handleShowRestOfPrestaToBeDone(rowData)}
            >
              ...
            </p>
          </Tooltip>
        )}
        {rowData.displayAllPrestatToBeDone && (
          <Tooltip title="Cacher">
            <p
              className="presta-to-be-done-item-rest"
              onClick={() => handleShowRestOfPrestaToBeDone(rowData)}
            >
              ^
            </p>
          </Tooltip>
        )}
      </div>
    )
  }

  const amountColumnsBodyTemplate = (rowData, args) => {
    return (<div>{formatAmount(rowData[args.field])}</div>);
  }

  const observationColumnBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) {
      return <span>--</span>;
    }

    return (
      <div className="edit-texts-icons">
          <Tooltip title="Ajouter/Modifier une remarque">
            <Button
              icon="pi pi-file-edit"
              rounded
              text
              outlined
              raised
              severity="info"
              aria-label="File Edit"
              onClick={() => handleShowObservationModal(true, rowData)}
            />
          </Tooltip>
        </div>
    );
  }

  const handleShowRestOfPrestaToBeDone = (rowData) => {
    setDisplayedData((prevData) =>
      prevData.map((item) =>
        item.id === rowData.id ? { ...item, displayAllPrestatToBeDone: !item.displayAllPrestatToBeDone } : item
      )
    );
  }

  const handleUpdateCompensationField = (rowData) => {
    setDisplayedData((prevData) =>
      prevData.map((item) =>
        item.id === rowData.id ? { ...item, updateCompensationField: !item.updateCompensationField } : item
      )
    );
  }

  const handleUpdateReasonForDepartureField = (rowData) => {
    setDisplayedData((prevData) =>
      prevData.map((item) =>
        item.id === rowData.id ? { ...item, updateReasonForDepartureField: !item.updateReasonForDepartureField } : item
      )
    );
  }

  const handleShowObservationModal = (isVisible, rowData) => {
    if (rowData) {
      setSelectedRow(rowData);
    } else {
      setSelectedRow(null);
    }
    setShowObservationModal(isVisible);
  }

  const handleRowModification = (rowData, modifiedPropertyName, modifiedPropertyValue) => {
    const keys = modifiedPropertyName.split('.');
    const updatedRowData = {
      ...rowData,
      [modifiedPropertyName]: modifiedPropertyValue
    };

    // Set the new value in the cloned rowData
    setValue({ ...rowData }, keys, modifiedPropertyValue);

    // Update the modifiedRows state

    setModifiedRows(prevState => {
      // Check if the row already exists in modifiedRows
      const modifiedRowIndex = prevState.findIndex(i => i.id === rowData.id);

      if (modifiedRowIndex > -1) {
        // Update existing modified row
        const updatedModifiedRows = [...prevState];
        updatedModifiedRows[modifiedRowIndex] = { ...updatedModifiedRows[modifiedRowIndex], ...updatedRowData };
        return updatedModifiedRows;
      } else {
        // Add new modified row
        return [...prevState, updatedRowData];
      }
    });

    // Update the displayedData state
    setDisplayedData(prevState =>
      prevState.map(i =>
        i.id === rowData.id ? { ...rowData, [modifiedPropertyName]: modifiedPropertyValue } : i
      )
    );
  }

  const setValue = (obj, keys, value) => {
    keys.reduce((accumulator, key, index) => {
      // If we're at the last key, set the value
      if (index === keys.length - 1) {
        accumulator[key] = value;
      } else {
        // If the key doesn't exist, create an empty object
        if (!accumulator[key]) {
          accumulator[key] = {};
        }
        return accumulator[key]; // Move to the next nested object
      }
      return accumulator;
    }, obj);
  };

  const handleSaveModifiedRows = () => {
    setIsSaving(prevState => ({...prevState, rowData: true}));

    saveOldClients({token, organizationId: navCommunity.id, items: modifiedRows}).then((res) => {
      if (res.status === 200) {
        setModifiedRows([]);
        toast.success("Les données ont été enregistrées avec succès");
        getOldClientsQuery.refetch();
      } else {
        toast.error("Une erreur est survenue lors de l'enregistrement des données");
      }
    }).catch(e => {
      toast.error("Une erreur est survenue lors de l'enregistrement des données");
      console.error("Error saving data : ", e);
    }).finally(() => {
      setIsSaving(prevState => ({...prevState, rowData: false}));
    });
  }

  const handleSaveObservation = () => {
    const { id, clientId, todo, observation } = selectedRow;

    setIsSaving(prevState => ({...prevState, observation: true}));
    saveOldClients({token, organizationId: navCommunity.id, items: [{id, clientId, todo, observation}]}).then((res) => {
      if (res.status === 200) {
        setSelectedRow(null);
        setShowObservationModal(false);
        toast.success("Commentaire enregistré avec succès.");
        getOldClientsQuery.refetch();
      } else {
        toast.error("Échec de l'enregistrement de l'observation. Veuillez réessayer.");
      }
    }).catch(e => {
      toast.error("Échec de l'enregistrement de l'observation. Veuillez réessayer.");
      console.error("Error saving l'observation : ", e);
    }).finally(() => {
      setIsSaving(prevState => ({...prevState, observation: false}));
    });
  }

  useEffect(() => {
    setCurrentPage(0);
    setPageStart(0);
  }, [filters.pageSize]);

  return (
    <div className="clients-out-container">
       <div className="datatable-header">
        <div className="datatable-header-row">
          <div className="filters" style={{display: "flex", gap: "8px"}}>
            <ClientsOutFilters
              filterFormIsVisible={false}
              filter={filters}
              nbResult={10}
              updateFilter={setFilters}
              fetching={getOldClientsQuery.isFetching}
            />
          </div>
        </div>
        <div className="datatable-header-row">
          <p className="data-count">
            Nombre de dossiers: <span>{totalDataCount}</span>
          </p>
          <div className="display-info-buttons">
            {SUB_TABS.map((tab) => (
              <button
                key={tab.key}
                className={`display-info-button ${selectedSubTab.key === tab.key ? 'active' : ''}`}
                onClick={() => setSelectedSubTab(tab)}>
                {tab.label}
              </button>
            ))}
          </div>
        </div>
      </div>
      <DataTable
        value={displayedData}
        frozenValue={totalsData}
        lazy
        paginator
        totalRecords={totalDataCount}
        first={currentPage * filters.pageSize + 1}
        rows={filters.pageSize}
        onPage={(e) => {
          setCurrentPage(e.page);
          setPageStart(e.page * filters.pageSize);
        }}
        scrollable
        scrollHeight="900px"
        tableClassName="clients-out-datatable"
        loading={isLoadingData}
        loadingIcon={
          <div className="ripple_loading" style={{alignItems: 'center'}}>
            <img src={"/img/Ripple-1s-150px.svg"} alt="" width={80} height={80} />
          </div>
        }
      >
        {
          renderSelectedColumns().map(col => (
            <Column
              className="datatable-column"
              key={col.field}
              field={col.field}
              header={col.header}
              style={{width: col.style?.width ?? 'auto'}}
              sortable={col.sortable ?? false}
              body={col.body ?? null}
              alignHeader={col?.alignHeader ?? 'left'}
              resizeable={col?.resizeable ?? false}
              align={col?.align ?? 'center'}
            />
          ))
        }
      </DataTable>

      {/* save/cancel buttons */}
      {modifiedRows.length > 0 && (
        <div className="action-buttons">
          <Button
            label="Annuler"
            severity="secondary"
            size="small"
            onClick={() => {
              setModifiedRows([]);
              setDisplayedData(_.cloneDeep(data));
            }}
            disabled={isSaving.rowData}
          />
          <Button
            size="small"
            label={
              isSaving.rowData ? (
                <AnimatedLoadingIcon color="#ffffff" />
              ) : 'Enregistrer'
            }
            disabled={isSaving.rowData}
            severity="success"
            onClick={handleSaveModifiedRows}
          />
        </div>
      )}

      {/* todo/observation dialog */}
      {showObservationModal && selectedRow !== null && (
        <Modal
          isVisible={true}
          title="Ajouter/Modifier une remarque"
          value={selectedRow.observation}
          onValueChanges={(e) => {
            const value = e.target.value;
            setSelectedRow(prevState => ({...prevState, observation: value}));
          }}
          onHide={() => {
            handleShowObservationModal(false)
          }}
          inputLabel="Remarque"
          isSavingChanges={isSaving.observation}
          onConfirm={handleSaveObservation}
        />
      )}
    </div>
  )
}
