import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useOnClickOutside, renderTeamYears } from "../../../utils";
import { getCollaboratorsSuggestions } from "../../../api";
import { TiArrowSortedDown } from "react-icons/ti";
import TTPFilterHorizontalBar from "../../common/Filter/TTPFilterHorizontalBar";
import _ from "../../../i18n";
import { BALANCES_STATUS } from "../../../config";
const BalancesRecapFilter = ({
  token,
  filter,
  nbResult,
  organization,
  updateFilter,
}) => {
  const ref = useRef();
  console.log("filter colla:", filter.collaborator, filter.partner);
  useOnClickOutside(ref, () => setYearDivIsVisible(false));
  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [collaboratorOptions, setCollaboratorOptions] = useState([]);

  let collaboratorsdQuery = useQuery(
    ["getCollaborators", token, organization],
    async () => {
      if (token && organization) {
        try {
          const response = await getCollaboratorsSuggestions(
            token,
            organization
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
              };
            });
          setCollaboratorOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div className={"customed_select_header"}>
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span
                className={"action"}
                onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
              >
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => {
                    handleChangeYear(item);
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleChangeYear = (year) => {
    updateFilter({
      ...filter,
      year: year,
    });
  };

  const handleCollaboratorChange = (item) => {
    updateFilter({ ...filter, collaborator: Number(item) });
  };
  const handlePartnerChange = (partner) => {
    updateFilter({ ...filter, partner: Number(partner) });
  };

  const handleStatusBalanceChange = (items) => {
    updateFilter({ ...filter, status: items });
  };

  useEffect(() => {
    console.log("Collaborators updated:", collaboratorOptions);
  }, [collaboratorOptions]);

  let filters = [
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isMulti: false,
        isClearable: true,
        isSearch: true,
        placeholder: _("partner"),
        options: filter.partnerOptions,
        values: filter.partner,
        action: handlePartnerChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isMulti: false,
        isClearable: true,
        isSearch: true,
        placeholder: _("collaborator"),
        options: collaboratorOptions,
        values: filter.collaborator,
        action: handleCollaboratorChange,
      },
    },
    {
      grid: 3,
      type: "SELECT",
      options: {
        isSimple: true,
        placeholder: _("status"),
        options: BALANCES_STATUS,
        action: handleStatusBalanceChange, 
        values: filter.sortBy,
      },
    },
  ];

  let countFilter = 0;
  filters.forEach((filter) => {
    if (filter && filter.options.values && filter.options.values.length > 0) {
      countFilter++;
    }
  });

  return (
    <div className="row align-center medium-12">
      <div className="medium-12">
        {/* <TTPFilterHorizontalBar
          button
          subBG="F8F9FA"
          searchBox
          filter={filter}
          filters={filters}
          nbResult={nbResult}
          countFilter={countFilter}
          pageSize={10}
          customAddOn={renderCustomAddOn()}
          updateFilterContent={updateFilter}
          cssClass="medium-12"
          fetching={collaboratorOptions.isFetching}
        /> */}
        <TTPFilterHorizontalBar
          button
          bg="#FFF"
          subBG="F8F9FA"
          searchBox
          filter={filter}
          filters={filters}
          nbResult={nbResult}
          countFilter={countFilter}
          pageSize={filter.pageSize}
          customAddOn={renderYearSelect()}
          updateFilterContent={updateFilter}
          // resetFilterContent={resetFilter}
          cssClass="medium-12"
        />
      </div>
    </div>
  );
};

export default BalancesRecapFilter;
