import styles from './index.module.scss';
import RespectProcessingDeadlines from './components/RespectProcessingDeadlines/RespectProcessingDeadlines';
import RespectProcessingTime from './components/RespectProcessingTime/RespectProcessingTime';
import Ratios from './components/Ratios/Ratios';
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import FoldersQuality from './components/FoldersQuality/FoldersQuality';
import BorderedCard from './components/BorderedCard/BorderedCard';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import classNames from 'classnames';
import { useOuterClick } from '../../../../utils/hooks';
import { CheckFilledIcon, UnCheckFilledIcon, CheckListIcon, InfoCircleFilledIcon } from '../../../common/Icons';
import { minutesToTime, renderAvatar } from '../../../../utils';
import { useQuery } from 'react-query';
import { getCollaboratorNFHoursData } from '../../../../api';

const SIDEBAR_CHECK_LIST = [
  {
    key: 'nf5_1',
    title: 'Formation : Nettoyage hebdomadaire',
    description: '(NF5) : 3 heures par semaine, soit 12 heures par mois.',
    isCompleted: true,
    isOpened: false,
    weeklyValue: 0,
    monthlyValue: 0
  },
  {
    key: 'nf5_2',
    title: 'Formation : Bilan mensuel',
    description: '(NF5) : 4 heures par mois.',
    isCompleted: false,
    isOpened: false,
    weeklyValue: 0,
    monthlyValue: 0
  },
  {
    key: 'nf6',
    title: 'Réunions hebdomadaires',
    description: '(NF6) : 0,75 heure par semaine, soit 3 heures par mois.',
    isCompleted: false,
    isOpened: false,
    weeklyValue: 0,
    monthlyValue: 0
  },
  {
    key: 'nf4',
    title: "Besoin d'assistance",
    description: '(NF4) : 1,5 heure par semaine pour les juniors, soit 6 heures par mois.',
    isCompleted: true,
    isOpened: false,
    weeklyValue: 0,
    monthlyValue: 0
  },
  {
    key: 'ma5',
    title: 'Production et suivi de production',
    description: '(MA5) : 1 heure par semaine pour le TP, 4 heures par semaine pour la production, soit 5 heures par mois en total.',
    isCompleted: false,
    isOpened: false,
    weeklyValue: 0,
    monthlyValue: 0
  },
];

export default function BonusMAPage({
  collaborator,
  period
}) {
  const [bonusValues, setBonusValues] = useState({
    respectProcessingDeadlines: {
      bonus: 250,
      deserveIt: false,
    },
    respectProcessingTime: {
      bonus: 250,
      deserveIt: false,
    },
    ratios: {
      bonus: 500,
      deserveIt: false,
    },
    foldersQuality: {
      bonus: 500,
      deserveIt: false,
    }
  });
  const { token, navCommunity} = useSelector((state) => state.auth);
  const [showSidebar, setShowSidebar] = useState(false);
  const [sidebarChecksList, setSidebarChecksList] = useState(SIDEBAR_CHECK_LIST);
  const [data, setData] = useState({});

  const innerRef = useOuterClick(() => {
    setShowSidebar(false);
  });

  const getCollaboratorNFHoursDataQuery = useQuery(
    ["getCollaboratorNFHoursData", token, navCommunity, collaborator, period],
    async () => {
      if (token && navCommunity && collaborator) {
        try {
          const response = await getCollaboratorNFHoursData({
            token,
            organizationId: navCommunity.id,
            collaboratorId: collaborator.id,
            period
          });

          setData(response.data.data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  useEffect(() => {
    if (Object.keys(data).length === 0) {
      return;
    }
    setSidebarChecksList(prevState => {
      const currentYear = (new Date()).getFullYear();
      let currentMonth = (new Date()).getMonth() + 1;
      if (currentYear != period) {
        currentMonth = 12;
      }
      const nf5_1 = prevState.find(item => item.key === 'nf5_1');
      const nf5_2 = prevState.find(item => item.key === 'nf5_2');
      const nf6 = prevState.find(item => item.key === 'nf6');
      const nf4 = prevState.find(item => item.key === 'nf4');
      const ma5 = prevState.find(item => item.key === 'ma5');

      nf5_1.monthlyValue = 0;
      nf5_2.monthlyValue = 0;
      nf6.monthlyValue = 0;
      nf4.monthlyValue = 0;
      ma5.monthlyValue = 0;

      let numberOfMonths = 0;
      for (let i = 1; i <= currentMonth; i++) {
        const monthData = data[`${period}/${i.toString().padStart(2, "0")}`];
        // TODO: fix this two values...
        nf5_1.monthlyValue += monthData.nf5;
        nf5_2.monthlyValue += monthData.nf5;
        nf4.monthlyValue += monthData.nf4;
        nf6.monthlyValue += monthData.nf6;
        ma5.monthlyValue += monthData.ma5;
        numberOfMonths++;
      }

      const nf5_2_val = nf5_1.monthlyValue - (12 * 60 * numberOfMonths);
      nf5_1.monthlyValue = (nf5_2_val > 0 ? nf5_1.monthlyValue - nf5_2_val : nf5_1.monthlyValue) / 12;
      nf5_2.monthlyValue = nf5_2_val > 0 ?  nf5_2_val : 0;
      nf6.monthlyValue /= numberOfMonths;
      nf4.monthlyValue /= numberOfMonths;
      ma5.monthlyValue /= numberOfMonths;

      nf5_1.weeklyValue = nf5_1.monthlyValue / 4;
      nf5_2.weeklyValue = nf5_2.monthlyValue / 4;
      nf6.weeklyValue = nf6.monthlyValue / 4;
      nf4.weeklyValue = nf4.monthlyValue / 4;
      ma5.weeklyValue = ma5.monthlyValue / 4;

      nf5_1.isCompleted = nf5_1.monthlyValue === 12 && nf5_1.weeklyValue === 3;
      nf5_2.isCompleted = nf5_2.monthlyValue === 4;
      nf6.isCompleted = nf6.monthlyValue === 3 && nf6.weeklyValue === 0.75;
      nf4.isCompleted = nf4.monthlyValue === 6 && nf4.weeklyValue === 1.5;
      ma5.isCompleted = ma5.monthlyValue === 5 && ma5.weeklyValue === 1.25;


      return [
        nf5_1,
        nf5_2,
        nf6,
        nf4,
        ma5
      ];
    })
  }, [data]);

  useEffect(() => {
    getCollaboratorNFHoursDataQuery.refetch();
  }, [collaborator, period]);

  const handleDeserveRespectProcessingDeadlinesBonus = (deserveIt) => {
    setBonusValues(prevState => ({
      ...prevState,
      respectProcessingDeadlines: {
        ...prevState.respectProcessingDeadlines,
        deserveIt
      }
    }));
  }

  const handleDeserveRespectProcessingTimeBonus = (deserveIt) => {
    setBonusValues(prevState => ({
      ...prevState,
      respectProcessingTime: {
        ...prevState.respectProcessingTime,
        deserveIt
      }
    }));
  }

  const handleDeserveRatiosBonus = (deserveIt) => {
    setBonusValues(prevState => ({
      ...prevState,
      ratios: {
        ...prevState.ratios,
        deserveIt
      }
    }));
  }

  const handleDeserveFoldersQualityBonus = (deserveIt) => {
    setBonusValues(prevState => ({
      ...prevState,
      foldersQuality: {
        ...prevState.foldersQuality,
        deserveIt
      }
    }));
  }

  const calculateBonusValue = () => {
    let bonusValue = 0;

    if (bonusValues.respectProcessingDeadlines.deserveIt) {
      bonusValue += bonusValues.respectProcessingDeadlines.bonus;
    }
    if (bonusValues.respectProcessingTime.deserveIt) {
      bonusValue += bonusValues.respectProcessingTime.bonus;
    }
    if (bonusValues.ratios.deserveIt) {
      bonusValue += bonusValues.ratios.bonus;
    }
    if (bonusValues.foldersQuality.deserveIt) {
      bonusValue += bonusValues.foldersQuality.bonus;
    }

    return bonusValue;
  }

  const renderNFHoursBubble = () => {
    return (
      <div className={styles.setting_bubbles}>
        <div
          className={classNames({
            [styles.noFill]: true,
            [styles.active]: showSidebar,
            [styles.opened]: showSidebar,
          })}
          onClick={() => setShowSidebar(!showSidebar)}
        >
          <CheckListIcon size="20" fill="#6D7F92" />
          <span style={{ paddingLeft: "2.5rem" }}>
            % et/ou Heures NF
          </span>
        </div>
      </div>
    );
  };


  if (!collaborator) {
    return (
      <div className="ripple_loading">
        <img src={"/img/Ripple-1s-150px.svg"} alt="" />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      {!showSidebar ? renderNFHoursBubble() : null}
      <section className={styles.header_section}>
        <div className={styles.header_filters}>
          {renderAvatar(collaborator.avatarUrl, collaborator.name)}
          {collaborator?.name}
        </div>
        <BorderedCard className={styles.bonus_container}>
          <p className={styles.bonus_text}>Montant total de la prime</p>
          <span className={styles.bonus_value}>{calculateBonusValue()} DH</span>
        </BorderedCard>
      </section>

      <section className={styles.main_section}>
        <div className={styles.column}>
          <RespectProcessingDeadlines
            period={period}
            collaboratorId={collaborator?.id}
            setDeserveBonus={handleDeserveRespectProcessingDeadlinesBonus}
          />
        </div>

        <div className={styles.column}>
          <RespectProcessingTime
            period={period}
            collaboratorId={collaborator?.id}
            setDeserveBonus={handleDeserveRespectProcessingTimeBonus}
          />
        </div>

        <div className={styles.column}>
          <Ratios
            period={period}
            collaboratorId={collaborator?.id}
            setDeserveBonus={handleDeserveRatiosBonus}
          />
        </div>

        <div className={styles.column}>
          <FoldersQuality
            period={period}
            collaboratorId={collaborator?.id}
            setDeserveBonus={handleDeserveFoldersQualityBonus}
          />
        </div>
      </section>

      {showSidebar && (
        <aside className={styles.sidebar} ref={innerRef}>
          <h4 className={styles.sidebar_title}>% et/ou Heures NF</h4>

          <div className={styles.checks}>
            {sidebarChecksList.map(item => (
              <BorderedCard>
                <div
                  className={styles.check_item}
                  onClick={() => {
                    const newVal = sidebarChecksList.map(checkItem => {
                      if (checkItem.key === item.key) {
                        return {...checkItem, isOpened: !checkItem.isOpened};
                      } else {
                        return {...checkItem}
                      }
                    })
                    setSidebarChecksList(newVal)
                  }}
                >
                  <div className={styles.check_title}>
                    {item.isCompleted ?
                      (<CheckFilledIcon size={20} color='#00a000'/>) :
                      (<UnCheckFilledIcon size={17} color='#d50000'/>)
                    }
                    <p className={styles.check_label}>{item.title}</p>
                    {item.isOpened ?
                      (<TiArrowSortedUp
                        size={20}
                        color='#29394D'
                        className={styles.check_info_icon}
                      />) :
                      (<TiArrowSortedDown
                        size={20}
                        color='#29394D'
                        className={styles.check_info_icon}
                      />)
                    }
                  </div>

                  <div className={styles.realized_values}>
                    <p className={styles.realized_title}>
                      Réalisé:
                    </p>
                    <p className={styles.realized_per_week}>
                      {minutesToTime(item.weeklyValue)}/semaine
                    </p>
                    <p className={styles.realized_per_month}>
                      {minutesToTime(item.monthlyValue)}/mois
                    </p>
                  </div>

                  {item.isOpened && (
                    <div className={styles.check_description}>
                      {item.description}
                    </div>
                  )}
                </div>
              </BorderedCard>
            ))}

            <div className={styles.realized_values}>
              <p className={styles.realized_title}>
                Le total réalisé:
              </p>
              <p className={styles.realized_per_week}>
                {minutesToTime(sidebarChecksList.reduce((total, item) => total + item.weeklyValue, 0))}/semaine
              </p>
              <p className={styles.realized_per_month}>
                {minutesToTime(sidebarChecksList.reduce((total, item) => total + item.monthlyValue, 0))}/mois
              </p>
            </div>

            <div className={styles.total_remark_text}>
              <InfoCircleFilledIcon size={12}/>
              <p>Le total général des heures par mois est <span>d'un maximum 30 heures</span>.</p>
            </div>

          </div>
        </aside>
      )}
    </div>
  )
  }
