import axios from "axios";
import { TTP_API_URL } from "../../config";

export const getLastHourCollaboratorsContribution = (
  token,
  organization,
  partner,
  year
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/collaborators-contribution`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      partner: partner,
      year: year,
    },
  });
};

export const getPartnerStatistics = (token, organization, partner, year) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/statistics`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      partner: partner,
      year: year,
    },
  });
};

export const getBinomesList = (
  token,
  organization,
  partner,
  year,
  period,
  start,
  end
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/binomes-list`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization.id,
      partner: partner,
      year: year,
      period: period,
      start: start,
      end: end,
    },
  });
};

export const getProductionCapacity = (
  token,
  collaborators,
  organization,
  partner,
  year,
  period,
  start,
  end
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/production-capacity`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization.id,
      collaborators,
      partner: partner,
      year: year,
      period: period,
      start: start,
      end: end,
    },
  });
};

export const countPlans = (
  token,
  collaborators,
  organization,
  partner,
  year,
  start,
  end
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/count-plans`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization.id,
      collaborators,
      partner: partner,
      year: year,
      start: start,
      end: end,
    },
  });
};

export const getLastAbsences = (
  token,
  collaborators,
  organization,
  partner,
  year
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/calendar`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization.id,
      collaborators,
      partner: partner,
      year: year,
    },
  });
};

export const getErrorReportCounter = (
  token,
  collaborators,
  organization,
  partner,
  year
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/error-report`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization.id,
      collaborators,
      partner: partner,
      year: year,
    },
  });
};

export const getBalanceProduction = (
  token,
  collaborators,
  organization,
  partner,
  year
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/balance`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization.id,
      collaborators: collaborators,
      partner: partner,
      year: year,
    },
  });
};

export const getBinomeFinancials = (
  token,
  collaborators,
  organization,
  partner,
  year,
  start,
  end
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/financial`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      collaborators,
      partner: partner,
      year,
      start,
      end,
    },
  });
};

export const getScheduleProgression = (
  token,
  organization,
  partner,
  year,
  type
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/schedule-progression`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization?.id,
      partner: partner,
      year: year,
      type: type,
    },
  });
};

export const getProfitProgression = (
  token,
  organization,
  partner,
  year,
  type
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/profit-progression`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization?.id,
      partner: partner,
      year: year,
      type: type,
    },
  });
};

export const getCPProgression = (token, organization, partner, year, zone) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/production-progression`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization?.id,
      partner: partner,
      year: year,
      zone: zone,
    },
  });
};

export const getNonBillableContribution = (
  token,
  organization,
  collaborator,
  partner,
  year,
  role
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/non-billable-tasks`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      collaborator: collaborator,
      partner: partner,
      year: year,
      role: role,
    },
  });
};

export const getClientsIN = (token, organization, partner, year) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/client-in`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      partner: partner,
      year: year,
    },
  });
};

export const getClientsOut = (token, organization, partner, year) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/client-out`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      partner: partner,
      year: year,
    },
  });
};

export const getDevelopmentPlans = (token, organization, partner, year) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/dev-plan`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      partner: partner,
      year: year,
    },
  });
};

export const getBonusCount = (token, organization, partner, year) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/bonus`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      partner: partner,
      year: year,
    },
  });
};

export const getClientsReview = (token, organization, partner, year) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/client-review`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      partner: partner,
      year: year,
    },
  });
};

export const getClientOrderSignature = (token, organization, partner, year) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/order-signature`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      partner: partner,
      year: year,
    },
  });
};
