import { formatAmount } from '../../../../../../utils';
import { CheckFilledIcon, TriangleDownIcon, TriangleUpIcon, UnCheckFilledIcon } from '../../../../../common/Icons';
import BorderedCard from '../BorderedCard/BorderedCard';
import styles from './ColumnHeader.module.scss';

export default function ColumnHeader({
  titleValue,
  title,
  isTitleValuePositif,
  subtitleValue,
  subtitle,
  isSubtitleValuePositif,
  price,
  subtitles
}) {
  const redColor = '#d50000';
  const greenColor = '#00a000';
  const titleIcon = isTitleValuePositif ?
    <CheckFilledIcon size={24} color={greenColor} /> :
    <UnCheckFilledIcon size={20} color={redColor} />;
  const titleColor = isTitleValuePositif ? 'green' : 'red';

  return (
    <div className={styles.container}>
      <BorderedCard>
        <div className={`${styles.column_header}`}>
          <div className={`${styles.title_value} ${styles['title_value_' + titleColor]}`}>
            {formatAmount(titleValue, false) + '%'}
            {titleIcon}
          </div>
          <span className={styles.price_tag}>
            {price} DH
          </span>
          <p className={styles.title}>
            {title}
          </p>
        </div>
      </BorderedCard>

      {subtitleValue != null && subtitle != null && (
        <BorderedCard>
          <div className={styles.column_sub_header}>
            <div className={styles.subtitle_value}>
              {subtitleValue}
              {
                isSubtitleValuePositif ?
                  <TriangleUpIcon color={greenColor} /> :
                  <TriangleDownIcon size={22} color={redColor} />
              }
            </div>
            <p className={styles.title}>
              {subtitle}
            </p>
          </div>
        </BorderedCard>
      )}

      {subtitles !== null && subtitles?.length > 0 && (
        <div className={styles.subtitles}>
          {
            subtitles.map(item => (
              <BorderedCard>
                  <div className={styles.column_sub_header}>
                    <div className={styles.subtitle_value}>
                      {item.value}
                      {
                        item.isSubtitleValuePositif ?
                          <TriangleUpIcon size={20} color={greenColor} /> :
                          <TriangleDownIcon size={20} color={redColor} />
                      }
                    </div>
                    <p className={styles.title}>
                      {item.subtitle}
                    </p>
                    <p className={styles.desc}>
                      {item.desc}
                    </p>
                  </div>
              </BorderedCard>
            ))
          }
        </div>
      )}
    </div>
  )
}
