import React, { useState } from "react";
import { useSelector } from "react-redux";
import _ from "../../i18n";

import { BriefCaseIcon, IconExport } from "../common/Icons";
import PageHeader from "../common/PageHeader/PageHeader";
import Reporting from "./Reporting";
import Echeances from "./Echeances";
import BalancesRecap from "./BalancesRecap";
import CalendarRecap from "./Calendar";
import Bonuses from "./Bonuses";
import PrestationSuivi from "./PrestationSuivi";
import ErrorReporting from "./ErrorReporting";
import AbsenceMatrix from "./AbsenceMatrix/AbsenceMatrix";
import CollaboratorPlansBoard from "../CollaboratorSpace/PlansBoard/CollaboratorPlansBoard";
import Nettoyage from "./ProductionCapacity";
import PortfolioPlaning from "./Portfolio";
import TimeSheetProgression from "./TimeSheetProgression";
import { BeatLoader } from "react-spinners";
import {
  exportClientsVolumetry,
  exportProductionCapacity
} from "../../api";
import NotAuthorized from "../common/NotAuthorized";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getPartners } from "../../api/partners";
import { OffCanvasFilter } from "../common/OffCanvasFilter";

const ReportingPage = () => {
  const { token, navCommunity, user, loggedAs } = useSelector(
    (state) => state.auth
  );
  const { language } = useSelector((state) => state.params);
  const [exporting, setExporting] = useState(false);
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get("tab");

  const preferences =
    user && navCommunity && user.userOrganizations.length > 0
      ? user.userOrganizations.find(
          (organization) => organization.id === navCommunity.id
        ).authorisation.preferences
      : {};

  let tabs = [
    // {
    //   id: "REPORTING",
    //   label: "reporting",
    //   hasAccess: preferences.allowAccessReportingTab === "1"
    // },
    {
      id: "PRODUCTION_CAPACITY",
      label: "productionCapacity",
      hasAccess: preferences.allowAccessProductionCapacity === "1",
    },
    {
      id: "BONUSES",
      label: "Développement individuel",
      hasAccess: preferences.allowAccessBonusPlan === "1",
    },
    {
      id: "ECHEANCES",
      label: "echeances",
      hasAccess: preferences.allowAccessEcheance === "1",
    },
    {
      id: "BALANCES_RECAP",
      label: "BalanceRecap",
      hasAccess: preferences.allowAccessBalanceRecap === "1",
    },
    {
      id: "CALENDAR",
      label: "calendar",
      hasAccess: preferences.allowAccessCalendar === "1",
    },
    {
      id: "TS_PROGRESSION",
      label: "tsProgression",
      hasAccess: preferences.allowAccessTimeReport === "1",
    },
    {
      id: "PORTFOLIO",
      label: "portfolios",
      hasAccess: preferences.allowAccessPortfolio === "1",
    },
    {
      id: "SUPERVISION",
      label: "supervision",
      hasAccess: preferences.allowAccessErrReporting === "1",
    },
    {
      id: "ABSENCE_MATRIX",
      label: "absenceMatrix",
      hasAccess: preferences.allowAccessVacationMatrix === "1",
    },
    {
      id: "PLAN_BOARD",
      label: "planBoard",
      hasAccess: preferences.allowAccessPlans === "1",
    },
    {
      id: "PRESTATION_SUIVI",
      label: "prestationSuivi",
      hasAccess: preferences.allowAccessPrestation === "1",
    },
  ];

  const accessibleTabs = tabs.filter((tab) => tab.hasAccess);
  const [filter, setFilter] = useState({
    tab: activeTab ? activeTab : accessibleTabs[0] && accessibleTabs[0].id,
    year: new Date().getFullYear().toString(),
    collaborator: null,
    partnerOptions: [],
    partner: loggedAs == "MANAGER" ? user?.id : null,
    zone: null,
    keyword: "",
    category: 4,
  });

  let partnersQuery = useQuery(
    ["partnersQuery", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        const response = await getPartners({
          token,
          organizationId: navCommunity?.id,
        });
        const data = response.data.data.map((partner) => ({
          value: Number(partner.id),
          label: partner.name,
        }));
        setFilter({ ...filter, partnerOptions: data });
        return data;
      }
    },
    {
      enabled: !!token && !!navCommunity,
    }
  );

  if (preferences.allowAccessReporting === "0") {
    return <NotAuthorized />;
  }

  const handleExportProductionCapacity = async (
    organization,
    token,
    filter
  ) => {
    try {
      setExporting(true);
      await exportProductionCapacity(token, organization, filter);
    } catch (e) {
      console.error("Error exporting production capacity:", e);
    } finally {
      setExporting(false);
    }
  };

  const handleExportClientsVolumetry = async () => {
    try {
      setExporting(true);
      await exportClientsVolumetry(token, navCommunity.id);
    } catch (e) {
      console.error("Error exporting clients volumetry:", e);
    } finally {
      setExporting(false);
    }
  };

  const renderReportingTabContent = () => {
    switch (filter.tab) {
      case "REPORTING":
        return (
          <Reporting
            token={token}
            navCommunity={navCommunity}
            hasAccess={preferences.allowAccessReportingTab}
          />
        );
      case "ECHEANCES":
        return (
          <Echeances
            token={token}
            navCommunity={navCommunity}
            parentFilter={filter}
            hasAccess={preferences.allowAccessEcheance}
          />
        );
      case "BALANCES_RECAP":
        return (
          <BalancesRecap
            token={token}
            navCommunity={navCommunity}
            parentFilter={filter}
            hasAccess={preferences.allowAccessEcheance}
          />
        );
      case "BONUSES":
        return (
          <Bonuses
            token={token}
            navCommunity={navCommunity}
            parentFilter={filter}
            hasAccess={preferences.allowAccessBonusPlan}
          />
        );
      case "SUPERVISION":
        return (
          <ErrorReporting
            token={token}
            navCommunity={navCommunity}
            parentFilter={filter}
            language={language}
            hasAccess={preferences.allowAccessErrReporting}
          />
        );
      case "PORTFOLIO":
        return (
          <PortfolioPlaning
            token={token}
            navCommunity={navCommunity}
            language={language}
            hasAccess={preferences.allowAccessPortfolio}
          />
        );
      case "TS_PROGRESSION":
        return (
          <TimeSheetProgression
            token={token}
            navCommunity={navCommunity}
            parentFilter={filter}
            language={language}
            hasAccess={preferences.allowAccessTimeReport}
          />
        );
      case "CALENDAR":
        return (
          <CalendarRecap
            token={token}
            navCommunity={navCommunity}
            parentFilter={filter}
            lng={language}
            user={user ? user.firstName + " " + user.lastName : ""}
            loggedAs={loggedAs}
            hasAccess={preferences.allowAccessCalendar}
          />
        );
      case "ABSENCE_MATRIX":
        return (
          <AbsenceMatrix
            token={token}
            navCommunity={navCommunity}
            parentFilter={filter}
            language={language}
            hasAccess={preferences.allowAccessVacationMatrix}
          />
        );
      case "PLAN_BOARD":
        return (
          <CollaboratorPlansBoard
            token={token}
            lng={language}
            year={filter.year}
            loggedAs={loggedAs}
            organization={navCommunity && navCommunity.id}
            parentFilter={filter}
            user={user ? user.firstName + " " + user.lastName : ""}
            hasAccess={preferences.allowAccessPlans}
          />
        );
      case "PRODUCTION_CAPACITY":
        return (
          <Nettoyage
            filter={filter}
            setFilter={setFilter}
            hasAccess={preferences.allowAccessProductionCapacity}
          />
        );
      case "PRESTATION_SUIVI":
        return (
          <PrestationSuivi
            token={token}
            navCommunity={navCommunity}
            lng={language}
            year={filter.year}
            hasAccess={preferences.allowAccessPrestation}
          />
        );
      default:
        return;
    }
  };

  const renderExportButton = () => {
    switch (filter.tab) {
      case "PRODUCTION_CAPACITY":
        return (
          <div
            className="export_btn"
            onClick={() =>
              handleExportProductionCapacity(navCommunity.id, token, filter)
            }>
            {exporting ? (
              <BeatLoader color="#3498db" size={10} />
            ) : (
              <>
                <IconExport size={20} />
                Exporter
              </>
            )}
          </div>
        );
      case "PRESTATION_SUIVI":
        return (
          <div
            className="export_btn"
            onClick={() => handleExportClientsVolumetry()}>
            {exporting ? (
              <BeatLoader color="#3498db" size={10} />
            ) : (
              <>
                <IconExport size={20} />
                Exporter la volumétrie
              </>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="page-content">
      <PageHeader
        header={_("reporting")}
        subHeader={_(
          accessibleTabs.find((tab) => tab.id === filter.tab)?.label ||
            _("reportingSection")
        )}
        icon={<BriefCaseIcon fill="#29394D" size="36" />}>
        <div style={{ display: "flex", alignItems: "center", gap: 30 }}>
          {renderExportButton()}
          <OffCanvasFilter
            tabs={accessibleTabs}
            activeTab={filter.tab}
            selectTab={(item) => {
              setFilter({ ...filter, tab: item.id });
            }}
          />
        </div>
      </PageHeader>

      <div
        className="content-body large-content"
        style={{
          padding:
            (filter.tab === "ECHEANCES" || filter.tab === "BONUSES") && 0,
        }}>
        {renderReportingTabContent()}
      </div>
    </div>
  );
};

export default ReportingPage;
