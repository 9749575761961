import { Tooltip } from "antd";
import { Carousel } from "primereact/carousel";
import { useEffect, useRef, useState } from "react";
import { TiArrowDown, TiArrowSortedDown } from "react-icons/ti";
import { useQuery } from "react-query";
import {
  Bar,
  BarChart,
  Tooltip as BarTooltip,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import {
  countPlans,
  getBalanceProduction,
  getBinomesList,
  getCollaboratorContributionByCategory,
  getErrorReportCounter,
  getLastAbsences,
  getNonBillableContribution,
  getProductionCapacity,
} from "../../../api";
import _ from "../../../i18n";
import {
  calculatePeriodDates,
  generateFullName,
  getUserNameForAvatar,
  renderDurationDisplay,
} from "../../../utils";
import styles from "./CapacityProduction.module.scss";
import moment from "moment";
import { useSelector } from "react-redux";
import { AlertCircleIcon } from "../../common/Icons";
import { Matrix } from "../components/Matrix";
import { RedirectCard } from "../components/RedirectCard";
import { FaRegCalendarTimes } from "react-icons/fa";
import { BiMessageError, BiTaskX } from "react-icons/bi";

const tabs = [
  {
    label: _("individual"),
    value: 0,
  },
  {
    label: _("both"),
    value: 1,
  },
];

const periods = ["_week", "_month", "_quarter"];
const { start: initStart, end: initEnd } = calculatePeriodDates("_week");

export default function CapacityProduction({
  token,
  loggedAs,
  organization,
  partner,
  year,
}) {
  const { language: lang } = useSelector((state) => state.params);

  const [list, setList] = useState([]);
  const [data, setData] = useState([]);
  const [plansCounter, setPlansCounter] = useState([]);
  const [absences, setAbsences] = useState([]);
  const [errorCounter, setErrorCounter] = useState([]);
  const [balance, setBalance] = useState([]);
  const [categories, setCategories] = useState([]);
  const [nonBillable, setNonBillable] = useState([]);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState({
    collaborators: [],
    activeBinome: [],
    activeCard: "",
    tab: tabs[0].value,
    year: year,
    organization,
    partner: ["SUPERADMIN", "ADMIN"].includes(loggedAs)
      ? partner?.value
      : partner?.id,
    period: "_week",
    start: initStart,
    end: initEnd,
  });

  const binomesListQuery = useQuery(
    ["getBinomesListQuery", token, organization, partner, year],
    async () => {
      if (token && organization && partner) {
        const response = await getBinomesList(
          token,
          organization,
          ["SUPERADMIN", "ADMIN"].includes(loggedAs)
            ? partner?.value
            : partner?.id,
          year,
          filter.period,
          filter.start,
          filter.end
        );
        return response.data.data;
      }
    },
    {
      onSuccess: (data) => {
        setList(data);
        setFilter((prevFilter) => ({
          ...prevFilter,
          activeCard: data[0]?.binome_id || null,
          activeBinome: data[0] || null,
        }));
      },
      onError: () => setList([]),
    }
  );

  useEffect(() => {
    binomesListQuery.refetch();
  }, [filter.period, year, partner]);

  const productionCapacityQuery = useQuery(
    ["getProductionCapacityQuery", token, organization, filter.period],
    async () => {
      if (
        token &&
        organization &&
        partner &&
        filter?.collaborators?.[filter.activeCard]
      ) {
        const ids = filter?.collaborators?.[filter.activeCard]
          ? Object.values(filter?.collaborators[filter.activeCard]).filter(
              (o) => o
            )
          : [];
        try {
          const response = await getProductionCapacity(
            token,
            ids,
            organization,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            year,
            filter.period,
            filter.start,
            filter.end
          );
          const data = response.data.data;
          setData(data);
        } catch (e) {
          setData([]);
          setError("No data available");
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!organization && !!partner,
    }
  );

  const countPlansQuery = useQuery(
    ["countPlansQuery", token, organization, filter.period],
    async () => {
      if (token && organization && partner) {
        const ids =
          filter?.collaborators?.[filter.activeCard] &&
          Object.values(filter?.collaborators[filter.activeCard]).filter(
            (o) => o
          );
        try {
          const response = await countPlans(
            token,
            ids,
            organization,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            year,
            filter.start,
            filter.end
          );
          const data = response.data.data;
          setPlansCounter(data);
        } catch (e) {
          setPlansCounter([]);
          setError("No data available");
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!organization && !!partner,
    }
  );

  const lastAbsencesQuery = useQuery(
    ["getLastAbsencesQuery", token, organization, filter.year],
    async () => {
      if (
        token &&
        organization &&
        partner &&
        filter?.collaborators?.[filter.activeCard]
      ) {
        const ids = filter?.collaborators?.[filter.activeCard]
          ? Object.values(filter?.collaborators[filter.activeCard]).filter(
              (o) => o
            )
          : [];
        try {
          const response = await getLastAbsences(
            token,
            ids,
            organization,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            year
          );
          const data = response.data.data;
          setAbsences(data);
        } catch (e) {
          setAbsences([]);
          setError("No data available");
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!organization && !!partner,
    }
  );

  const countErrorReport = useQuery(
    ["getErrorReportCounterQuery", token, organization, filter.year],
    async () => {
      if (token && organization && partner) {
        const ids =
          filter?.collaborators?.[filter.activeCard] &&
          Object.values(filter?.collaborators[filter.activeCard]).filter(
            (o) => o
          );
        try {
          const response = await getErrorReportCounter(
            token,
            ids,
            organization,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            year
          );
          const data = response.data.data;
          setErrorCounter(data[0]);
        } catch (e) {
          setErrorCounter([]);
          setError("No data available");
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!organization && !!partner,
    }
  );

  const getBalanceProductionQuery = useQuery(
    ["getBalanceProductionQuery", token, organization, filter.year],
    async () => {
      const ids =
        filter?.collaborators?.[filter.activeCard] &&
        Object.values(filter?.collaborators[filter.activeCard]).filter(
          (o) => o
        );
      if (token && organization && partner && ids) {
        try {
          const response = await getBalanceProduction(
            token,
            ids,
            organization,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            year
          );
          const data = response.data.data;
          console.log("bbb", data);
          setBalance(data);
        } catch (e) {
          setBalance([]);
          setError("No data available");
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!organization && !!partner,
    }
  );

  const contributionByCategoryQuery = useQuery(
    ["contributionByCategoryQuery", token, year],
    async () => {
      const id = filter.collaborators[filter.activeCard]?.backup;
      if (token && organization && id) {
        const { start, end } = calculatePeriodDates("_month");
        try {
          const response = await getCollaboratorContributionByCategory(
            id,
            organization.id,
            year,
            token,
            start,
            end
          );

          const data = response.data.data;
          setCategories(data);
        } catch (e) {
          console.log(e);
          setCategories({});
          return null;
        }
      }
    },
    {
      enabled: !!token && !!organization && !!partner,
    }
  );

  const nonBillableTasksQuery = useQuery(
    ["getNonBillableTasksQuery", token, year],
    async () => {
      let id = filter.collaborators[filter.activeCard]?.collaborator;
      let role = "GD";
      if (!id) {
        id = filter?.collaborators[filter.activeCard]?.backup;
        role = "GE";
      }
      if ((token, organization, partner, id)) {
        try {
          const response = await getNonBillableContribution(
            token,
            organization.id,
            id,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            year,
            role
          );

          const data = response.data.data;
          setNonBillable(data[0]);
        } catch (e) {
          console.log(e);
          setNonBillable({});
          return null;
        }
      }
    },
    {
      enabled: !!token && !!organization && !!partner,
    }
  );

  useEffect(() => {
    productionCapacityQuery.refetch();
    countPlansQuery.refetch();
    lastAbsencesQuery.refetch();
  }, [filter.collaborators, filter.period, year, partner]);

  useEffect(() => {
    getBalanceProductionQuery.refetch();
    lastAbsencesQuery.refetch();
    countErrorReport.refetch();
    contributionByCategoryQuery.refetch();
    nonBillableTasksQuery.refetch();
  }, [filter.collaborators, year, partner]);

  // useEffect(() => {
  //   contributionByCategoryQuery.refetch();
  // }, [filter.collaborators]);

  useEffect(() => {
    if (list && list?.length > 0) {
      const initialState = {
        collaborators:
          filter.tab === 0
            ? list.reduce((acc, binome) => {
                acc[binome.binome_id] = {
                  collaborator: parseInt(binome.collaborator.id),
                  backup: null,
                };
                return acc;
              }, {})
            : filter.tab === 1
            ? list.reduce((acc, binome) => {
                acc[binome.binome_id] = {
                  collaborator: parseInt(binome.collaborator.id),
                  backup: parseInt(binome.backup.id),
                };
                return acc;
              }, {})
            : {},
      };

      setFilter((prevFilter) => ({
        ...prevFilter,
        ...initialState,
      }));
    }
  }, [list, filter.tab]);

  const activateCard = (binomeId) => {
    const selectedBinome = list.find((b) => b.binome_id == binomeId);

    setFilter((prevFilter) => ({
      ...prevFilter,
      activeCard: binomeId,
      activeBinome: selectedBinome,
      collaborators: {
        ...prevFilter.collaborators,
        [binomeId]:
          prevFilter.tab === 1
            ? {
                collaborator: parseInt(selectedBinome.collaborator.id),
                backup: parseInt(selectedBinome.backup.id),
              }
            : {
                ...prevFilter.collaborators[binomeId],
              },
      },
    }));
  };

  const activateCollaborator = (binomeId, collaboratorId) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      collaborators: {
        ...prevFilter.collaborators,
        [binomeId]: {
          collaborator: parseInt(collaboratorId),
          backup: null,
        },
      },
    }));
  };

  const activateBackup = (binomeId, backupId) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      collaborators: {
        ...prevFilter.collaborators,
        [binomeId]: {
          collaborator: null,
          backup: parseInt(backupId),
        },
      },
    }));
  };

  const CustomLegend = ({ data }) => {
    const totalPlanned = data.reduce((sum, item) => sum + item.planned, 0);
    const totalTheoric = data.reduce((sum, item) => sum + item.theoric, 0);
    const totalReal = data.reduce((sum, item) => sum + item.real, 0);
    const totalAbsence = data.reduce((sum, item) => sum + item.absence, 0);

    return (
      <div className={styles.custom_legend}>
        <div className={styles.legend}>
          <div className={styles.legend_label}>
            <div
              className={styles.legend_label_square}
              style={{ backgroundColor: "#C7E8FE" }}
            />
            <div>{_("planned")}</div>
          </div>
          <div className={styles.legend_total}>
            <span>Total:</span>
            <span>{renderDurationDisplay(totalPlanned)}</span>
            <span>
              {totalTheoric > 0
                ? ((totalPlanned * 100) / totalTheoric).toFixed(1)
                : 0}{" "}
              %
            </span>
          </div>
        </div>
        <div className={styles.separator} />
        <div className={styles.legend}>
          <div className={styles.legend_label}>
            <div
              className={styles.legend_label_square}
              style={{ backgroundColor: "#18A0FB" }}
            />
            <div>{_("realized")}</div>
          </div>
          <div className={styles.legend_total}>
            <span>Total:</span>
            <span>{renderDurationDisplay(totalReal)}</span>
            <span>
              {totalTheoric > 0
                ? ((totalReal * 100) / totalTheoric).toFixed(1)
                : 0}{" "}
              %
            </span>
          </div>
        </div>
        <div className={styles.separator} />
        <div className={styles.legend}>
          <div className={styles.legend_label}>
            <div
              className={styles.legend_label_square}
              style={{ backgroundColor: "#B2BCC6" }}
            />
            <div>{_("expected")}</div>
          </div>
          <div className={styles.legend_total}>
            <span>Total:</span>
            <span>{renderDurationDisplay(totalTheoric)}</span>
          </div>
        </div>
        <div className={styles.separator} />
        <div className={styles.legend}>
          <div className={styles.legend_label}>
            <div
              className={styles.legend_label_square}
              style={{ backgroundColor: "#F7953E" }}
            />
            <div>{_("absenceDay")}</div>
          </div>
          <div className={styles.legend_total}>
            <span>Total:</span>
            <span>{renderDurationDisplay(totalAbsence)}</span>
          </div>
        </div>
      </div>
    );
  };

  const { collaborators, activeCard, tab, activeBinome } = filter;
  const redirectId = collaborators[activeCard]?.collaborator
    ? collaborators[activeCard]?.collaborator
    : collaborators[activeCard]?.backup;

  const param = encodeURIComponent(
    JSON.stringify({ id: 11, label: "15. NON FACTURABLE" })
  );

  return (
    <div className={styles.cp_container}>
      <CPHeader data={list} filter={filter} setFilter={setFilter} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          overflowX: "auto",
          width: "100%",
          whiteSpace: "nowrap",
        }}>
        {binomesListQuery.isFetched &&
          list.map((item) => (
            <Binome
              key={item.binome_id}
              binomeId={item.binome_id}
              collaborator={item.collaborator}
              backup={item.backup}
              filter={filter}
              setFilter={setFilter}
              activateCard={activateCard}
              activateCollaborator={activateCollaborator}
              activateBackup={activateBackup}
            />
          ))}
      </div>

      <div
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}>
        {binomesListQuery.isFetched && productionCapacityQuery.isFetched ? (
          data && data.length > 0 ? (
            <div className={styles.chart_container}>
              <CustomLegend data={data} />
              <ResponsiveContainer width="100%" height={250}>
                <BarChart
                  data={data}
                  margin={{ top: 20, right: 20, left: 20, bottom: 5 }}
                  className={styles.chart_container_chart}>
                  <CartesianGrid
                    strokeDasharray="3 0"
                    vertical={false}
                    stroke="#F1F2F4"
                  />
                  <XAxis
                    dataKey="name"
                    tickLine={false}
                    tickSize={12}
                    tickFormatter={(name) =>
                      filter.period == "_week" ? name.split(".")[0] : _(name)
                    }
                    fill="#29394D"
                  />
                  <YAxis
                    axisLine={false}
                    orientation="right"
                    type="number"
                    tickLine={false}
                    tickSize={10}
                    unit="h"
                    tickMargin={20}
                    fill="#64748B"
                    tickFormatter={(value) =>
                      value % 60 === 0
                        ? (value / 60).toFixed(0)
                        : (value / 60).toFixed(1)
                    }
                  />
                  <BarTooltip
                    content={<CustomTooltip />}
                    cursor={{ fill: "rgba(225, 228, 232, 0.50)" }}
                  />
                  <Bar
                    barSize={12}
                    dataKey="planned"
                    fill="#C7E8FE"
                    name={_("planned")}
                    radius={[3, 3, 0, 0]}
                  />
                  <Bar
                    barSize={3}
                    dataKey="theoric"
                    name={_("expected")}
                    fill="#B2BCC6"
                    shape={(props) => {
                      const { x, y, width, height, payload } = props;
                      const fillColor =
                        payload.absence > 0 ? "#F7953E" : "#B2BCC6";
                      return (
                        <rect
                          x={x}
                          y={y}
                          width={width}
                          height={height}
                          fill={fillColor}
                          radius={[3, 3, 0, 0]}
                        />
                      );
                    }}
                  />
                  <Bar
                    barSize={12}
                    dataKey="real"
                    fill="#18A0FB"
                    name={_("realized")}
                    radius={[3, 3, 0, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div className="no_data" style={{ height: 100 }}>
              <div className="title">{_("noDataFound")}</div>
            </div>
          )
        ) : (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        )}
        <div className={styles.divider} />
        <div style={{ display: "flex", gap: 10 }}>
          <RedirectCard
            title={_("nonBillableTasks")}
            to={`/${lang}/fiche-collaborator/${redirectId}?category=${param}`}>
            {nonBillableTasksQuery.isFetched && nonBillable ? (
              <div className={styles.non_billable_tasks}>
                <div className={styles.non_billable_tasks_head}>
                  <span>{_("individual")}</span>
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                    {renderDurationDisplay(nonBillable.collaborator)}
                    <span>{`${parseInt(nonBillable.diff)} %`}</span>
                  </div>
                </div>
                <div>
                  <div className={styles.bar_container}>
                    <div
                      className={styles.bar}
                      style={{ width: `${nonBillable.diff}%` }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.no_data_alert}>
                <BiTaskX size={20} />
                <em>{_("noTask")}</em>
              </div>
            )}
          </RedirectCard>
          <RedirectCard
            title={_("planManagment")}
            to={`/${lang}/fiche-collaborator/${redirectId}?tab=PLANS_BOARD`}>
            <div className={styles.counter_bubbles}>
              {plansCounter &&
                plansCounter.length > 0 &&
                plansCounter.map((c) => (
                  <div
                    key={c.status}
                    className={styles.bubble}
                    style={{
                      width: c.width,
                      height: c.width,
                      backgroundColor: c.bgColor,
                      color: c.color,
                      borderRadius: "50%",
                      top: c.top,
                      left: c.left,
                    }}>
                    <div>{c.count}</div>
                    <div>
                      {c.label.length > 9
                        ? `${c.label.slice(0, 8)}...`
                        : c.label}
                    </div>
                  </div>
                ))}
            </div>
          </RedirectCard>
          <RedirectCard
            title={_("errorReport")}
            to={`/${lang}/reporting?tab=SUPERVISION`}>
            {errorCounter ? (
              <div className={styles.error_rapport}>
                {errorCounter?.created?.error > 0 && (
                  <div className={styles.error_rapport_column}>
                    <div>
                      <span>{errorCounter?.created?.error}</span>
                      <span>{_("created")}</span>
                    </div>
                    <TiArrowDown />
                    <div>
                      <span>{errorCounter?.created?.client}</span>
                      <span>{_("clientsCount")}</span>
                    </div>
                  </div>
                )}
                {errorCounter?.received?.error > 0 && (
                  <div className={styles.error_rapport_column}>
                    <div>
                      <span>{errorCounter?.received?.error}</span>
                      <span>{_("received")}</span>
                    </div>
                    <TiArrowDown />
                    <div>
                      <span>{errorCounter?.received?.client}</span>
                      <span>{_("clientsCount")}</span>
                    </div>
                  </div>
                )}

                {errorCounter?.created?.error == 0 &&
                  errorCounter?.received?.error == 0 && (
                    <div className={styles.no_data_alert}>
                      <BiMessageError size={20} />
                      <em>{_("noReport")}</em>
                    </div>
                  )}
              </div>
            ) : (
              <div className={styles.no_data_alert}>
                <BiMessageError size={20} /> <em>{_("noReport")}</em>
              </div>
            )}
          </RedirectCard>
          {tab == 0 && collaborators[activeCard]?.backup ? (
            <RedirectCard
              title={_("production")}
              to={`/${lang}/fiche-collaborator/${redirectId}`}>
              {categories && categories.real ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    overflowY: "auto",
                  }}>
                  {categories.real.map((c) => (
                    <div key={c.id} className={styles.balance_container}>
                      <h2>
                        {`${c.label.slice(4, 12)}.`}
                        <div style={{ display: "flex", gap: 10, fontSize: 10 }}>
                          <span>
                            {renderDurationDisplay(c.duration_minutes)}
                          </span>
                          <span>{`${(
                            (c.duration_minutes * 100) /
                            (categories.theoric * 60)
                          ).toFixed(1)} %`}</span>
                        </div>
                      </h2>
                      <div>
                        <div
                          className={styles.bar_container}
                          style={{ marginTop: -2 }}>
                          <div
                            className={styles.bar}
                            style={{
                              width: `${
                                (c.duration_minutes * 100) /
                                (categories.theoric * 60)
                              }%`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <em className={styles.no_data_alert}>{_("noData")}</em>
              )}
            </RedirectCard>
          ) : (
            <RedirectCard
              title={_("production")}
              to={`/${lang}/reporting?tab=ECHEANCES`}
            >
              {getBalanceProductionQuery.isFetched &&
              balance &&
              balance.length > 0 ? (
                balance.map((b) => (
                  <div key={b.type} className={styles.balance_container}>
                    <h2>
                      {`${b.type} ${
                        b?.echeance?.echeance
                          ? "(" + b?.echeance?.echeance + ")"
                          : ""
                      }`}
                      {b.type == "TVA" ? (
                        <span>{`${b?.echeance["total"]}/${b?.total}`}</span>
                      ) : (
                        ""
                      )}
                    </h2>
                    {b.type == "TVA" ? (
                      <div>
                        <div className={styles.bar_container}>
                          <div
                            className={styles.bar}
                            style={{
                              width: `${
                                (b?.echeance["total"] * 100) / (b?.total ?? 1)
                              }%`,
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 10,
                          width: "100%",
                        }}>
                        {Object.keys(b?.bnb || {})
                          .filter((key) => key !== "total")
                          .map((key) => (
                            <div key={key}>
                              <h3>
                                {_(key)}{" "}
                                <span>{`${b?.bnb[key]}/${
                                  b?.bnb["total"]
                                }`}</span>
                              </h3>
                              <div className={styles.bar_container}>
                                <div
                                  className={styles.bar}
                                  style={{
                                    width: `${
                                      (b?.bnb[key] * 100) /
                                      (b?.bnb["total"] ?? 1)
                                    }%`,
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <em className={styles.no_data_alert}>{_("noData")}</em>
              )}
            </RedirectCard>
          )}
          <RedirectCard
            title={_("calendar")}
            to={`/${lang}/fiche-collaborator/${redirectId}?tab=CALENDAR`}>
            <div className={styles.calendar}>
              <div className={styles.date}>{moment().format("MMMM YYYY")}</div>
              {absences && absences.length > 0 ? (
                absences.map((absence, index) => (
                  <div key={index} className={styles.absence_item}>
                    <div className={styles.row}>
                      <div
                        style={{
                          width: 5,
                          height: 5,
                          borderRadius: 100,
                          backgroundColor: absence.type_color,
                        }}
                      />
                      <span>{absence.name}</span>
                    </div>
                    <div
                      className={styles.row}
                      style={{ alignItems: "flex-start" }}>
                      <div className={styles.row_child}>
                        <strong>{absence.reason}</strong>
                        <div className={styles[absence.status]}>
                          {_(absence.status)}
                        </div>
                      </div>
                      <div>
                        {JSON.parse(absence.days).map((day, index) => (
                          <div
                            key={index}
                            className={styles.formatted_date}
                            style={{ textTransform: "capitalize" }}>
                            {new Intl.DateTimeFormat("fr-FR", {
                              day: "2-digit",
                              month: "short",
                            }).format(new Date(day))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className={styles.no_data_alert}>
                  <FaRegCalendarTimes size={20} /> <em>{_("noAbsence")}</em>
                </div>
              )}
            </div>
          </RedirectCard>
        </div>

        {activeBinome && (
          <Matrix
            token={token}
            loggedAs={loggedAs}
            language={lang}
            organization={organization}
            partner={partner}
            year={year}
            activeBinome={activeBinome}
          />
        )}
      </div>
    </div>
  );
}

const CustomTooltip = ({ active, payload }) => {
  if (!active || !payload || payload.length === 0) return null;

  const { name } = payload[0].payload;
  const tooltipItems = payload.map((entry) => ({
    color: entry.color,
    name: entry.name,
    value: entry.value,
  }));

  return (
    <div className={styles.custom_tooltip}>
      <div className={styles.custom_tooltip_child}>{name}</div>
      {tooltipItems.map((item) => (
        <div key={item.name} className={styles.custom_tooltip_child}>
          <div>
            <div
              className={styles.square}
              style={{ backgroundColor: item.color }}
            />
            <span>{item.name}</span>
          </div>
          <div>{renderDurationDisplay(item.value)}</div>
        </div>
      ))}
    </div>
  );
};

function CPHeader({ data, filter, setFilter }) {
  const ref = useRef();
  const [priodDivIsVisible, setPriodDivIsVisible] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setPriodDivIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setPriodDivIsVisible]);

  const handlePriodChange = (period) => {
    const { start, end } = calculatePeriodDates(period);
    setFilter((prevFilter) => ({ ...prevFilter, period, start, end }));
  };

  const renderPeriodSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (priodDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
          width: "136px",
        }}>
        <div
          className={"customed_select_header"}
          onClick={() => setPriodDivIsVisible(!priodDivIsVisible)}>
          <span>{_(filter.period)}</span>
          <div className={"actions"}>
            <div>
              <span className={"action"}>
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {priodDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {periods.map((item) => (
                <div
                  key={item.col_id}
                  className={"customed_select_body_option"}
                  onClick={() => handlePriodChange(item)}>
                  {_(item)}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const switchTab = (tab) => {
    setFilter((prevFilter) => {
      const updatedState = {
        ...prevFilter,
        tab,
        collaborators: {},
      };

      data.forEach((binome) => {
        updatedState.collaborators[binome.binome_id] = {
          collaborator: parseInt(binome.collaborator.id),
          backup: tab === 1 ? parseInt(binome.backup.id) : null,
        };
      });

      return updatedState;
    });
  };

  return (
    <div className={styles.cp_container_header}>
      <div>
        <h2>{_("capacityProduction")}</h2>
        <p>{_("capacityProductionDesc")}</p>
      </div>
      <div className={styles.cp_container_header_actions}>
        <Carousel
          value={tabs}
          numVisible={2}
          numScroll={0}
          showNavigators={false}
          itemTemplate={(category, i) => {
            return (
              <div
                key={i}
                className={`tab_badge ${
                  filter.tab === category.value ? "tab_badge_active" : ""
                }`}
                style={{ overflow: "hidden" }}
                onClick={() => switchTab(category.value)}>
                <div style={{ textTransform: "capitalize" }}>
                  {category.label}
                </div>
              </div>
            );
          }}
        />
        {renderPeriodSelect()}
      </div>
    </div>
  );
}

function Binome({
  binomeId,
  activateCard,
  collaborator,
  backup,
  filter,
  activateCollaborator,
  activateBackup,
}) {
  const { tab, activeCard, collaborators } = filter;
  const activeEntity =
    collaborators[binomeId]?.collaborator == collaborator.id
      ? collaborator
      : collaborators[binomeId]?.backup == backup.id
      ? backup
      : collaborator;

  return (
    <div
      className={`${styles.cp_container_binome} ${
        activeCard === binomeId && styles.active_card
      }`}
      onClick={() => activateCard(binomeId)}>
      {!backup.id && (
        <Tooltip title={_("noBackup")} placement="left">
          <div style={{ position: "absolute", top: 10, right: 10 }}>
            <AlertCircleIcon />
          </div>
        </Tooltip>
      )}
      <div
        className={styles.cp_container_binome_avatars}
        style={{
          flexDirection:
            collaborators[binomeId]?.backup && tab === 0
              ? "row-reverse"
              : "row",
        }}>
        {collaborator.id && (
          <div
            className={`${
              collaborators[binomeId]?.collaborator
                ? styles.active_avatar
                : styles.inactive_avatar
            }`}
            style={{
              border: !backup.id && "1px solid #de4848",
              borderRadius: "50%",
            }}
            onClick={tab === 1 ? (e) => e.preventDefault() : null}>
            {renderClickedAvatar(
              collaborator.avatar,
              collaborator.first_name,
              collaborator.last_name,
              () => activateCollaborator(binomeId, collaborator.id)
            )}
          </div>
        )}
        {backup.id && (
          <div
            className={`${
              collaborators[binomeId]?.backup
                ? styles.active_avatar
                : styles.inactive_avatar
            }`}
            onClick={tab === 1 ? (e) => e.preventDefault() : null}>
            {renderClickedAvatar(
              backup.avatar,
              backup.first_name,
              backup.last_name,
              () => activateBackup(binomeId, backup.id)
            )}
          </div>
        )}
      </div>
      <div className={styles.cp_container_binome_details}>
        {tab === 1 ? (
          <>
            <div>
              <p>
                {collaborator.first_name}{" "}
                {collaborator.last_name && collaborator.last_name[0]}.
                {backup.id ? (
                  <>
                    <br /> +
                    <br /> {backup.first_name}{" "}
                    {backup.last_name && backup.last_name[0]}.
                  </>
                ) : (
                  <>
                    <br /> <br /> <br />
                  </>
                )}
              </p>
              <div className={styles.cp_container_binome_details_bar}>
                <div
                  style={{
                    width: `${
                      collaborator.theoric + backup.theoric > 0
                        ? ((collaborator.real + backup.real) /
                            (collaborator.theoric + backup.theoric)) *
                          100
                        : 0
                    }%`,
                    background:
                      filter.activeCard === binomeId ? "#18A0FB" : "#6D7F92",
                  }}
                />
              </div>
              <div>
                {renderDurationDisplay(collaborator.real + backup.real)}
              </div>
            </div>
          </>
        ) : (
          <>
            <p>
              {activeEntity.first_name}
              <br /> {activeEntity.last_name}
            </p>
            <span>{activeEntity.role}</span>
            <div className={styles.cp_container_binome_details_bar}>
              <div
                style={{
                  width: `${(activeEntity.real / activeEntity.theoric) * 100}%`,
                  background: activeCard === binomeId ? "#18A0FB" : "#6D7F92",
                }}
              />
            </div>
            <div>{renderDurationDisplay(activeEntity.real)}</div>
          </>
        )}
      </div>
    </div>
  );
}

export const renderClickedAvatar = (
  avatarUrl,
  firstName,
  lastName,
  onClick
) => {
  if (!avatarUrl && !firstName && !lastName) {
    return (
      <div className={styles.avatar_not_exist} onClick={onClick}>
        <p style={{ margin: "auto" }}>-</p>
      </div>
    );
  }
  let avatarDiv;
  if (!avatarUrl) {
    avatarDiv = (
      <div className={styles.avatar_not_exist} onClick={onClick}>
        <span>{getUserNameForAvatar(firstName, lastName)}</span>
      </div>
    );
  } else {
    avatarDiv = (
      <div className={styles.avatar_exist} onClick={onClick}>
        <img src={avatarUrl} alt={generateFullName(firstName, lastName)} />
      </div>
    );
  }

  return (
    <Tooltip title={`${firstName} ${lastName}`} placement="top">
      {avatarDiv}
    </Tooltip>
  );
};
