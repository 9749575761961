import axios from "axios";

import { TTP_API_URL } from "../config";

export const runAdminIsTaskSynchronisation = ({
  token,
  organization,
  year,
  period
}) => {
  const requestUrl = `${TTP_API_URL}/team/admin_is_tasks_sync`;

  let formData = new FormData();
  formData.append("organization", organization);
  formData.append("year", year);
  formData.append("period", period);
  formData.append("access_token", token);
 

  return axios.post(requestUrl, formData);
};
export const runAdminIsInvoiceSynchronisation = ({
  token,
  organization,
  year,
  period
}) => {
  const requestUrl = `${TTP_API_URL}/team/admin_is_invoices_sync`;

  let formData = new FormData();
  formData.append("organization", organization);
  formData.append("year", year);
  formData.append("period", period);
  formData.append("access_token", token);
 

  return axios.post(requestUrl, formData);
};
export const runAdminIsTvaBalancesSynchronisation = ({
  token,
  organization,
  year
}) => {
  const requestUrl = `${TTP_API_URL}/team/admin_is_tva_balances_sync`;

  let formData = new FormData();
  formData.append("organization", organization);
  formData.append("year", year);
  formData.append("access_token", token);
 

  return axios.post(requestUrl, formData);
};
export const runAdminIsBalancesSynchronisation = ({
  token,
  organization,
  year,
  type
}) => {
  const requestUrl = `${TTP_API_URL}/team/admin_is_balances_sync`;

  let formData = new FormData();
  formData.append("organization", organization);
  formData.append("year", year);
  formData.append("type", type);
  formData.append("access_token", token);
 

  return axios.post(requestUrl, formData);
};
export const runAdminIsClientRolesSynchronisation = ({
  token,
  organization,
  year,
}) => {
  const requestUrl = `${TTP_API_URL}/team/admin_is_client_roles`;

  let formData = new FormData();
  formData.append("organization", organization);
  formData.append("year", year);
  formData.append("access_token", token);
 

  return axios.post(requestUrl, formData);
};

export const runAdminIsClientEntitiesSynchronisation = ({
  token,
  organization,
  year,
}) => {
  const requestUrl = `${TTP_API_URL}/team/admin_is_client_entities_sync`;

  let formData = new FormData();
  formData.append("organization", organization);
  formData.append("year", year);
  formData.append("access_token", token);
 

  return axios.post(requestUrl, formData);
};

export const runAdminIsInvoicePlanningSynchronisation = ({
  token,
  organization,
  year
}) => {
  const requestUrl = `${TTP_API_URL}/team/admin_is_invoice_planning_sync`;

  let formData = new FormData();
  formData.append("organization", organization);
  formData.append("year", year);
  formData.append("access_token", token);

  return axios.post(requestUrl, formData);
};

export const sendTimeSheetReminders = ({
  token,
  organization
}) => {
  const requestUrl = `${TTP_API_URL}/team/adminis/timesheet-reminder`;

  let formData = new FormData();
  formData.append("organization", organization);
  formData.append("access_token", token);

  return axios.post(requestUrl, formData);
};

