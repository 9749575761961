import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCollaboratorsBonuses, getCollaboratorsByRole } from "../../../api";
import "./Bonuses.local.scss";
import { CustumedMatrix } from "tamtam-components";
import CollaboratorDevelopmentCard from "../../Collaborators/CollaboratorDevelopmentCard";
import _ from "../../../i18n";
import TTPPaginator from "../../common/TTPPaginator";
import TTPFilterHorizontalBar from "../../common/Filter/TTPFilterHorizontalBar";
import { renderTeamYears, useOnClickOutside } from "../../../utils";
import { TiArrowSortedDown } from "react-icons/ti";
import BonusMAPage from "./BonusMA";

const Bonuses = ({ hasAccess, parentFilter }) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setYearDivIsVisible(false));

  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const { token, navCommunity, lng, user } = useSelector((state) => state.auth);
  const [showSideBar, setShowSideBar] = useState(null);
  const [filter, setFilter] = useState({
    ...parentFilter,
    encodingManagerOptions: [],
    encodingManager: null,
    keyWord: "",
    selectedZone: 1,
    year: new Date().getFullYear().toString(),
    nbResult: 100,
    pageSize: 10,
    paginationPage: 1,
    // collaborators: [],
    // deletedStatus: null,
    // clientCRMGroup: [],
    // clientCRMTypes: [],
    // counters: [],
  });
  const [columns, setColumns] = useState([
    {
      id: "powerteam",
      label: _("Powerteam"),
      isEditable: true,
      widthPercentage: 12,
    },
    {
      id: "balance",
      label: _("Balance"),
      isEditable: true,
      widthPercentage: 15,
    },
    {
      id: "ipp_cr",
      label: _("Ipp CR"),
      isEditable: true,
      widthPercentage: 20,
    },
    {
      id: "ipp",
      label: _("Ipp"),
      isEditable: true,
      widthPercentage: 14,
    },
    {
      id: "horus",
      label: _("Horus"),
      isEditable: true,
      widthPercentage: 6,
    },
    {
      id: "intermediateSituation",
      label: _("Int. Situation"),
      isEditable: true,
      widthPercentage: 10,
    },
  ]);
  const [collaborators, setCollaborators] = useState(null);
  const [encodingManagers, setEncodingManagers] = useState([]);
  const [data, setData] = useState(null);
  const [loadingCollaborators, setLoadingCollaborators] = useState(true);
  const [filterInputs, setFilterInputs] = useState([]);

  let collaboratorsQuery = useQuery(
    ["getCollaboratorsByRole", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsByRole({
            token,
            organizationId: navCommunity.id,
            role: 'encodingManager'
          });
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
              };
            });
          setEncodingManagers(response.data.data.filter(item => item !== null));
          setFilter(prevState => ({ ...prevState, encodingManagerOptions: data, encodingManager: data[0]?.value }));
        } catch (e) {
          return null;
        }
      }
    }
  );

  const handlePartnerChange = (partner) => {
    setFilter({ ...filter, partner: Number(partner) });
  };

  const handleGEChange = (ge) => {
    setFilter({ ...filter, encodingManager: ge });
  };

  useEffect(() => {
    if (filter.selectedZone == 1) {
      setFilterInputs([
        {
          type: "SELECT",
          options: {
            isSimple: true,
            isClearable: true,
            placeholder: _("partner"),
            options: filter.partnerOptions,
            values: filter.partner,
            action: handlePartnerChange,
          },
        }
      ]);
    } else {
      setFilterInputs([
        {
          type: "SELECT",
          options: {
            isSimple: true,
            placeholder: _("GE"),
            options: filter.encodingManagerOptions,
            values: filter.encodingManager,
            action: handleGEChange,
          },
        }
      ]);
    }
  }, [filter]);

  // TODO: Add Year filter
  let reportingsQuery = useQuery(
    ["getCollaboratorsBonuses", token, navCommunity, filter],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsBonuses(
            token,
            navCommunity.id,
            filter
          );
          const defaultCollaborator = {
            id: null,
            name: "Tous les collaborateurs",
          };
          setCollaborators(response.data.data);
          setData(response.data.data);
          setFilter({ ...filter, nbResult: response.data.nbResult });
          setLoadingCollaborators(false);
        } catch (e) {
          setLoadingCollaborators(false);
          return null;
        }
      }
    }
  );

  const handleChangeYear = (year) => {
    setFilter({
      ...filter,
      year: year,
    });
  };

  const handleChangeZone = (id) => {
    setFilter({
      ...filter,
      selectedZone: id,
    });
  };

  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div className={"customed_select_header"}>
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span
                className={"action"}
                onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
              >
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => {
                    handleChangeYear(item);
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderCustomAddOn = () => {
    return (
      <div className="filter_custom" style={{ justifyContent: "flex-start" }}>
        {renderYearSelect()}
        <div className="mini-tabs-frame">
          {[
            {
              id: "1",
              label: "belgique",
              title: "les collaborateurs de la belgique",
            },
            {
              id: "2",
              label: "maroc",
              title: "les collaborateurs du maroc",
            },
          ].map((item) => (
            <div
              className={
                filter.selectedZone == item.id && "mini-tabs-frame_active"
              }
              onClick={() => handleChangeZone(item.id)}
            >
              {_(item.label)}
              {/* <div className="span">{filter.counters[item.id]}</div> */}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleColumns = (column, line) => {
    if (column.id === "balance") {
      return (
        <div
          className="tags"
          style={{ margin: "0px 10px 2px 2px" }}

          // style={{ marginTop: "20px", justifyContent: "center" }}
        >
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("taches créées")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.balance.createdTasks} %</div>
            </div>
          </div>
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("Blian fini deg")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.balance.balanceFinishedDeg}</div>
            </div>
          </div>
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("Retard")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.balance.delay}</div>
            </div>
          </div>
        </div>
      );
    } else if (column.id === "horus") {
      return (
        <div
          className="tags"
          style={{ margin: "1px 0px 2px 2px" }}
          // style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <div
          // className="labled-tag no-border"
          // className={`no-border ${
          //   data.indexOf(line) === 0 ? "labled-tag " : ""
          // }`}
          >
            {data.indexOf(line) === 0 && <span>{_("client horus")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.horus.clientsHorus} %</div>
            </div>
          </div>
        </div>
      );
    } else if (column.id === "powerteam") {
      return (
        <div
          className="tags"
          style={{ margin: "1px 0px 2px 2px" }}
          // style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <div
          // className="labled-tag no-border"
          // className={`no-border ${
          //   data.indexOf(line) === 0 ? "labled-tag " : ""
          // }`}
          >
            {data.indexOf(line) === 0 && <span>{_("Planner")}</span>}
            <div
              className="tag"
              style={{
                borderLeft: "none",
                justifyContent: "center",
                display: "flex",
                background: "none",
                width: "40px",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  background: "none",
                  width: "40px",
                }}
              >
                {line.powerteam.countOverHoursByWeek}
              </div>
            </div>
          </div>
          <div
          // className="labled-tag no-border"
          // className={`no-border ${
          //   data.indexOf(line) === 0 ? "labled-tag " : ""
          // }`}
          >
            {data.indexOf(line) === 0 && <span>{_("Plan de correction")}</span>}
            <div
              className="tag"
              style={{
                borderLeft: "none",
                justifyContent: "center",
                display: "flex",
                background: "none",
                width: "40px",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  background: "none",
                  width: "30px",
                }}
              >
                {line.powerteam.countDevelopementCard}
              </div>
            </div>
          </div>
          <div
          // className="labled-tag no-border"
          // className={`no-border ${
          //   data.indexOf(line) === 0 ? "labled-tag " : ""
          // }`}
          >
            {data.indexOf(line) === 0 && <span>{_("Rapport d erreur ")}</span>}
            <div
              className="tag"
              style={{
                borderLeft: "none",
                justifyContent: "center",
                display: "flex",
                background: "none",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  background: "none",
                }}
              >
                {line.powerteam.countErrorReport}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (column.id === "ipp_cr") {
      return (
        <div
          className="tags"
          style={{ margin: "1px 0px 2px 2px" }}
          // style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <div
          // className={`no-border ${
          //   data.indexOf(line) === 0 ? "labled-tag " : ""
          // }`}
          >
            {data.indexOf(line) === 0 && <span>{_("taches créées")}</span>}
            <div
              className="tag"
              // style={{ borderLeft: "none", justifyContent: "flex-end" }}
              style={{
                borderLeft: "none",
                justifyContent: "center",
                display: "flex",
                background: "none",
              }}
            >
              <div style={{ width: "30px" }}>{line.ippCr.createdTasks} %</div>
            </div>
          </div>
          <div
          // className={`no-border ${
          //   data.indexOf(line) === 0 ? "labled-tag " : ""
          // }`}
          >
            {data.indexOf(line) === 0 && (
              <span>{_("Presentation déclaré")}</span>
            )}
            <div
              className="tag"
              style={{
                borderLeft: "none",
                justifyContent: "flex-end",
                width: "30px",
              }}
            >
              <div style={{ width: "30px" }}>
                {line.ippCr.isPresentationDeclared}{" "}
              </div>
            </div>
          </div>
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("taches completes")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div style={{ width: "30px" }}>{line.ippCr.completedTaskes} </div>
            </div>
          </div>
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("retard")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.ippCr.delay} </div>
            </div>
          </div>
        </div>
      );
    } else if (column.id === "ipp") {
      return (
        <div
          className="tags"
          style={{ margin: "1px 0px 2px 2px" }}
          // style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("taches créées")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.ipp.createdTasks} %</div>
            </div>
          </div>

          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("taches comptetes")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.ipp.completedTaskes} </div>
            </div>
          </div>
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("retard")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.ipp.delay} </div>
            </div>
          </div>
        </div>
      );
    } else if (column.id === "intermediateSituation") {
      return (
        <div
          className="tags"
          style={{ margin: "1px 0px 2px 2px" }}
          // style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("taches créées")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div style={{ width: "30px" }}>
                {line.intermediateSituation.createdTasks} %
              </div>
            </div>
          </div>
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("Presentation Si")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div style={{ width: "30px" }}>
                {line.intermediateSituation.isPresentationSi}{" "}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (column.id === "timesheet") {
      return (
        <div className="tags" style={{ margin: "20px 10px" }}>
          <div className="labled-tag no-border">
            <span>{_("Encodage Réalisé")}</span>
            <div className="tag">
              <div>
                {/* {renderDurationDisplay(line.timesheet.realizedEncodage)} */}
              </div>
            </div>
          </div>

          <div className="labled-tag no-border">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "85%",
              }}
            >
              <span>{_("Nettoyage Réalisé")}</span>
            </div>
            <div className="tag">
              <div>
                {/* {renderDurationDisplay(line.timesheet.realizedNettoyage)} */}
              </div>
            </div>
          </div>
          <div className="labled-tag no-border">
            <span>{_("Bilan Réalisé")}</span>
          </div>

          <div className="labled-tag no-border">
            <div
              style={{
                display: "flex",
                width: "85%",
              }}
            >
              <span>{_("Rapport")}</span>
            </div>
            <div className="tag">
              <div
                style={{
                  color:
                    line.timesheet.rapport > 2
                      ? " #06D9B1"
                      : line.timesheet.rapport > 1.5
                      ? "#EA8124"
                      : "#eb5757",
                  backgroundColor:
                    line.timesheet.rapport > 2
                      ? " rgba(6, 217, 177, 0.1)"
                      : line.timesheet.rapport > 1.5
                      ? "rgb(235 152 87 / 17%)"
                      : "rgba(235, 87, 87, 0.1)",
                }}
              >
                {line.timesheet.rapport}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  const handleTitle = (item) => {
    const isFirstItem = data.indexOf(item) === 0;
    return (
      <div
        className="matrix_title"
        style={{ paddingTop: isFirstItem ? "50px" : 0, minWidth: "20 px" }}
      >
        <div>{item.name}</div>
      </div>
    );
  };

  const renderDevelopementCardSideBarFrame = () => {
    return (
      <div className="fadePage">
        <div className={`sidebar ${showSideBar !== null && "display"}`}>
          <CollaboratorDevelopmentCard
            lng={lng}
            year={filter.year}
            organization={navCommunity.id}
            collaborator={showSideBar}
            token={token}
            onClose={() => setShowSideBar(null)}
            user={user ? user.firstName + " " + user.lastName : ""}
          />
        </div>
      </div>
    );
  };

  const renderPaginate = () => {
    return (
      <TTPPaginator
        onPageChange={({ selected }) =>
          setFilter({ ...filter, paginationPage: selected + 1 })
        }
        pageSize={filter.pageSize}
        nbResult={filter.nbResult}
        paginationPage={filter.paginationPage}
      />
    );
  };

  const { selectedZone } = filter;

  return (
    <>
      <TTPFilterHorizontalBar
        button
        bg="#FFF"
        filter={filter}
        filters={filterInputs}
        nbResult={filter.nbResult}
        pageSize={filter.pageSize}
        updateFilterContent={setFilter}
        customAddOn={renderCustomAddOn()}
        cssClass="medium-12"
      />
      {hasAccess === "1" ? (
        <div>
          <div className="bonuses_table">
            {loadingCollaborators ? (
              <div className="ripple_loading">
                <img src={"/img/Ripple-1s-150px.svg"} alt="" />
              </div>
            ) : selectedZone == 1 ? (
              data && (
                <div>
                  <div
                    style={{
                      overflowX: "auto",
                      marginTop: 10,
                    }}
                  >
                    <div
                      className="production-frame"
                      style={{ width: "125%", overflowX: "auto", margin: 0 }}
                    >
                      <CustumedMatrix
                        columns={columns}
                        items={data}
                        handleCellule={handleColumns}
                        handleTitle={handleTitle}
                        headerTagBG={"#FFFFFF"}
                      />
                    </div>
                  </div>
                  {renderPaginate()}
                </div>
              )
            ) : (
              <BonusMAPage
                collaborator={encodingManagers.find(em => em.id === filter.encodingManager)}
                period={filter.year}
              />
            )}
          </div>
          {showSideBar && renderDevelopementCardSideBarFrame()}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Bonuses;
