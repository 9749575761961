export const getTableColumns = (secondColumnName) => {
  let columns = [
    {
      title: 'Nom Client',
      dataIndex: 'clientName',
      key: 'clientName',
      align: 'left',
      width: '140px',
    },
  ];

  switch(secondColumnName) {
    case "nettoyage":
    columns.push({
      title: 'Encodage',
      dataIndex: 'encodage',
      key: 'encodage',
      align: 'center',
    });
    columns.push({
      title: 'Nettoyage',
      dataIndex: 'nettoyage',
      key: 'nettoyage',
      align: 'center',
    }); break;
    case "bilan":
    columns.push({
      title: 'Nettoyage',
      dataIndex: 'nettoyage',
      key: 'nettoyage',
      align: 'center',
    });
    columns.push({
      title: 'Bilan',
      dataIndex: 'bilan',
      key: 'bilan',
      align: 'center',
    }); break;
    case "situation":
    columns.push({
      title: 'Nettoyage',
      dataIndex: 'nettoyage',
      key: 'nettoyage',
      align: 'center',
    });
    columns.push({
      title: 'Situation',
      dataIndex: 'situation',
      key: 'situation',
      align: 'center',
    }); break;
    default:
  }

  columns.push({
    title: 'Ratio',
    dataIndex: 'ratio',
    key: 'ratio',
    align: 'ratio',
  });

  return columns;
}
