import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  getClientsSuggestions,
  getCategoriesSuggestions,
  getCollaboratorsPlanner,
  saveCollaboratorsPlanner,
  sendGenericEmail,
  getCollaboratorEmail,
  getCollaboratorsSuggestions
} from "../../../api";
import {
  AlertTriangleIcon,
  CheckFilledIcon,
} from "../../common/Icons";
import moment from "moment";
import "moment/locale/fr";
import "./Planner.local.scss";
import CalendarPage from "./Calendar.js";
import ListView from "./ListView.js";
import SideBarPlanner from "./SideBarPlanner.js";
import InProgressBalances from "./InProgressBalances.js";

import _ from "../../../i18n";
import { IconPlus, IconChevronDown } from "../../common/Icons";
import { colors, TTP_UA_URL } from "../../../config";
import {
  renderAvatar,
  renderDurationDisplay,
} from "../../../utils";
import { Tooltip } from "antd";
import { MsalSignInButton } from "./MsalSignInButton.js";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { MsalSignOutButton } from "./MsalSignOutButton.js";
import { createEvent, updateEvent, getEventCategories, createEventCategory } from "../../../api/msal-graph.js";
import { loginRequest } from "../../../config/msal-config.js";

moment.locale("fr");
const formatDate = (date, dashSeparation = false) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  if (dashSeparation) {
    return `${year}-${month}-${day}`;
  }
  return `${year}/${month}/${day}`;
};

const formatDateForTeams = (
  date,
  withHours = true,
  setHoursToMidnight = false
) => {
  const momentDate = moment(date);

  if (setHoursToMidnight) {
    momentDate.hour(0);
    momentDate.minute(0);
    momentDate.second(0);
    return momentDate.format("Y-MM-DTHH:mm:ss");
  }

  if (withHours) {
    return momentDate.format("Y-MM-DTHH:mm:ss");
  }

  return momentDate.format("Y-MM-DTHH:mm:ss");
};

const Planner = ({
  token,
  navCommunity,
  organizationId,
  collaborator,
  lng,
  year,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const collaborators = useSelector((state) => state.collaborators.data);
  const [currentDate, setCurrentDate] = useState(moment());
  const [clientOptions, setClientOptions] = useState([]);
  const [allClientsData, setAllClientsData] = useState([]);
  const [taskCategoriesOptions, setTaskCategoriesOptions] = useState([]);
  const [collapsedWeeks, setCollapsedWeeks] = useState({});
  const [selectedPeriodTypeId, setSelectedPeriodTypeId] = useState(1);
  const [selectedCalendarTypeId, setSelectedCalendarTypeId] = useState(1);
  const [openAddEvent, setOpenAddEvent] = useState(false);
  const [selectedTypeEvent, setSelectedTypeEvent] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedTitleEvent, setSelectedTitleEvent] = useState(null);
  const [selectedDateEvent, setSelectedDateEvent] = useState(moment());
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("A faire");
  const [selectedDescription, setSelecteddescription] = useState(null);
  const [countDevelopementCardAndReports, setCountDevelopementCardAndReports] =
    useState(null);
  const [selectedDurationHours, setSelectedDurationHours] = useState(0);
  const [selectedRecurrenceDetails, setSelectedRecurrenceDetails] = useState(
    []
  );
  const [selectedDurationTravelHours, setSelectedDurationTravelHours] =
    useState(0);
  const [selectedPlaceRDV, setSelectedPlaceRDV] = useState(null);
  const [selectedDurationMin, setSelectedDurationMin] = useState(0);
  const [selectedDurationTravelMin, setSelectedDurationTravelMin] = useState(0);
  const [selectedDuration, setSelectedDuration] = useState(0);
  const [selectedColor, setSelectedColor] = useState("default");
  const [selectedMeetingInfo, setSelectedMeetingInfo] = useState(null);
  const [typesEventDivIsVisible, setTypesEventDivIsVisible] = useState(null);
  const [clientsDivIsVisible, setClientsDivIsVisible] = useState(null);
  const [categoriesDivIsVisible, setCategoriesDivIsVisible] = useState(null);
  const [statusDivIsVisible, setStatusDivIsVisible] = useState(null);
  const [childrens, setChildrens] = useState([]);
  const [timeSheetByDays, setTimeSheetByDays] = useState([]);
  const [timeSheetNFByDays, setTimeSheetNFByDays] = useState([]);
  const [holidaysInWeek, setHolidaysInWeek] = useState([]);
  const [totalTimeByWeek, setTotalTimeByWeek] = useState([]);
  const [totalRealTimeByWeek, setTotalRealTimeByWeek] = useState([]);
  const [totalRealTimeNFByWeek, setTotalRealTimeNFByWeek] = useState([]);
  const [overtimeByWeek, setOvertimeByWeek] = useState([]);
  const [events, setEvents] = useState([]);
  const [backupEvents, setBackupEvents] = useState([]);
  const [availabilities, setAvailabilities] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [durationsByDay, setDurationsByDay] = useState([]);
  const [workingHours, setWorkingHours] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [currentWeek, setCurrentWeek] = useState(moment());
  const [weekAll, setWeekAll] = useState([]);
  const [currentWeekIndex, setCurrentWeekIndex] = useState(0);
  const [selectedShemaType, setSelectedShemaType] = useState(1);
  const [plannerConfiguration, setPlannerConfiguration] = useState(null);
  const [isElementDragged, setIsElementDragged] = useState(false);
  const [backupInformations, setBackupInformations] = useState(null);
  const [selectedCollaboratorBackupTabs, setSelectedCollaboratorBackupTabs] =
    useState(0);
  const [selectedCollaboratorEmail, setSelectedCollaboratorEmail] =
    useState(null);
  const [applyToChildren, setApplyToChildren] = useState(false);
  const [fisrtDateOccurence, setFisrtDateOccurence] = useState(false);
  const [collaboratorOptions, setCollaboratorOptions] = useState([]);
  const [selectedCollaborators, setSelectedCollaborators] = useState([
    collaborator.id.toString(),
  ]);
  const [selectedBalanceId, setSelectedBalanceId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const [tableVueSelected, setTableVueSelected] = useState(null);
  const [inProgressFilteredData, setInProgressFilteredData] = useState(null);

  const [showOptions, setShowOptions] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const isAuthenticatedToMsal = useIsAuthenticated();
  const { instance, accounts } = useMsal();


  const handleAddClick = (index) => {
    setActiveItem(index);
    setShowOptions((prev) => !prev);
  };

  const calendarTabs = [
    {
      id: 1,
      label: "calendrier",
      title: "calendrier",
    },
    {
      id: 2,
      label: "recapulatif",
      title: "recapulatif",
    },
  ];
  const schemaTypeTabs = [
    {
      id: 1,
      label: "category",
      title: "category",
    },
    {
      id: 2,
      label: "clients",
      title: "clients",
    },
  ];
  const periodTabs = [
    {
      id: 1,
      label: "HEBDOMADAIRE",
      title: "HEBDOMADAIRE",
    },
    {
      id: 2,
      label: "MENSUEL",
      title: "MENSUEL",
    },
  ];
  const statusOptions = [
    { label: "A faire", value: "todo" },
    { label: "En cours", value: "inprogress" },
    { label: "Terminé", value: "done" },
  ];
  const dayMapping = {
    "lun.": "Mon",
    "mar.": "Tue",
    "mer.": "Wed",
    "jeu.": "Thu",
    "ven.": "Fri",
    "sam.": "Sat",
    "dim.": "Sun",
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [orderBy, setOrderBy] = useState("category");
  const [transformedData, setTransformedData] = useState({});
  const [dataListRealised, setDataListRealised] = useState({});
  const [transformedDataClients, setTransformedDataClients] = useState({});
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [budgetProgression, setBudgetProgression] = useState({});
  const [responsables, setResponsables] = useState(null);
  const [toSaveCollaboratorPlanner, setToSaveCollaboratorPlanner] =
    useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const generateWeekDatesOptions = () => {
    const dates = [];
    let currentDayOptions = currentDate.clone().startOf("week");

    for (let i = 0; i < 5; i++) {
      dates.push({
        date: currentDayOptions.format("YYYY-MM-DD"),
        day: currentDayOptions.format("dddd"),
      });
      currentDayOptions = currentDayOptions.add(1, "day");
    }

    return dates;
  };

  const weekDatesOptions = generateWeekDatesOptions();

  const handleOptionClick = (date, data, option) => {
    setShowOptions(false);

    let categoryId =
      selectedTab === "ISOC" ? 8 : selectedTab === "IPM" ? 7 : null;

    const client = clientOptions.find(
      (item) => item.value === parseInt(data.client_id, 10)
    );
    const category = taskCategoriesOptions.find(
      (item) => item.value === categoryId
    );
    setSelectedBalanceId(
      categoryId === 5
        ? "TVA:" + data.tva_id
        : categoryId === 8
        ? "balance:" + data.balance_id
        : null
    );
    setSelectedTypeEvent(option);
    setSelectedStatus("TODO");
    setSelectedClient(client);
    setSelectedEventId(null);
    setSelectedCategory(category);
    setSelecteddescription(null);
    if (option === "balancePresentation") {
      setSelectedDurationHours(1);
    }
    const momentDate = date ? moment(date) : moment();
    if (option === "balancePresentation" && date) {
      momentDate.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
    }
    // if (option === "Ouvrir dans le side bar") {
    //   setSelectedDateEvent(momentDate);
    //   setOpenAddEvent(true);
    // } else {
    setSelectedDate(momentDate);
    setSelectedDateEvent(momentDate);
    setToSaveCollaboratorPlanner(true);
  };

  const handleSaveEvent = (selectedDate) => {
    if (selectedDateEvent) {
      const duration =
        selectedDurationHours && selectedDurationMin
          ? parseInt(selectedDurationHours) * 60 + parseInt(selectedDurationMin)
          : selectedDurationHours
          ? selectedDurationHours * 60
          : selectedDurationMin
          ? selectedDurationMin
          : 0;

      const updatedEvent = {
        id: selectedEventId,
        type: selectedTypeEvent,
        start: selectedDateEvent.format("YYYY-MM-DD"),
        date: selectedDateEvent.format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
        duration: duration,
        meetingPlace: selectedPlaceRDV,
        client_id: selectedClient ? selectedClient.value : null,
        client_name: selectedClient ? selectedClient.label : null,
        category_name: selectedCategory ? selectedCategory.label : null,
        category_id: selectedCategory ? selectedCategory.value : null,
        description: selectedDescription,
        color: selectedColor,
        status: selectedStatus,
        recurrenceDetails: selectedRecurrenceDetails,
      };
      saveCollaboratorPlanner(updatedEvent);
    }
  };
  useEffect(() => {
    if (selectedDateEvent && toSaveCollaboratorPlanner) {
      handleSaveEvent(selectedDateEvent);
      setToSaveCollaboratorPlanner(false);
    }
  }, [toSaveCollaboratorPlanner]);

  let eventsPlanner = useQuery(
    ["getPlannersList", token, organizationId],
    async () => {
      if (token && organizationId) {
        try {
          const response = await getCollaboratorsPlanner(
            organizationId,
            collaborator.id,
            year,
            token
          );
          setEvents(response.data.data.events);
          setBackupEvents(response.data.data.backupEvents);
          setTimeSheetByDays(response.data.data.tasksByDay);
          setTimeSheetNFByDays(response.data.data.tasksNFByDay);
          setHolidays(response.data.data.holidays);
          setAvailabilities(response.data.data.availabilities);
          setDurationsByDay(response.data.data.durationsByDay);
          setWorkingHours(response.data.data.workingHours);
          setBackupInformations(response.data.data.informations);
          setDataListRealised(response.data.data.listVue);
          setCountDevelopementCardAndReports(
            response.data.data.countDevelopementCardAndReports
          );
        } catch (e) {
          return null;
        }
      }
    }
  );

  let taskCateoriesdQuery = useQuery(
    ["getCategoriesSuggestions", token, organizationId],
    async () => {
      if (token && organizationId) {
        try {
          const response = await getCategoriesSuggestions(
            organizationId,
            token
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.label.fr,
              };
            });
          setTaskCategoriesOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let collaboratorsdQuery = useQuery(
    ["getCollaborators", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsSuggestions(
            token,
            navCommunity.id
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
              };
            });
          setCollaboratorOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );
  let clientsdQuery = useQuery(
    ["getClients", token, organizationId],
    async () => {
      if (token && organizationId) {
        try {
          const response = await getClientsSuggestions(
            token,
            [],
            organizationId,
            "isEntity",
            year
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
              };
            });
          setAllClientsData(response.data.data);
          setClientOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  // const { data, refetch } = useQuery(
  //   [
  //     "getCollaboratorInProgressEcheance",
  //     token,
  //     navCommunity.id,
  //     selectedCurentStep,
  //     selectedTvaType,
  //     selectedTab,
  //   ],
  //   async () => {
  //     if (token && navCommunity.id) {
  //       console.log(
  //         "befoore api =====",
  //         navCommunity.id,
  //         collaborator.id,
  //         filterInprogressData.year,
  //         selectedCurentStep,
  //         selectedTvaType,
  //         selectedTab,
  //         filterInprogressData.keyWord,
  //         token
  //       );
  //       setInProgressDataFetching(true);
  //       try {
  //         const response = await getCollaboratorInProgressEcheance(
  //           navCommunity.id,
  //           collaborator.id,
  //           filterInprogressData.year,
  //           selectedCurentStep,
  //           selectedTvaType,
  //           selectedTab,
  //           filterInprogressData.keyWord,
  //           token
  //         );
  //         setInProgressData(response.data.data.data);
  //         setInProgressFilteredData(response.data.data.data);
  //         setInProgressIPMData(response.data.data.IPM);
  //         setInProgressISOCData(response.data.data.ISOC);
  //         setInProgressTVAData(response.data.data.TVA);
  //         setCountByStep(response.data.data.countByStep);
  //         setCountByVatPeriodicity(response.data.data.countByVatPeriodicity);
  //         setInProgressDataFetching(false);
  //       } catch (e) {
  //         return null;
  //       }
  //     }
  //   },
  //   {
  //     enabled: !!token && !!navCommunity.id,
  //   }
  // );

  // useEffect(() => {
  //   refetch();
  // }, [selectedCurentStep, selectedTvaType, selectedTab]);

  // useEffect(() => {
  //   if (filterInprogressData.keyWord !== "") {
  //     const filteredData = inProgressData
  //       ? inProgressData.filter((item) =>
  //           item.client_name
  //             .toLowerCase()
  //             .includes(filterInprogressData.keyWord.toLowerCase())
  //         )
  //       : [];
  //     setInProgressFilteredData(filteredData);
  //   } else {
  //     setInProgressFilteredData(inProgressData);
  //   }
  // }, [filterInprogressData.keyWord]);

  // useEffect(() => {
  //   if (filterInprogressData.sort !== "") {
  //     const sortedData = [...inProgressFilteredData].sort((a, b) => {
  //       const dateA = new Date(formatClosingDate(a.closing_date, true));
  //       const dateB = new Date(formatClosingDate(b.closing_date, true));

  //       if (filterInprogressData.sort === "closing_date_acd") {
  //         if (dateA.getFullYear() === dateB.getFullYear()) {
  //           return dateA.getMonth() - dateB.getMonth();
  //         }
  //         return dateA - dateB;
  //       } else if (filterInprogressData.sort === "closing_date_desc") {
  //         if (dateA.getFullYear() === dateB.getFullYear()) {
  //           return dateB.getMonth() - dateA.getMonth();
  //         }
  //         return dateB - dateA;
  //       }
  //       return 0;
  //     });

  //     setInProgressFilteredData(sortedData);
  //   } else {
  //     setInProgressFilteredData(inProgressData);
  //   }
  // }, [filterInprogressData.sort, inProgressData]);

  const composeEventDetailsForTeams = () => {
    let startDate = selectedDateEvent.clone();
    let endDate = selectedDateEvent.clone();
    const minutesToAdd =
      parseInt(selectedDurationHours) * 60 + parseInt(selectedDurationMin);
    const eventDetails = {};

    console.log({selectedTypeEvent})
    const limitedDurationEvents = [
      "task",
      "meeting",
      "management",
      "balancePresentation",
    ];
    const allDayEvents = ["balanceEcheance", "intermediateSituationBalance"];

    if (limitedDurationEvents.includes(selectedTypeEvent)) {
      let title = "";
      endDate.add(minutesToAdd, "minutes");

      if (selectedTypeEvent === "task") {
        title = `[Tâche] ${selectedClient.label} - ${selectedCategory.label}`;
      } else if (selectedTypeEvent === "meeting") {
        title = `Rendez-vous avec ${selectedClient.label}`;
        eventDetails.isOnlineMeeting = true;
        eventDetails.onlineMeetingProvider = "teamsForBusiness"; // Specify Teams as the meeting provider
        eventDetails.allowNewTimeProposals = true; // Allow attendees to propose new meeting times
        eventDetails.responseRequested = true; // Request responses from attendees
      } else if (selectedTypeEvent === "management") {
        title = `[Gestion] ${selectedTitleEvent}`;
      } else if (selectedTypeEvent === "balancePresentation") {
        title = `[Présentation Bilan] ${selectedClient.label}`;
      }

      eventDetails.subject = title;
    } else if (allDayEvents.includes(selectedTypeEvent)) {
      let title = "";

      startDate.hour(0);
      startDate.minute(0);
      startDate.second(0);
      startDate.millisecond(0);

      endDate.add(1, "day");
      endDate.hour(0);
      endDate.minute(0);
      endDate.second(0);
      endDate.millisecond(0);

      if (selectedTypeEvent === "balanceEcheance") {
        title = `[Échéance Bilan] ${selectedClient.label} - ${selectedCategory.label}`;
      } else if (selectedTypeEvent === "intermediateSituationBalance") {
        title = `[Situation Intermédiaire] ${selectedClient.label} - ${selectedCategory.label}`;
      }

      eventDetails.subject = title;
      eventDetails.isReminderOn = true;
      eventDetails.isAllDay = true;
    }

    eventDetails.start = {
      dateTime: startDate.format("Y-MM-DDTHH:mm:ss"),
      timeZone:
        collaborator.zoneId === 1 ? "Europe/Brussels" : "Africa/Casablanca",
    };
    eventDetails.end = {
      dateTime: endDate.format("Y-MM-DDTHH:mm:ss"),
      timeZone:
        collaborator.zoneId === 1 ? "Europe/Brussels" : "Africa/Casablanca",
    };
    eventDetails.location = {
      displayName: "Microsoft Teams",
    };

    let color = colors.find((colorData) => {
      return colorData.id === selectedColor;
    });

    if (!color) {
      color = colors[0];
    }
    eventDetails.categories = [color.id];

    // add collaborator e-mail
    if (selectedCollaboratorEmail) {
      eventDetails.attendees = [
        {
          emailAddress: {
            address: selectedCollaboratorEmail,
            name: collaborator.name,
          },
          type: "required",
        },
      ];
    }
    // TODO: add client e-mail also

    return eventDetails;
  };

  const saveOutlookEvent = async () => {
    let meetingId = null;
    let meetingUrl = null;

    if (isAuthenticatedToMsal) {
      const eventDetails = composeEventDetailsForTeams();

      if (selectedEventId) {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });

          const eventData = await updateEvent(response.accessToken, selectedMeetingInfo.id, eventDetails);

          meetingId = eventData.id;
          meetingUrl = eventData?.onlineMeeting?.joinUrl;

          setSelectedMeetingInfo({ id: meetingId, url: meetingUrl });
        } catch (error) {
          console.error("Error updating event:", error);
        }
      } else {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });

          const eventData = await createEvent(response.accessToken, eventDetails);

          meetingId = eventData.id;
          meetingUrl = eventData?.onlineMeeting?.joinUrl;

          setSelectedMeetingInfo({ id: meetingId, url: meetingUrl });
        } catch (error) {
          console.error("Error creating event:", error);
        }
      }
    }

    return { meetingId, meetingUrl };
  };

  const saveCollaboratorPlanner = async () => {
    setIsSaving(true);

    if (selectedBalanceId) {
      const [type, id] = selectedBalanceId.split(":");
      setInProgressFilteredData((prevData) =>
        prevData.map((item) =>
          (type === "TVA" && item.tva_id === id) ||
          (type === "balance" && item.balance_id === id)
            ? {
                ...item,
                is_added_to_planner: "1",
                ...(type === "TVA" ? { tva_id: id } : { balance_id: id }),
              }
            : item
        )
      );
    }

    const { meetingId, meetingUrl } = await saveOutlookEvent();

    const baseEventData = {
      id: selectedEventId,
      organizationId,
      type: selectedTypeEvent,
      title: selectedTitleEvent,
      meetingPlace: selectedPlaceRDV,
      collaboratorId: collaborator.id,
      collaborators: selectedCollaborators,
      parentId: selectedEventId,
      clientId: selectedClient ? selectedClient.value : null,
      categoryId: selectedCategory ? selectedCategory.value : null,
      status: selectedStatus,
      date: { date: selectedDateEvent?.format("YYYY-MM-DDTHH:mm:ss.SSSSSS") },
      observation: selectedDescription,
      color: selectedColor,
      backupId: selectedEventId,
      recurrenceDetails: selectedRecurrenceDetails,
      duration:
        parseInt(selectedDurationHours) * 60 + parseInt(selectedDurationMin),
      durationTravel:
        parseInt(selectedDurationTravelHours) * 60 +
        parseInt(selectedDurationTravelMin),
      year,
      token,
      meetingId,
      meetingUrl,
    };

    const existingOccurrences = events.filter(
      (e) => e.idParent === selectedEventId && e.id !== null
    );

    let occurrences = [];
    if (existingOccurrences.length > 0 && applyToChildren) {
      occurrences = updateExistingOccurrences(baseEventData);
    }
    if (
      selectedRecurrenceDetails &&
      selectedRecurrenceDetails.type !== null &&
      existingOccurrences.length === 0
    ) {
      occurrences = generateRecurringEvents(baseEventData);
    }
    let updatedEvents = events;
    try {
      const response = await saveCollaboratorsPlanner(
        selectedEventId,
        organizationId,
        selectedTypeEvent,
        selectedTitleEvent,
        selectedPlaceRDV,
        collaborator.id,
        selectedCollaborators,
        selectedClient ? selectedClient.value : null,
        selectedCategory ? selectedCategory.value : null,
        selectedStatus,
        selectedDateEvent?.format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
        selectedDescription,
        selectedColor,
        selectedRecurrenceDetails,
        selectedBalanceId,
        parseInt(selectedDurationHours) * 60 + parseInt(selectedDurationMin),
        parseInt(selectedDurationTravelHours) * 60 +
          parseInt(selectedDurationTravelMin),
        selectedParentId,
        year,
        token,
        meetingId,
        meetingUrl
      );
      if (response.data.result === "OK") {
        let updatedEvent = response.data.data;
        if (selectedEventId) {
          updatedEvents = events.map((event) =>
            event.id === selectedEventId ? updatedEvent : event
          );
        } else {
          updatedEvents = [...events, updatedEvent];
        }
        setEvents(updatedEvents);
        calculateDurationsByDay(updatedEvents);
        transformDataByWeek(updatedEvents, startDate, endDate, orderBy);
        toast.success(_("successfully_added"));
        let responsables = [
          // {
          //   id: 528409,
          //   name: "Yousra Berradi",
          //   email: "yousra.berradi@oam.ma",
          // },
          // {
          //   id: 458410,
          //   name: "Yassine El Bekkali",
          //   email: "yassine.el.bekkali21@gmail.com",
          // },
          {
            id: collaborator.id,
            name: collaborator.name,
            email: selectedCollaboratorEmail,
          },
        ];

        sendEmailToResponsable(responsables, null, null);
      } else {
        toast.error(_("successfully_added"), { autoClose: true });
      }
    } catch (e) {
      toast.error(_("successfullcdecdcy_added"), { autoClose: true });
      return null;
    } finally {
      setIsSaving(false);
    }

    if (occurrences && occurrences.length > 0) {
      try {
        const savePromises = occurrences.map((occurrence) => {
          return saveCollaboratorsPlanner(
            occurrence.id,
            occurrence.organizationId,
            occurrence.type,
            occurrence.title,
            occurrence.meetingPlace,
            occurrence.collaboratorId,
            selectedCollaborators,
            occurrence.clientId,
            occurrence.categoryId,
            occurrence.status,
            occurrence.date.date,
            occurrence.observation,
            occurrence.color,
            occurrence.recurrenceDetails,
            occurrence.balanceId,
            occurrence.duration,
            occurrence.durationTravel,
            occurrence.parentId,
            occurrence.year,
            token,
            meetingId,
            meetingUrl
          );
        });

        const responses = await Promise.all(savePromises);

        if (responses.every((response) => response.data.result === "OK")) {
          const updatedOcurencesEvents = updatedEvents.map((event) => {
            const matchingResponse = responses.find(
              (res) => res.data.data.id === event.id
            );
            return matchingResponse ? matchingResponse.data.data : event;
          });

          responses.forEach((res) => {
            if (!events.some((event) => event.id === res.data.data.id)) {
              updatedOcurencesEvents.push(res.data.data);
            }
          });
          setEvents(updatedOcurencesEvents);
          calculateDurationsByDay(updatedOcurencesEvents);
          toast.success(_("successfully_added"));
        } else {
          toast.error(_("savingError"), { autoClose: true });
        }
      } catch (e) {
        toast.error(_("savingError"), { autoClose: true });
      } finally {
        setIsSaving(false);
      }
    }
    setSelectedBalanceId(null);
    setOpenAddEvent(false);
    setSelectedCategory(null);
    setSelectedClient(null);
    setSelectedDateEvent(moment());
    setSelectedTypeEvent(null);
    setSelectedTitleEvent(null);
    setSelectedDurationHours(0);
    setSelectedDurationTravelHours(0);
    setSelectedDurationMin(0);
    setSelectedDurationTravelMin(0);
    setSelectedMeetingInfo(null);
    setSelectedEvent(null);
    setSelecteddescription(null);
    setFisrtDateOccurence(null);
    setSelectedCollaborators([String(collaborator.id)]);
    setSelectedRecurrenceDetails(null);
  };

  function calculateDurationsByDay(events) {
    const durationsByDay = {};

    events.forEach((event, index) => {
      let date;
      if (event.date instanceof Date) {
        date = event.date.toISOString().split("T")[0];
      } else if (typeof event.date === "string") {
        date = event.date.slice(0, 10);
      } else if (event.date && event.date.date) {
        date = event.date.date.slice(0, 10);
      } else {
        return;
      }
      if (!durationsByDay[date]) {
        durationsByDay[date] = 0.0;
      }
      durationsByDay[date] += parseFloat(event.duration);
    });

    const result = Object.keys(durationsByDay).map((date) => ({
      date: date,
      duration: durationsByDay[date],
    }));
    setDurationsByDay(result);
    return result;
  }

  const sendEmailToResponsable = async (responsables, client, status) => {
    const recipients = {};
    const dynamicFields = {};
    const promises = responsables.map(async (responsable) => {
      try {
        recipients[responsable.apiId ?? "470189"] = responsable?.email ?? "";
        dynamicFields["{{RECIPIENT}}"] = collaborator.name;

        dynamicFields[
          "{{OBJET}}"
        ] = `Une nouvelle tâche vous a été assignée pour le client ${selectedClient.label} à la date du ${selectedDateEvent}.
        `;
        if (selectedTypeEvent === "task") {
          if (selectedEventId) {
            dynamicFields[
              "{{OBJET}}"
            ] = `Une nouvelle tâche vous a été assignée pour le client ${selectedClient.label} à la date du ${selectedDateEvent}.
        `;
          } else {
            dynamicFields[
              "{{OBJET}}"
            ] = `Une modification sur vos tâche a été faite pour le client ${selectedClient.label} à la date du ${selectedDateEvent}.
       `;
          }
        } else {
          dynamicFields[
            "{{OBJET}}"
          ] = `Un nouveau rendez-vous vous a été assignée pour le client ${selectedClient.label} à la date du ${selectedDateEvent} .
       `;
        }
        dynamicFields["{{ORGANIZATION.NAME}}"] = navCommunity.name;
        dynamicFields["FIDUCIARY_LOGO"] = navCommunity.avatarUrl;
        dynamicFields["{{NOTIF_URL}}"] = `${TTP_UA_URL}/user-space`;
      } catch (error) {
        return null;
      }
    });
    await Promise.all(promises);

    sendGenericEmail(
      token,
      recipients,
      "PLANNER_NOTIFICATION",
      lng,
      dynamicFields,
      organizationId
    );
  };

  useEffect(() => {
    // get Collaborator E-mail
    (async function getSelectedCollaboratorEmail() {
      const response = await getCollaboratorEmail({
        token,
        collaboratorId: collaborator.id,
        organizationId,
      });
      if (response.data?.data && response.data.data.length > 0) {
        setSelectedCollaboratorEmail(response.data.data[0].email);
      }
    })();
  }, []);

  useEffect(() => {
    if (isAuthenticatedToMsal) {
      (async function getAndCreateOutlookColorCategories() {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
        const userExistedCategories = await getEventCategories(
          response.accessToken
        );
        const categories = colors.map((color) => ({
          displayName: color.id,
          color: color.outlookColor,
        }));

        // create outlook event categories if not existed
        for (let i = 0; i < categories.length; i++) {
          if (
            userExistedCategories !== null &&
            !userExistedCategories.value.find(
              (c) => c.displayName === categories[i].displayName
            )
          ) {
            await createEventCategory({
              accessToken: response.accessToken,
              category: categories[i],
            });
          }
        }
      })();
    }
    if (plannerConfiguration && plannerConfiguration.accessToken) {
      // get/create outlook color categories
      (async function getAndCreateOutlookColorCategories() {
        const userExistedCategories = await getEventCategories(
          plannerConfiguration.accessToken
        );
        const categories = colors.map((color) => ({
          displayName: color.id,
          color: color.outlookColor,
        }));

        // create outlook event categories if not existed
        for (let i = 0; i < categories.length; i++) {
          if (
            userExistedCategories !== null &&
            !userExistedCategories.value.find(
              (c) => c.displayName === categories[i].displayName
            )
          ) {
            await createEventCategory({
              accessToken: plannerConfiguration.accessToken,
              category: categories[i],
            });
          }
        }
      })();
    }
  }, [plannerConfiguration]);

  useEffect(() => {
    if (clientOptions.length === 0) {
      clientsdQuery.refetch();
    }
    const startOfWeek = currentDate.clone().startOf("week");
    const endOfWeek = currentDate.clone().endOf("week");
    const days = [];
    const nextWeekDays = [];

    for (let i = 0; i <= 4; i++) {
      const day = startOfWeek.clone().add(i, "days");
      const nextWeekDay = startOfWeek.clone().add(i + 7, "days");

      days.push(day);
      nextWeekDays.push(nextWeekDay);
    }
    setWeekAll(days);
    setCurrentWeek(currentWeek);

    generateWeeks();
  }, [currentMonth, clientOptions]);

  useEffect(() => {
    if (selectedCalendarTypeId === 2) {
      transformDataByWeek(events, startDate, endDate);
      const holidaysInWeek = getHolidaysInWeek(startDate, endDate, holidays);
      const totalTime = calculateTotalHoursPerWeek(
        workingHours,
        holidaysInWeek
      );
      const totalRealTime = calculateTotalDurationForWeek(
        durationsByDay,
        startDate,
        endDate
      );
      const totalRealNFTime = calculateTotalDurationForWeek(
        timeSheetNFByDays,
        startDate,
        endDate
      );
      const overtime = calculateOvertime(totalTimeByWeek, totalRealTimeByWeek);
      setOvertimeByWeek(overtime);
      setHolidaysInWeek(holidaysInWeek);
      setTotalTimeByWeek(totalTime);
      setTotalRealTimeByWeek(totalRealTime);
      setTotalRealTimeNFByWeek(totalRealNFTime);
    }
  }, [weekAll, startDate, endDate]);

  useEffect(() => {
    if (selectedCalendarTypeId === 2 && selectedPeriodTypeId) {
      transformDataByWeek(events, startDate, endDate, orderBy);
    }
  }, [selectedPeriodTypeId]);

  const calculateTotalHoursPerWeek = (hoursPerDay, holidaysInWeek) => {
    let totalMinutes = 0;

    Object.keys(hoursPerDay).forEach((day) => {
      const isHoliday = holidaysInWeek.some((holiday) => {
        const holidayDay = moment(holiday.date).locale("en").format("ddd");
        return holidayDay === day;
      });

      if (!isHoliday) {
        const time = hoursPerDay[day];
        const hours = Math.floor(time);
        const minutes = Math.round((time % 1) * 60);

        totalMinutes += hours * 60 + minutes;
      }
    });

    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    return { totalHours, remainingMinutes };
  };

  const getHolidaysInWeek = (startDate, endDate, holidays) => {
    return holidays.filter((holiday) => {
      const holidayDate = moment(holiday.date);
      return holidayDate.isBetween(startDate, endDate, null, "[]");
    });
  };

  // const transformDataByWeek = (events, startDate, endDate) => {
  //   let firstDay, lastDay;

  //   if (selectedPeriodTypeId === 2) {
  //     firstDay = moment(weeks[0][0]._d);
  //     lastDay = moment(
  //       weeks[weeks.length - 1][weeks[weeks.length - 1].length - 1]._d
  //     );
  //   } else if (selectedPeriodTypeId === 1) {
  //     firstDay = moment(weekAll[0]._d);
  //     lastDay = moment(weekAll[weekAll.length - 1]._d);
  //   }
  //   setStartDate(firstDay);
  //   setEndDate(lastDay);
  //   const weekEvents = events.filter((event) => {
  //     const eventDate = moment(event.date.date);
  //     return (
  //       eventDate.isBetween(firstDay, lastDay, null, "[]") &&
  //       event.type === "task"
  //     );
  //   });

  //   const totalDurationByClientAndCategory = {};

  //   Object.keys(dataListRealised).forEach((key) => {
  //     const event = dataListRealised[key];
  //     const { client_id, category_id, duration } = event;

  //     if (!totalDurationByClientAndCategory[client_id]) {
  //       totalDurationByClientAndCategory[client_id] = {};
  //     }

  //     if (!totalDurationByClientAndCategory[client_id][category_id]) {
  //       totalDurationByClientAndCategory[client_id][category_id] = 0;
  //     }

  //     totalDurationByClientAndCategory[client_id][category_id] += duration;
  //   });

  //   const groupedDataByCategory = weekEvents.reduce((acc, event) => {
  //     const categoryKey = event.category_id;
  //     if (!acc[categoryKey]) {
  //       acc[categoryKey] = {
  //         id: event.category_id,
  //         label: event.category_name,
  //         estimated: 0,
  //         realized: 0,
  //         ecart: 0,
  //         childrens: [],
  //       };
  //     }
  //     const clientKey = event.client_id;
  //     let client = acc[categoryKey].childrens.find((c) => c.id === clientKey);

  //     if (!client) {
  //       client = {
  //         id: clientKey,
  //         label: event.client_name,
  //         estimated: 0,
  //         realized: 0,
  //         ecart: 0,
  //       };
  //       acc[categoryKey].childrens.push(client);
  //     }

  //     client.estimated += event.duration ? parseInt(event.duration) : 0;
  //     client.realized =
  //       totalDurationByClientAndCategory[event.client_id]?.[categoryKey] !==
  //       undefined
  //         ? totalDurationByClientAndCategory[event.client_id][categoryKey]
  //         : 0;
  //     client.ecart = client.estimated - client.realized;

  //     acc[categoryKey].estimated += event.duration
  //       ? parseInt(event.duration)
  //       : 0;

  //     acc[categoryKey].realized =
  //       totalDurationByClientAndCategory[event.client_id]?.[categoryKey] !==
  //       undefined
  //         ? totalDurationByClientAndCategory[event.client_id][categoryKey]
  //         : 0;
  //     acc[categoryKey].ecart =
  //       acc[categoryKey].estimated - acc[categoryKey].realized;

  //     return acc;
  //   }, {});

  //   const groupedDataByClient = weekEvents.reduce((acc, event) => {
  //     const clientKey = event.client_id;
  //     if (!acc[clientKey]) {
  //       acc[clientKey] = {
  //         id: event.client_id,
  //         label: event.client_name,
  //         estimated: 0,
  //         realized: 0,
  //         ecart: 0,
  //         childrens: [],
  //       };
  //     }

  //     const categoryKey = event.category_id;
  //     let category = acc[clientKey].childrens.find((c) => c.id === categoryKey);

  //     if (!category) {
  //       category = {
  //         id: categoryKey,
  //         label: event.category_name,
  //         estimated: 0,
  //         realized: 0,
  //         ecart: 0,
  //       };
  //       acc[clientKey].childrens.push(category);
  //     }

  //     category.estimated += event.duration ? parseInt(event.duration) : 0;
  //     category.realized =
  //       totalDurationByClientAndCategory[event.client_id]?.[categoryKey] !==
  //       undefined
  //         ? totalDurationByClientAndCategory[event.client_id][categoryKey]
  //         : 0;
  //     category.ecart = category.estimated - category.realized;

  //     acc[clientKey].estimated += event.duration ? parseInt(event.duration) : 0;
  //     acc[clientKey].realized +=
  //       totalDurationByClientAndCategory[event.client_id]?.[categoryKey] !==
  //       undefined
  //         ? totalDurationByClientAndCategory[event.client_id][categoryKey]
  //         : 0;
  //     acc[clientKey].ecart = acc[clientKey].estimated - acc[clientKey].realized;

  //     return acc;
  //   }, {});

  //   const categoriesList = Object.values(groupedDataByCategory);
  //   const clientsList = Object.values(groupedDataByClient);
  //   setTransformedDataClients(clientsList);
  //   setTransformedData(categoriesList);

  //   return clientsList;
  // };
  const transformDataByWeek = (events, startDate, endDate) => {
    let firstDay, lastDay;

    if (selectedPeriodTypeId === 2) {
      firstDay = moment(weeks[0][0]._d);
      lastDay = moment(
        weeks[weeks.length - 1][weeks[weeks.length - 1].length - 1]._d
      );
    } else if (selectedPeriodTypeId === 1) {
      firstDay = moment(weekAll[0]._d);
      lastDay = moment(weekAll[weekAll.length - 1]._d);
    }

    setStartDate(firstDay);
    setEndDate(lastDay);

    const weekEvents = events.filter((event) => {
      const eventDate = moment(event.date.date);
      return (
        eventDate.isBetween(firstDay, lastDay, null, "[]") &&
        event.type === "task"
      );
    });

    const totalDurationByClientAndCategory = {};

    Object.keys(dataListRealised).forEach((key) => {
      const event = dataListRealised[key];
      const { client_id, category_id, duration } = event;

      if (!totalDurationByClientAndCategory[client_id]) {
        totalDurationByClientAndCategory[client_id] = {};
      }

      if (!totalDurationByClientAndCategory[client_id][category_id]) {
        totalDurationByClientAndCategory[client_id][category_id] = 0;
      }

      totalDurationByClientAndCategory[client_id][category_id] += duration;
    });

    // Correction pour éviter les doubles calculs pour `realized`
    const processedClientsByCategory = {};

    const groupedDataByCategory = weekEvents.reduce((acc, event) => {
      const categoryKey = event.category_id;

      if (!acc[categoryKey]) {
        acc[categoryKey] = {
          id: categoryKey,
          label: event.category_name,
          estimated: 0,
          realized: 0,
          ecart: 0,
          childrens: [],
        };

        // Initialiser un Set pour suivre les clients déjà traités
        processedClientsByCategory[categoryKey] = new Set();
      }

      const clientKey = event.client_id;
      let client = acc[categoryKey].childrens.find((c) => c.id === clientKey);

      if (!client) {
        client = {
          id: clientKey,
          label: event.client_name,
          estimated: 0,
          realized: 0,
          ecart: 0,
        };
        acc[categoryKey].childrens.push(client);
      }

      client.estimated += event.duration ? parseInt(event.duration) : 0;

      if (!processedClientsByCategory[categoryKey].has(clientKey)) {
        client.realized =
          totalDurationByClientAndCategory[clientKey]?.[categoryKey] !==
          undefined
            ? totalDurationByClientAndCategory[clientKey][categoryKey]
            : 0;

        acc[categoryKey].realized += client.realized;
        processedClientsByCategory[categoryKey].add(clientKey);
      }

      client.ecart = client.estimated - client.realized;

      acc[categoryKey].estimated += event.duration
        ? parseInt(event.duration)
        : 0;
      acc[categoryKey].ecart =
        acc[categoryKey].estimated - acc[categoryKey].realized;

      return acc;
    }, {});

    const groupedDataByClient = weekEvents.reduce((acc, event) => {
      const clientKey = event.client_id;

      if (!acc[clientKey]) {
        acc[clientKey] = {
          id: clientKey,
          label: event.client_name,
          estimated: 0,
          realized: 0,
          ecart: 0,
          childrens: [],
        };
      }

      const categoryKey = event.category_id;
      let category = acc[clientKey].childrens.find((c) => c.id === categoryKey);

      if (!category) {
        category = {
          id: categoryKey,
          label: event.category_name,
          estimated: 0,
          realized: 0,
          ecart: 0,
        };
        acc[clientKey].childrens.push(category);
      }

      category.estimated += event.duration ? parseInt(event.duration) : 0;
      category.realized =
        totalDurationByClientAndCategory[clientKey]?.[categoryKey] !== undefined
          ? totalDurationByClientAndCategory[clientKey][categoryKey]
          : 0;
      category.ecart = category.estimated - category.realized;

      acc[clientKey].estimated += event.duration ? parseInt(event.duration) : 0;
      acc[clientKey].realized += category.realized;
      acc[clientKey].ecart = acc[clientKey].estimated - acc[clientKey].realized;

      return acc;
    }, {});

    const categoriesList = Object.values(groupedDataByCategory);
    const clientsList = Object.values(groupedDataByClient);

    setTransformedDataClients(clientsList);
    setTransformedData(categoriesList);

    return clientsList;
  };

  const formatDuration = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}`;
  };

  const generateWeeks = () => {
    const startOfMonth = currentMonth.clone().startOf("month");
    const endOfMonth = currentMonth.clone().endOf("month");
    const weeks = [];
    const collapsedState = {};

    let weekStart = startOfMonth.clone();
    if (startOfMonth.day() === 6) {
      weekStart.add(2, "days");
    } else if (startOfMonth.day() === 0) {
      weekStart.add(1, "days");
    } else {
      weekStart = startOfMonth.clone().startOf("week").add(0, "days");
    }

    while (weekStart.isBefore(endOfMonth.clone().endOf("week"))) {
      const days = [];
      let day = weekStart.clone();

      for (let i = 0; i < 5; i++) {
        days.push(day.clone());
        day.add(1, "day");
      }

      weeks.push(days);
      weekStart.add(7, "days");
      collapsedState[weeks.length - 1] = true;
    }

    setWeeks(weeks);
    setCollapsedWeeks(collapsedState);
  };

  const renderCustomAddOn = (selectedId, items, type) => {
    return (
      <div className="filter_custom">
        <div className={"mini-tabs-frame"}>
          {items.map((item) => (
            <div
              key={item.id}
              className={selectedId === item.id ? "mini-tabs-frame_active" : ""}
              onClick={() =>
                type === "calendar"
                  ? handleCalendarTypeChange(item.id)
                  : type === "balances"
                  ? setTableVueSelected(item.id)
                  : handlesetSelectedPeriodTypeIdChange(item.id)
              }
            >
              {_(item.label)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderCollaboratorsCustomAddOn = () => {
    const collaboratorBackupTabs = [
      {
        id: 0,
        name: "All",
        avatar: "",
      },
      {
        id: 1,
        name: backupInformations.collaboratorName,
        avatar: backupInformations.collaboratorAvatar,
      },
      {
        id: 2,
        name: backupInformations.backupName,
        avatar: backupInformations.backupAvatar,
      },
    ];
    return (
      <div className="filter_custom">
        <div className={"mini-tabs-frame"}>
          {collaboratorBackupTabs.map((item) => (
            <div
              key={item.id}
              className={
                selectedCollaboratorBackupTabs === item.id
                  ? "mini-tabs-frame_active"
                  : ""
              }
              onClick={() => setSelectedCollaboratorBackupTabs(item.id)}
            >
              <div>{renderAvatar(item.avatar, item.name)}</div>

              {_(item.name)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handlePreviousWeek = () => {
    setWeekAll((prevWeekAll) => {
      const startOfWeek = prevWeekAll[0].clone().startOf("week");
      const days = [];
      for (let i = 0; i <= 4; i++) {
        const day = startOfWeek.clone().add(i - 7, "days");
        days.push(day);
      }
      return days;
    });
  };

  const handleNextWeek = () => {
    setWeekAll((prevWeekAll) => {
      const startOfWeek = prevWeekAll[0].clone().startOf("week");
      const days = [];
      for (let i = 0; i <= 4; i++) {
        const day = startOfWeek.clone().add(i + 7, "days");
        days.push(day);
      }
      return days;
    });
  };
  const calculateTotalDurationForWeek = (
    durationsByDay,
    startDate,
    endDate
  ) => {
    let totalMinutes = 0;

    durationsByDay.forEach((day) => {
      const dayDate = moment(day.date);
      if (dayDate.isBetween(startDate, endDate, null, "[]")) {
        totalMinutes += day.duration;
      }
    });

    const totalHours = totalMinutes ? Math.floor(totalMinutes / 60) : 0;
    const remainingMinutes = totalMinutes ? totalMinutes % 60 : 0;
    return { totalHours, remainingMinutes };
  };

  const calculateOvertime = (totalTimeByWeek, totalRealTimeByWeek) => {
    const totalTimeInMinutes =
      totalTimeByWeek.totalHours * 60 + totalTimeByWeek.remainingMinutes;
    const totalRealTimeInMinutes =
      totalRealTimeByWeek.totalHours * 60 +
      totalRealTimeByWeek.remainingMinutes;

    if (totalRealTimeInMinutes > totalTimeInMinutes) {
      const overtimeInMinutes = totalRealTimeInMinutes - totalTimeInMinutes;

      const overtimeHours = Math.floor(overtimeInMinutes / 60);
      const overtimeMinutes = overtimeInMinutes % 60;

      return { overtimeHours, overtimeMinutes };
    } else {
      return { overtimeHours: 0, overtimeMinutes: 0 };
    }
  };

  const handleCalendarTypeChange = (type) => {
    if (type === 2) {
      handlesetSelectedPeriodTypeIdChange(1);
    }
    setSelectedCalendarTypeId(type);
    setCurrentWeek(moment().startOf("week"));
    setCurrentMonth(moment());
    transformDataByWeek(events, startDate, endDate, orderBy);
  };

  const handlesetSelectedPeriodTypeIdChange = (type) => {
    if (selectedCalendarTypeId !== 2) {
      setSelectedPeriodTypeId(type);
      if (type === 1) {
        const startOfWeek = currentMonth.clone().startOf("week");
        const days = [];
        const nextWeekDays = [];
        for (let i = 0; i <= 4; i++) {
          const day = startOfWeek.clone().add(i, "days");
          days.push(day);
        }
        setWeekAll(days);
        // } else {
        //  setCurrentWeek(currentWeek);
      }
    }
  };

  const generateRecurringEvents = (event, fisrtDateOccurence) => {
    const recurrenceDetails = event.recurrenceDetails;
    if (!recurrenceDetails) return [];

    const occurrences = [];
    const startDate = fisrtDateOccurence
      ? moment(fisrtDateOccurence, "YYYY-MM-DD")
      : moment(event.date.date, "YYYY-MM-DD");
    const endDate = moment(recurrenceDetails.recurrence_end_date, "YYYY-MM-DD");
    let nextOccurrence = startDate.clone();

    if (recurrenceDetails.type === "weekly") {
      while (nextOccurrence.isBefore(endDate)) {
        recurrenceDetails.day_of_week.forEach((day) => {
          let occurrence = nextOccurrence.clone().day(day);
          if (
            occurrence.isSameOrAfter(event.date.date) &&
            occurrence.isBefore(endDate) &&
            occurrence.isAfter(event.date.date)
          ) {
            occurrences.push({
              ...event,
              id: null,
              parentId: event.id,
              date: { date: occurrence.format("YYYY-MM-DD") },
              recurrenceDetails: null,
            });
          }
        });
        nextOccurrence.add(1, "week");
      }
    }

    if (recurrenceDetails.type === "monthly") {
      while (nextOccurrence.isBefore(endDate)) {
        let firstDayOfMonth = nextOccurrence.clone().startOf("month");

        let occurrence = firstDayOfMonth
          .clone()
          .day(recurrenceDetails.day_of_week[0]);

        if (occurrence.isBefore(firstDayOfMonth)) {
          occurrence.add(7, "days");
        }
        occurrence.add((recurrenceDetails.day_of_month - 1) * 7, "days");

        if (
          occurrence.isSameOrAfter(startDate) &&
          occurrence.isBefore(endDate)
        ) {
          occurrences.push({
            ...event,
            id: null,
            parentId: event.id,
            color: event.color,
            date: { date: occurrence.format("YYYY-MM-DD") },
          });
        }

        nextOccurrence.add(1, "month");
      }
    }
    return occurrences;
  };

  const updateExistingOccurrences = (event) => {
    let existingOccurrences = events.filter(
      (e) => e.idParent === event.id && e.id !== null
    );
    if (applyToChildren) {
      existingOccurrences.forEach((occurrence) => {
        occurrence.duration = event.duration;
        occurrence.color = event.color;
        occurrence.meetingPlace = event.meetingPlace;
        occurrence.type = event.type;
        occurrence.title = event.title;
        occurrence.meetingPlace = event.meetingPlace;
        occurrence.collaboratorId = event.collaboratorId;
        occurrence.clientId = event.clientId;
        occurrence.categoryId = event.categoryId;
        occurrence.status = event.status;
        occurrence.observation = event.observation;
        occurrence.duration = event.duration;
        occurrence.durationTravel = event.durationTravel;
        occurrence.organizationId = event.organizationId;
        occurrence.color = event.color;
        occurrence.parentId = event.parentId;
      });
    }
    const newOccurrences = generateRecurringEvents(event, fisrtDateOccurence);
    newOccurrences.forEach((res) => {
      existingOccurrences.push(res);
    });
    return existingOccurrences;
  };

  return (
    <div className="planner-calendar">
      <div className="filters_content">
        <div className=" filters  line_space_content">
          <div className="filters ">
            <div className="calender_type">
              {renderCustomAddOn(
                selectedCalendarTypeId,
                calendarTabs,
                "calendar"
              )}
            </div>
            <div style={{ marginLeft: "12px" }}>
              {renderCustomAddOn(selectedPeriodTypeId, periodTabs, "period")}
            </div>
            <div style={{ marginLeft: "12px" }}>
              {backupInformations &&
                backupInformations.backupId &&
                renderCollaboratorsCustomAddOn(
                  selectedPeriodTypeId,
                  periodTabs,
                  "period"
                )}
            </div>
          </div>
          <div style={{ display: "flex", gap: "8px" }}>
            {isAuthenticatedToMsal ? (
              <>
                <p className="outlook-associated-text">
                  <CheckFilledIcon color="#02af8e" size={14} />
                  <span>Outlook associé</span>
                </p>
                {/* <MsalSignOutButton /> */}
              </>
            ) :
              <MsalSignInButton />
            }

            <button
              onClick={() => {
                setOpenAddEvent(true);
                setSelectedEventId(null);
              }}
            >
              <IconPlus size={10} /> Ajouter un événement
            </button>
          </div>
        </div>
        {eventsPlanner.isFetchedAfterMount &&
          selectedCollaboratorEmail === null && (
            <Tooltip title="L'événement qui sera créé n'apparaîtra pas dans le calendrier Outlook.">
              <div
                style={{
                  display: "flex",
                  gap: "3px",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginTop: "-12px",
                }}
              >
                <AlertTriangleIcon size={12} />
                <p style={{ fontSize: "10px", color: "#EB5757", margin: 0 }}>
                  Pas d'adresse email principale configurée.
                </p>
              </div>
            </Tooltip>
          )}
      </div>
      {eventsPlanner.isFetching ? (
        <div className="ripple_loading">
          <img src={"/img/Ripple-1s-150px.svg"} alt="" />
        </div>
      ) : (
        <>
          {selectedCalendarTypeId === 1 ? (
            <CalendarPage
              token={token}
              organizationId={organizationId}
              setOpenAddEvent={setOpenAddEvent}
              setSelectedDateEvent={setSelectedDateEvent}
              renderDurationDisplay={renderDurationDisplay}
              formatDuration={formatDuration}
              timeSheetByDays={timeSheetByDays}
              timeSheetNFByDays={timeSheetNFByDays}
              selectedTab={selectedTab}
              holidays={holidays}
              durationsByDay={durationsByDay}
              workingHours={workingHours}
              dayMapping={dayMapping}
              events={events}
              backupEvents={backupEvents}
              setEvents={setEvents}
              selectedPeriodTypeId={selectedPeriodTypeId}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
              handlePreviousWeek={handlePreviousWeek}
              handleNextWeek={handleNextWeek}
              weeks={weeks}
              currentDate={currentDate}
              currentWeekIndex={currentWeekIndex}
              weekAll={weekAll}
              collapsedWeeks={collapsedWeeks}
              setCollapsedWeeks={setCollapsedWeeks}
              setSelectedCategory={setSelectedCategory}
              setDurationsByDay={setDurationsByDay}
              selectedDateEvent={selectedDateEvent}
              selectedDurationHours={selectedDurationHours}
              setSelectedDurationHours={setSelectedDurationHours}
              setSelectedDurationTravelHours={setSelectedDurationTravelHours}
              setSelectedDuration={setSelectedDuration}
              selectedDurationMin={selectedDurationMin}
              setSelectedDurationMin={setSelectedDurationMin}
              setSelectedDurationTravelMin={setSelectedDurationTravelMin}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              selectedPlaceRDV={selectedPlaceRDV}
              setSelectedPlaceRDV={setSelectedPlaceRDV}
              selectedDescription={selectedDescription}
              setSelectedDescription={setSelecteddescription}
              setSelectedClient={setSelectedClient}
              setSelectedTypeEvent={setSelectedTypeEvent}
              setSelectedTitleEvent={setSelectedTitleEvent}
              setSelectedEventId={setSelectedEventId}
              selectedMeetingInfo={selectedMeetingInfo}
              setSelectedMeetingInfo={setSelectedMeetingInfo}
              selectedCollaboratorBackupTabs={selectedCollaboratorBackupTabs}
              saveCollaboratorPlanner={saveCollaboratorPlanner}
              isElementDragged={isElementDragged}
              setIsElementDragged={setIsElementDragged}
              selectedRecurrenceDetails={selectedRecurrenceDetails}
              setSelectedRecurrenceDetails={setSelectedRecurrenceDetails}
              setSelectedColor={setSelectedColor}
              selectedEvent={selectedEvent}
              startDate={startDate}
              endDate={endDate}
              setSelectedEvent={setSelectedEvent}
              selectedEventId={selectedEventId}
              backupInformations={backupInformations}
              fisrtDateOccurence={fisrtDateOccurence}
              setSelectedCollaborators={setSelectedCollaborators}
              setFisrtDateOccurence={setFisrtDateOccurence}
            />
          ) : (
            <ListView
              organisationId={organizationId}
              renderCustomAddOn={renderCustomAddOn}
              tableVueSelected={tableVueSelected}
              collaboratorId={collaborator.id}
              collaboratorName={collaborator.name}
              selectedParentId={selectedParentId}
              setSelectedParentId={setSelectedParentId}
              childrens={childrens}
              setChildrens={setChildrens}
              schemaTypeTabs={schemaTypeTabs}
              selectedShemaType={selectedShemaType}
              setSelectedShemaType={setSelectedShemaType}
              transformedData={transformedData}
              transformedDataClients={transformedDataClients}
              formatDuration={formatDuration}
              IconChevronDown={IconChevronDown}
              handleNextWeek={handleNextWeek}
              handlePreviousWeek={handlePreviousWeek}
              startDate={startDate}
              endDate={endDate}
              totalTimeByWeek={totalTimeByWeek}
              totalRealTimeByWeek={totalRealTimeByWeek}
              totalRealTimeNFByWeek={totalRealTimeNFByWeek}
              overtimeByWeek={overtimeByWeek}
              countDevelopementCardAndReports={countDevelopementCardAndReports}
            />
          )}
          {openAddEvent && (
            <SideBarPlanner
              token={token}
              year={year}
              lng={lng}
              organizationId={organizationId}
              collaboratorId={collaborator.id}
              avatar={collaborator.avatar}
              events={events}
              setEvents={setEvents}
              collaboratorName={collaborator.name}
              setSelectedBalanceId={setSelectedBalanceId}
              clientOptions={clientOptions}
              taskCategoriesOptions={taskCategoriesOptions}
              statusOptions={statusOptions}
              selectedClient={selectedClient}
              selectedEventId={selectedEventId}
              selectedCategory={selectedCategory}
              selectedStatus={selectedStatus}
              selectedDescription={selectedDescription}
              selectedDurationHours={selectedDurationHours}
              selectedDurationTravelHours={selectedDurationTravelHours}
              setSelectedTypeEvent={setSelectedTypeEvent}
              setSelectedTitleEvent={setSelectedTitleEvent}
              setSelectedCategory={setSelectedCategory}
              setSelectedClient={setSelectedClient}
              setSelectedStatus={setSelectedStatus}
              setSelectedDurationHours={setSelectedDurationHours}
              setSelectedDurationTravelHours={setSelectedDurationTravelHours}
              setSelectedDurationMin={setSelectedDurationMin}
              setSelectedDurationTravelMin={setSelectedDurationTravelMin}
              setSelectedPlaceRDV={setSelectedPlaceRDV}
              setOpenAddEvent={setOpenAddEvent}
              setSelectedDateEvent={setSelectedDateEvent}
              setSelecteddescription={setSelecteddescription}
              selectedDurationMin={selectedDurationMin}
              selectedDurationTravelMin={selectedDurationTravelMin}
              selectedPlaceRDV={selectedPlaceRDV}
              typesEventDivIsVisible={typesEventDivIsVisible}
              clientsDivIsVisible={clientsDivIsVisible}
              categoriesDivIsVisible={categoriesDivIsVisible}
              statusDivIsVisible={statusDivIsVisible}
              openAddEvent={openAddEvent}
              selectedDateEvent={selectedDateEvent}
              selectedTypeEvent={selectedTypeEvent}
              selectedTitleEvent={selectedTitleEvent}
              budgetProgression={budgetProgression}
              saveCollaboratorPlanner={saveCollaboratorPlanner}
              setSelectedRecurrenceDetails={setSelectedRecurrenceDetails}
              selectedRecurrenceDetails={selectedRecurrenceDetails}
              selectedEvent={selectedEvent}
              responsables={responsables}
              selectedMeetingUrl={selectedMeetingInfo?.url}
              setSelectedMeetingInfo={setSelectedMeetingInfo}
              isSaving={isSaving}
              generateRecurringEvents={generateRecurringEvents}
              applyToChildren={applyToChildren}
              setApplyToChildren={setApplyToChildren}
              setFisrtDateOccurence={setFisrtDateOccurence}
              collaboratorOptions={collaboratorOptions}
              selectedCollaborators={selectedCollaborators}
              setSelectedCollaborators={setSelectedCollaborators}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
          )}
        </>
      )}
      <InProgressBalances
        token={token}
        navCommunity={navCommunity}
        collaboratorId={collaborator.id}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        selectedBalanceId={selectedBalanceId}
        setSelectedBalanceId={setSelectedBalanceId}
        inProgressFilteredData={inProgressFilteredData}
        setInProgressFilteredData={setInProgressFilteredData}
        handleOptionClick={handleOptionClick}
        isPlannerPage={true}
      />
    </div>
  );
};

export default Planner;
