import { formatAmount } from "../../../../../../utils";
import { renderFunctions } from "./services";

export const DEPOSIT_TABLE_VIEW_OPTIONS = [
  {
    value: 'BY_DOCUMENT',
    label: 'Par Document'
  },
  {
    value: 'BY_CLIENT',
    label: 'Par Client'
  }
];

export const DEPOT_TABLE_BY_DEPOT_COLUMNS =  [
  {
    title: 'ID',
    dataIndex: 'depositId',
    key: 'depositId',
    align: 'left',
  },
  {
    title: 'Client',
    dataIndex: 'clientName',
    key: 'clientName',
    align: 'left',
    width: '100px',
  },
  {
    title: 'Dépôt',
    dataIndex: 'docCreationDate',
    key: 'docCreationDate',
    align: 'left',
    render: renderFunctions.dateColumn
  },
  {
    title: 'Date Limite',
    dataIndex: 'processingLimitDate',
    key: 'processingLimitDate',
    align: 'center',
    render: renderFunctions.dateColumn
  },
  // {
  //   title: 'Date X',
  //   dataIndex: 'lastModifiedDate',
  //   key: 'lastModifiedDate',
  //   align: 'center',
  //   render: renderFunctions.dateColumn
  // },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: renderFunctions.depositDocsTableRenderStatusColumn
  },
  {
    title: 'Actions Suivi',
    dataIndex: 'nextActions',
    key: 'nextActions',
    align: 'center',
    render: renderFunctions.depositDocsTableRenderActionColumn
  },
];

export const DEPOT_TABLE_BY_DEPOT_DEMO_DATA = [
  {
    id: 1,
    idDepot: 'D001',
    clientName: 'Cabinet médical du Docteur Castro',
    depositDate: '2024-01-04',
    processLimitDate: '2024-12-19',
    nextActions: 'Aucune action nécessaire'
  },
  {
    id: 2,
    idDepot: 'D002',
    clientName: 'Philippe Weber',
    depositDate: '2024-12-09',
    processLimitDate: '2024-12-20',
    nextActions: 'Rappel envoyé au responsable'
  },
  {
    id: 3,
    idDepot: 'D003',
    clientName: 'Dr. Françoise Jouve',
    depositDate: '2024-12-23',
    processLimitDate: '2024-12-22',
    nextActions: 'Priorité urgente : alerte au manager'
  },
  {
    id: 4,
    idDepot: 'D004',
    clientName: 'Maître Jean-Michel PERARD, Avocat',
    depositDate: '2024-12-24',
    processLimitDate: '2024-12-30',
    nextActions: 'Aucune action nécessaire'
  }
]

export const DEPOT_TABLE_BY_CLIENT_COLUMNS =  [
  {
    title: 'Client',
    dataIndex: 'clientName',
    key: 'clientName',
    align: 'left'
  },
  {
    title: 'Total Dépôts',
    dataIndex: 'totalDeposits',
    key: 'totalDeposits',
  },
  {
    title: 'Total Dépôts Respectés',
    dataIndex: 'totalRespectedDeposits',
    key: 'totalRespectedDeposits',
    align: 'center',
  },
  {
    title: 'Total Dépôts Non Respectés',
    dataIndex: 'totalNonRespectedDeposits',
    key: 'totalNonRespectedDeposits',
  },
  {
    title: '% Dépôts Respectés',
    dataIndex: 'percentageRespectedDeposits',
    key: 'percentageRespectedDeposits',
    render: (_, rowData) => {
      const val = rowData.totalRespectedDeposits * 100 / rowData.totalDeposits;
      return (<span>{val === 0 || val === 100 ? val :formatAmount(val, false)}%</span>);
    },
  },
  {
    title: '% Dépôts Non Respectés',
    dataIndex: 'percentageNonRespectedDeposits',
    key: 'percentageNonRespectedDeposits',
    render: (_, rowData) => {
      const val = rowData.totalNonRespectedDeposits * 100 / rowData.totalDeposits;
      return (<span>{val === 0 || val === 100 ? val : formatAmount(val, false)}%</span>);
    },
  },
];

export const DEPOT_TABLE_BY_CLIENT_DEMO_DATA = [
  {
    id: 1,
    clientName: 'General Business Partners (GBP)',
    totalDeposits: 2,
    totalRespectedDeposits: 1,
    totalNonRespectedDeposits: 1,
    percentageRespectedDeposits: 50,
    percentageNonRespectedDeposits: 50
  },
  {
    id: 2,
    clientName: 'Maître Jean-Michel PERARD, Avocat',
    totalDeposits: 1,
    totalRespectedDeposits: 0,
    totalNonRespectedDeposits: 1,
    percentageRespectedDeposits: 0,
    percentageNonRespectedDeposits: 50
  },
  {
    id: 3,
    clientName: 'Dermaskin Medical',
    totalDeposits: 1,
    totalRespectedDeposits: 1,
    totalNonRespectedDeposits: 0,
    percentageRespectedDeposits: 100,
    percentageNonRespectedDeposits: 0
  }
]
