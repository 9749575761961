import ColumnHeader from "../ColumnHeader/ColumnHeader";
import styles from './RespectProcessingTime.module.scss';
import BorderedCard from "../BorderedCard/BorderedCard";
import { formatTime } from './../../../../../../utils';
import { useEffect, useState } from "react";
import { getCollaboratorProcessingTime } from "../../../../../../api";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

export default function RespectProcessingTime({
  period,
  collaboratorId,
  setDeserveBonus
}) {
  const bonusValue = 250;
  const { token, navCommunity } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [numberOfExcess, setNumberOfExcess] = useState(0);
  const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const [restOfItems, setRestOfItems] = useState([]);
  const [restOfHours, setRestOfHours] = useState(0);

  let encodageProcessingTime = useQuery(
    [("getCollaboratorProcessingTime", token, navCommunity, period, collaboratorId)],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorProcessingTime({
            token,
            organizationId: navCommunity.id,
            collaboratorId: collaboratorId,
            categories: [4],
            period
          });
          let data = response.data.data.processingTimeData;
          let excessData = [];
          if (data?.length > 0) {
            excessData = data.filter(item => item.timesheet.ecart > 0); // get only the ones with excess...
          }
          setData(data);
          setItemsToDisplay(excessData.slice(0, 10))
          setRestOfItems(excessData.slice(10));
          setRestOfHours(excessData.slice(10).reduce((total, item) => parseFloat(total) + item.timesheet.ecart, 0))
          setNumberOfExcess(response.data.data.numberOfExcess)
        } catch (e) {
          console.error(e);
          return null;
        }
      }
    }
  );

  useEffect(() => {
    setDeserveBonus(numberOfExcess === 0);
  }, [numberOfExcess]);

  useEffect(() => {
    encodageProcessingTime.refetch();
  }, [collaboratorId, period])

  return (
    <div className={styles.container}>
      <ColumnHeader
        titleValue={numberOfExcess === 0 ? 100 : (100 - (numberOfExcess * 100 / data.length))}
        title="Respect du temps de traitement"
        isTitleValuePositif={numberOfExcess === 0 || (100 - (numberOfExcess * 100 / data.length)) === 100}
        subtitleValue={numberOfExcess}
        subtitle="Nbr de dépassement"
        isSubtitleValuePositif={numberOfExcess === 0}
        price={bonusValue}
      />

      <BorderedCard>
        <div className={styles.content}>
          <h4 className={styles.title}>Respect du temps de traitement par type de documents comptables</h4>

          <div className={styles.client_list}>
            {itemsToDisplay.map(item => (
              <div className={styles.client_card}>
                <p className={styles.client_name}>{item.clientName}</p>
                <span className={`${styles.client_gap} ${styles.client_gap_negatif}`}>
                  +{formatTime(item.timesheet.ecart)}
                </span>
              </div>
            ))}
          </div>

          {restOfItems.length > 0 && (
            <p className={styles.rest_of_items_text}>
              +{restOfItems.length} dépassements (+{formatTime(restOfHours)})
            </p>
          )}

        </div>
      </BorderedCard>
    </div>
  )
}
