import { useEffect, useRef } from 'react';

/**
 * Custom hook is used when we want to detect the click outside an element and do something when that happened.
 *
 * @param callback the callback to run when the outside click happened.
 * @param excludeElementsRefs elements refs that we want to exclude from the click detection.
 * @returns a reference to use it in the target element.
 */
export function useOuterClick(
  callback,
  excludeElementsRefs,
) {
  const innerRef = useRef(null);
  const callbackRef = useRef();

  // set current callback in ref, before second useEffect uses it
  useEffect(() => {
    // useEffect wrapper to be safe for concurrent mode
    callbackRef.current = callback;
  }, [callback, excludeElementsRefs]);

  useEffect(() => {
    // read most recent callback and innerRef dom node from refs
    function handleClick(e) {
      if (innerRef?.current && callbackRef.current && !innerRef.current.contains(e.target)) {
        if (
          !excludeElementsRefs ||
          !excludeElementsRefs.find((r) => r.current?.contains(e.target))
        ) {
          callbackRef.current(e);
        }
      }
    }

    document.addEventListener('click', handleClick, { capture: true }); // capture the click in the inner components
    return () => document.removeEventListener('click', handleClick);
  }, []); // no need for callback + innerRef dep

  return innerRef; // return ref; client can omit `useRef`
}
