import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import "./BalancesRecap.local.scss";
import BalancesRecapFilter from "./BalancesRecapFilter";
import InProgressBalances from "../../CollaboratorSpace/Planner/InProgressBalances";
import { getBalancePlannerRecap } from "../../../api";
import {
  renderDurationMinutesDisplay,
  convertDecimalToHoursMinutes,
} from "../../../utils";
import { Tooltip } from "antd";
import _ from "../../../i18n";

const Table = ({ filter }) => {
  const { token, navCommunity } = useSelector((state) => state.auth);
  const [expandedWeeks, setExpandedWeeks] = useState({});
  const [currentWeek, setCurrentWeek] = useState(null);
  const [balancesPlannerRecap, setBalancesPlannerRecap] = useState([]);

  useEffect(() => {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const daysSinceStart = Math.floor(
      (now - startOfYear) / (24 * 60 * 60 * 1000)
    );
    const currentWeekIndex = Math.floor(daysSinceStart / 7) + 1;

    // setCurrentWeek(`Semaine ${currentWeekIndex}`);
    setExpandedWeeks({ [`Semaine ${currentWeekIndex}`]: true });
  }, []);

  let reportingsQuery = useQuery(
    ["getBalancePlannerRecap", token, navCommunity, filter],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getBalancePlannerRecap(
            token,
            navCommunity.id,
            filter.partner,
            filter.year,
            filter.collaborator,
            filter.keyWord,
            filter.status
          );
          setBalancesPlannerRecap(response.data.data);
        } catch (e) {
          return null;
        }
      }
    }
  );


  const toggleWeek = (week) => {
    setExpandedWeeks((prev) => ({
      ...prev,
      [week]: !prev[week],
    }));
  };
  const formatDateToFrench = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    if (isNaN(date)) return dateString;
    return date.toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  return (
    <div className="balances_recap_page">
      {reportingsQuery.isFetching ? (
        <div className="ripple_loading">
          <img src={"/img/Ripple-1s-150px.svg"} alt="" />
        </div>
      ) : balancesPlannerRecap && balancesPlannerRecap.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Semaine / Collaborateur</th>
              <th>Bilan Planifié</th>
              <th>Status</th>
              <th>Durée Budgétisé</th>
              <th>Durée Réalisée</th>
              {/* <th>% Charge Atteinte</th> */}
              <th>RDV Présentation</th>
              <th>RDV Situation Intermediaire</th>
            </tr>
          </thead>
          <tbody>
            {balancesPlannerRecap &&
              balancesPlannerRecap.map((weekData, index) => (
                <React.Fragment key={index}>
                  <tr className="week-row">
                    <td className="week-row" colSpan="8">
                      <button onClick={() => toggleWeek(weekData.week)}>
                        {expandedWeeks[weekData.week] ? "−" : "+"}{" "}
                        {weekData.week}
                        <div>
                          {" ( "}
                          {weekData.firstDay} {" - "}
                          {weekData.lastDay} {" ) "}
                        </div>
                      </button>
                    </td>
                  </tr>
                  {expandedWeeks[weekData.week] &&
                    weekData.collaborators.map((collaborator, colIndex) => (
                      <tr key={colIndex} className="collaborator-row">
                        <td>{collaborator.name}</td>
                        <td>{collaborator.planned}</td>
                        <td>
                          <div
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            {collaborator.stepCompletedDate ? (
                              <Tooltip
                                placement="topRight"
                                title={formatDateToFrench(
                                  collaborator.stepCompletedDate
                                )}
                              >
                                <span>{collaborator.status}</span>
                              </Tooltip>
                            ) : (
                              <span>{collaborator.status}</span>
                            )}
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            {convertDecimalToHoursMinutes(collaborator.budget)}
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            {renderDurationMinutesDisplay(
                              collaborator.realized
                            )}
                          </div>
                        </td>
                        {/* <td>
                                     <div
                                       style={{
                                         justifyContent: "center",
                                         display: "flex",
                                       }}
                                     >
                                       <Tooltip
                                         placement="topRight"
                                         title={`${renderDurationMinutesDisplay(
                                           collaborator.estimated
                                         )} / ${collaborator.totalWorkingHours}h`}
                                       >
                                         {Math.round(collaborator.charge)} %
                                       </Tooltip>
                                     </div>
                                   </td> */}
                        <td>
                          <div
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            {formatDateToFrench(collaborator.presentation)}
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            {formatDateToFrench(
                              collaborator.intermediateSituation
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      ) : (
        <div className="no_data">
          <div className="title">{_("noDataFound")}</div>
          <div className="subTitle">{_("noPlannedBalancesFound")}</div>
        </div>
      )}
    </div>
  );
};

export default Table;
