import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import "./BalancesRecap.local.scss";
import BalancesRecapFilter from "./BalancesRecapFilter";
import Table from "./Table";
import InProgressBalances from "../../CollaboratorSpace/Planner/InProgressBalances";

// import TTPFilterHorizontalBar from "../../../common/Filter/TTPFilterHorizontalBar";
import { getBalancePlannerRecap } from "../../../api";
import {
  renderDurationMinutesDisplay,
  convertDecimalToHoursMinutes,
} from "../../../utils";
import { Tooltip } from "antd";
import _ from "../../../i18n";

const BalancesRecap = ({ parentFilter, hasAccess }) => {
  const { token, navCommunity } = useSelector((state) => state.auth);
  const [expandedWeeks, setExpandedWeeks] = useState({});
  const [currentWeek, setCurrentWeek] = useState(null);
  const [balancesPlannerRecap, setBalancesPlannerRecap] = useState([]);
  const [inProgressFilteredData, setInProgressFilteredData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);

  const [filter, setFilter] = useState({
    ...parentFilter,
    year: new Date().getFullYear().toString(),
    keyWord: "",
    collaborator: null,
    status: "",
  });

  useEffect(() => {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const daysSinceStart = Math.floor(
      (now - startOfYear) / (24 * 60 * 60 * 1000)
    );
    const currentWeekIndex = Math.floor(daysSinceStart / 7) + 1;

    setCurrentWeek(`Semaine ${currentWeekIndex}`);
    setExpandedWeeks({ [`Semaine ${currentWeekIndex}`]: true });
  }, []);

  let reportingsQuery = useQuery(
    ["getBalancePlannerRecap", token, navCommunity, filter],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getBalancePlannerRecap(
            token,
            navCommunity.id,
            filter.partner,
            filter.year,
            filter.collaborator,
            filter.keyWord,
            filter.status
          );
          setBalancesPlannerRecap(response.data.data);
          // setLoadingCollaborators(false);
        } catch (e) {
          // setLoadingCollaborators(false);
          return null;
        }
      }
    }
  );

  const handleOptionClick = (week) => {
    return;
  };

  const toggleWeek = (week) => {
    setExpandedWeeks((prev) => ({
      ...prev,
      [week]: !prev[week],
    }));
  };
  const formatDateToFrench = (dateString) => {
    if (!dateString) return ""; // Gestion des cas où la date est vide ou invalide
    const date = new Date(dateString);
    if (isNaN(date)) return dateString; // Si la date est invalide, renvoyer la chaîne d'origine
    return date.toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  return (
    <div className="balances_recap_page">
      {hasAccess === "1" && (
        <div className="page-content">
          <BalancesRecapFilter
            token={token}
            organization={navCommunity && navCommunity.id}
            filter={{ ...filter }}
            updateFilter={setFilter}
          />
          <div className="echeance_container">
            <Table filter={filter} />
            {filter.collaborator && (
              <InProgressBalances
                token={token}
                navCommunity={navCommunity}
                collaboratorId={filter.collaborator}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                selectedBalanceId={null}
                setSelectedBalanceId={null}
                inProgressFilteredData={inProgressFilteredData}
                setInProgressFilteredData={setInProgressFilteredData}
                handleOptionClick={handleOptionClick}
                isPlannerPage={false}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BalancesRecap;
