import axios from "axios";
import { TTP_API_URL } from "../config";

export const getRevisionForfait = (token, organizationId, clientId) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/revision-forfaits`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organizationId,
      clientId: clientId
    },
  });
};

export const getListClient = (token, organizationId) => {
  const requestUrl = `${TTP_API_URL}/team/clients`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organizationId
    },
  });
};

export const saveForfaitRemarks = data => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/revision-forfaits/remarks/add`;
  let formData = new FormData();
  formData.append("access_token", data.token);
  formData.append("clientId", data.client);
  formData.append("organizationId", data.organization);
  formData.append("year", data.year);
  formData.append("cause", data.causes);
  formData.append("forfait", data.forfaits);
  formData.append("proposal", data.proposals);

  return axios.post(requestUrl, formData);
};

export const getForfaitRemarks = (token, organization, client, year) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/revision-forfaits/remarks`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organization,
      clientId: client,
      year: year,
    },
  });
};

export const saveForfaitMissionLetter = (data) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/revision-forfaits/update`;
  let formData = new FormData();
  formData.append("access_token", data.token);
  formData.append("clientId", data.client);
  formData.append("organizationId", data.organization);
  formData.append("params", data.params);

  return axios.post(requestUrl, formData);
}

export const updateClientVolumetry = (data) => {
  const requestUrl = `${TTP_API_URL}/team/client/volumetry/update`;
  let formData = new FormData();
  formData.append("access_token", data.token);
  formData.append("clientId", data.client);
  formData.append("organizationId", data.organization);
  formData.append("year", data.year);
  formData.append("type", data.type);
  formData.append("value", data.value);

  return axios.post(requestUrl, formData);
}