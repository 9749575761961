import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ReferenceLine } from 'recharts';

export default function ENBarChart({
  data,
  chartName,
  referenceLineY
}) {
  return (
    <BarChart
      width={340}
      height={220}
      data={data}
      margin={{
        top: 0,
        right: 0,
        left: -38,
        bottom: 0,
      }}
      barSize={16}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="name"
        tick={{ angle: -45, textAnchor: 'end', fontSize: 10 }}
        tickFormatter={(value) => (value.length > 7 ? `${value.substring(0, 7)}...` : value)}
        interval={4}
        height={80}
      />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar
        dataKey="ratio"
        name={chartName}
        fill="#18a0fb"
        background={{ fill: '#eee' }}
      >
        <LabelList dataKey="name" content={(props) => <span>{props.x}</span>} fontSize={5} />
      </Bar>
      <ReferenceLine alwaysShow y={referenceLineY} stroke="orange" position='middle' strokeWidth={2} />
    </BarChart>
  );
}
