import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "antd/dist/antd.css";
import "./styles/index.scss";
import App from "./App";
import { store } from "./store";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/msal-config";
import { PublicClientApplication } from "@azure/msal-browser";

// import * as Sentry from "@sentry/browser";

// import * as serviceWorker from './serviceWorker';

// Sentry.init({dsn: "https://9c3f37c4390448859c2f079fb8c23903@sentry.tamtam.pro/21"});

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
