import { React, useEffect, useState } from "react";
import PageHeader from "../common/PageHeader";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styles from "./Budget.module.scss";
import classNames from "classnames";
// import Select from "react-select";
import _ from "../../i18n";
import ClientInformations from "./ClientInformations";
import GlobalBudget from "./GlobalBudget";
import TaskManagement from "./TaskManagement";
import RolesManagement from "./RolesManagement";
import Settings from "./Settings";
import {
  saveBudgetVolumetrie,
  saveBudgetOffre,
  saveClientBudgetSetting,
} from "../../api";
import {
  fetchClientBudget,
  fetchCollaborators,
  fetchSquads,
  fetchModelesRepartition,
  fetchClusters,
  fetchBU,
} from "../../store";
import moment from "moment";
const statusMapping = {
  UNAUTHORIZED_BUDGET: 1,
  INFORMATIONS_CLIENT_COMPLETED: 2,
  BUDGET_GLOBAL_COMPLETED: 3,
  MANAGEMENT_TACHES_COMPLETED: 4,
  BUDGET_COMPLETED: 5,
};
const Budget = () => {
  const { navCommunity, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.clientBudget.data);
  const models = useSelector((state) => state.modeles.data);
  const collaborators = useSelector((state) => state.collaborators.data);
  const squads = useSelector((state) => state.squads.data);
  const bu = useSelector((state) => state.bu.data);
  const clusters = useSelector((state) => state.clusters.data);
  const { clientID, year } = useParams();
  const [budgetStatus, setBudgetStatus] = useState("");
  const [updatedData, setUpdatedData] = useState([]);
  const [updatedDataVolumetrie, setUpdatedDataVolumetrie] = useState([]);
  const [updatedClientInformationData, setUpdatedClientInformationData] =
    useState([]);
  const [updatedDataCategoryBudget, setUpdatedDataCategoryBudget] = useState(
    []
  );
  const [updatedCategoryRoleList, setUpdatedCategoryRoleList] = useState([]);
  const [updatedCollaboratorsList, setUpdatedCollaboratorsList] = useState([]);
  const [updatedDataOffre, setUpdatedDataOffre] = useState([]);
  const [totalGD, settotalGD] = useState(0);
  const [totalGE, settotalGE] = useState(0);
  const [totalSE, settotalSE] = useState(0);
  const [totalSupervisor, settotalsupervisor] = useState(0);
  const [totalScanOp, settotalScanOp] = useState(0);
  const [totalGDdelegue, settotalGDdelegue] = useState(0);
  const [totalGEdelegue, settotalGEdelegue] = useState(0);
  const [totalSEdelegue, settotalSEdelegue] = useState(0);
  const [totalSupervisorDelegue, settotalsupervisorDelegue] = useState(0);
  const [totalScanOpDelegue, settotalScanOpDelegue] = useState(0);
  const [activeStep, setactiveStep] = useState(1);
  const [updated, setUpdated] = useState(false);
  const [selectedModel, setSelectedModel] = useState([]);
  const [selectedModelOption, setSelectedModelOption] = useState([]);
  const [startCategories, setStartCategories] = useState([]);
  const [departCategories, setDepartCategories] = useState([]);
  const [selectedStartDates, setSelectedStartDates] = useState([]);
  const [selectedDepartDates, setSelectedDepartDates] = useState([]);
  const [startDatesActivated, setStartDatesActivated] = useState(false);
  const [startDatesFacturation, setStartDatesFacturation] = useState(moment());
  const [startDatesTask, setStartDatesTask] = useState(moment());
  const [departDatesActivated, setDepartDatesActivated] = useState(false);
  const [departDatesFacturation, setDepartDatesFacturation] = useState(moment());
  const [departDatesTask, setDepartDatesTask] = useState(moment());
  const [communicatedDeparture, setCommunicatedDeparture] = useState(moment());
  const [atRiskClientActivated, setAtRiskClientActivated] = useState(false);
  const [leavingRiskObservation, setLeavingRiskObservation] = useState(null);
  const [departYear, setDepartYear] = useState(null);
  const [preferentialTariff, setPreferentialTariff] = useState(false);
  const [normalVoyant, setNormalVoyant] = useState([]);
  const [mediumVoyantFrom, setMediumVoyantFrom] = useState([]);
  const [mediumVoyantTo, setMediumVoyantTo] = useState([]);
  const [highVoyant, setHighVoyant] = useState([]);
  const [isAccessAllowed, setIsAccessAllowed] = useState(false);
  const [isAccessAllowedToBudget, setIsAccessAllowedToBudget] = useState(false);
  const [stepsFiltred, setStepsFiltred] = useState([]);
  const [isSettingsTabActive, setIsSettingsTabActive] = useState(false);
  const auth = useSelector((state) => state.auth);
  const clientTypeId = data && data.client_type;
  const existingRoles = data && data.existingRoles;
  const filteredOptions =
    models &&
    models.modeles &&
    models.modeles[clientTypeId] &&
    models.modeles[clientTypeId].modeles
      ? models.modeles[clientTypeId].modeles.filter(
          (option) => option.labelModel && option.labelModel.trim() !== ""
        )
      : [];
  const statusNumber = statusMapping[activeStep] || 0;

  useEffect(() => {
    if (auth.navCommunity) {
      if (data.length !== 0 || data.client_id !== clientID) {
        dispatch(fetchClientBudget({ clientID, year }));
      }
      if (collaborators.length === 0) {
        dispatch(fetchCollaborators(year));
      }
      if (squads.length === 0) {
        dispatch(fetchSquads(""));
      }
      if (bu.length === 0) {
        dispatch(fetchBU(""));
      }
      if (clusters.length === 0) {
        dispatch(fetchClusters(""));
      }
    }
  }, [auth.navCommunity,clientID,year]);

  useEffect(() => {
    if (data.length !== 0 || data.client_id !== clientID) {
      if (data.length !== 0) {
        setUpdatedData(data);
        //setactiveStep(statusMapping[data.hourly_budget_status] || 1);
        setUpdatedDataVolumetrie(data.volumetrie_list);
        setBudgetStatus(data.hourly_budget_status);
        setStartCategories(data.settings.start_categories);
        setDepartCategories(data.settings.depart_categories);
        setSelectedStartDates(data.settings.start_dates);
        setSelectedDepartDates(data.settings.depart_dates);

        if (data.length !== 0 && activeStep === "") {
          setactiveStep(statusMapping[data.hourly_budget_status] || 1);
        }
        const leavingRiskObservation = data.settings.leaving_risk_observation;
        setLeavingRiskObservation(leavingRiskObservation);
        setAtRiskClientActivated(leavingRiskObservation !== null && leavingRiskObservation !== "");

        setUpdatedClientInformationData(data.client_information);
        setUpdatedDataOffre(data.offre_prix);
        setUpdatedDataCategoryBudget(data.category_budget);
        setUpdatedCategoryRoleList(data.modele_budget);
        setUpdatedCollaboratorsList(data.collaborators);

        const departDates = data.settings.depart_dates;
        setDepartDatesActivated(departDates != null && (
          departDates.communicated_departure != null ||
          departDates.depart_invoice != null ||
          departDates.depart_time_sheet != null)
        );

        if (departDates && moment(departDates.communicated_departure, 'DD-MM-YYYY').isValid()) {
          setCommunicatedDeparture(moment(departDates.communicated_departure, 'DD-MM-YYYY'));
          setDepartYear(moment(departDates.communicated_departure, 'DD-MM-YYYY').format("YYYY"));
        }

        if (departDates && moment(departDates.depart_time_sheet).isValid()) {
          setDepartDatesTask(moment(departDates.depart_time_sheet, 'DD-MM-YYYY'));
        }

        if (departDates && moment(departDates.depart_invoice).isValid()) {
          setDepartDatesFacturation(moment(departDates.depart_invoice, 'DD-MM-YYYY'));
        }

        const startDates = data.settings.start_dates;
        setStartDatesActivated(startDates != null && (startDates.start_time_sheet != null || startDates.start_invoice != null));

        if (startDates && moment(startDates.start_invoice).isValid()) {
          setStartDatesFacturation(moment(startDates.start_invoice, 'DD-MM-YYYY'));
        }

        if (startDates && moment(startDates.start_time_sheet).isValid()) {
          setStartDatesTask(moment(startDates.start_time_sheet, 'DD-MM-YYYY'));
        }

        setPreferentialTariff(data.settings.preferential_tariff);
        setNormalVoyant(data.settings.voyant.normal);
        setMediumVoyantFrom(data.settings.voyant.medium.from);
        setMediumVoyantTo(data.settings.voyant.medium.to);
        setHighVoyant(data.settings.voyant.high);

        const gdCollaborators = data.collaborators["GD"];
        const gdPrincipaleValue =
          gdCollaborators && gdCollaborators["principal"]["value"];
        const gdDelegatedTotal =
          gdCollaborators && gdCollaborators["delegated"]
            ? gdCollaborators["delegated"].reduce(
                (total, delegate) => total + delegate.value,
                0
              )
            : 0;
        const gdTotal =
          parseInt(gdPrincipaleValue) + parseInt(gdDelegatedTotal);
        settotalGDdelegue(gdDelegatedTotal);
        settotalGD(gdTotal);

        const geCollaborators = data.collaborators["GE"];
        const gePrincipaleValue =
          geCollaborators && geCollaborators["principal"]["value"];
        const geDelegatedTotal =
          geCollaborators && geCollaborators["delegated"]
            ? geCollaborators["delegated"].reduce(
                (total, delegate) => total + delegate.value,
                0
              )
            : 0;
        const geTotal = gePrincipaleValue + geDelegatedTotal;
        settotalGEdelegue(geDelegatedTotal);
        settotalGE(geTotal);
        const seCollaborators = data.collaborators["SE"];
        const sePrincipaleValue =
          seCollaborators &&
          seCollaborators["principal"] &&
          seCollaborators["principal"]["value"];
        const seDelegatedTotal =
          seCollaborators &&
          seCollaborators["delegated"].reduce(
            (total, delegate) => total + delegate.value,
            0
          );
        const seTotal = sePrincipaleValue + seDelegatedTotal;
        settotalSEdelegue(seDelegatedTotal);
        settotalSE(seTotal);
        const supervisorCollaborators = data.collaborators["SUPERVISOR"];
        const supervisorPrincipaleValue =
          supervisorCollaborators &&
          supervisorCollaborators["principal"] &&
          supervisorCollaborators["principal"]["value"];
        const supervisorDelegatedTotal =
          supervisorCollaborators &&
          supervisorCollaborators["delegated"].reduce(
            (total, delegate) => parseInt(total) + parseInt(delegate.value),
            0
          );

        const supervisorTotal =
          parseInt(supervisorPrincipaleValue) +
          parseInt(supervisorDelegatedTotal);
        settotalsupervisorDelegue(supervisorDelegatedTotal);
        settotalsupervisor(supervisorTotal);
        const scanOpCollaborators = data.collaborators["ScanOp"];
        const scanOpPrincipaleValue =
          scanOpCollaborators &&
          scanOpCollaborators["principal"] &&
          scanOpCollaborators["principal"]["value"];
        const scanOpDelegatedTotal =
          scanOpCollaborators &&
          scanOpCollaborators["delegated"].reduce(
            (total, delegate) => parseInt(total) + parseInt(delegate.value),
            0
          );
        const scanOpTotal =
          parseInt(scanOpPrincipaleValue) + parseInt(scanOpDelegatedTotal);
        settotalScanOpDelegue(scanOpDelegatedTotal);
        settotalScanOp(scanOpTotal);

        if (models.length === 0) {
          dispatch(fetchModelesRepartition({ year, clientTypeId }));
        }
      }
    }
  }, [data]);

  let steps = [
    { id: 1, property: "Informations client", status: "activated" },
    { id: 2, property: "Budget global", status: false },
    { id: 3, property: "Gestion des taches", status: false },
    { id: 4, property: "Gestion des rôles", status: false },
    // { id: 5, property: "Paramètre client", status: false },
  ];

  const preferences =
    user &&
    navCommunity &&
    user.userOrganizations &&
    user.userOrganizations.length > 0
      ? user.userOrganizations.find(
          (organization) => organization.id === navCommunity.id
        ).authorisation.preferences
      : {};

  const handleValidatedSteps = (step) => {
    const stepId = step.id;
    const budgetStatusId = statusMapping[budgetStatus];
    return budgetStatusId && budgetStatusId >= stepId;
  };

  const handleStepsBudget = (step) => {
    return (
      <div
        className={styles.step}
        onClick={() => {
          setIsSettingsTabActive(false)
          return handleValidatedSteps(step) && setactiveStep(step.id)
        }}
      >
        <div className={styles.text}>
          <div
            className={classNames(
              styles.label,
              step.id <= activeStep && activeStep <=4 && styles.active_label,
              isSettingsTabActive && step.id === 5 && styles.active_label
            )}
          >
            {step.property}
          </div>
        </div>
        <div
          className={classNames(
            styles.cercle,
            step.id <= activeStep && styles.selected,
            step.id === activeStep && styles.active_step
          )}
        >
          {step.id !== 4 && (
            <span
              style={{
                borderLeft:
                  step.id < activeStep &&
                  styles.selected &&
                  "4px solid #18A0FB",
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const handleChangeRolesValues = () => {
    const rolesOrder = Object.keys(updatedCollaboratorsList);
    setUpdatedCollaboratorsList((prevCollaboratorsList) => {
      const updatedCollaborators = { ...prevCollaboratorsList };
      rolesOrder.forEach((role) => {
        const principalValue = parseInt(
          parseInt(
            role === "GD"
              ? totalGD
              : role === "GE"
              ? totalGE
              : role === "SE"
              ? totalSE
              : role === "SUPERVISOR"
              ? totalSupervisor
              : totalScanOp
          ) -
            parseInt(
              role === "GD"
                ? totalGDdelegue
                : role === "GE"
                ? totalGEdelegue
                : role === "SE"
                ? totalSEdelegue
                : role === "SUPERVISOR"
                ? totalSupervisorDelegue
                : totalScanOpDelegue
            )
        );

        updatedCollaborators[role] = {
          ...updatedCollaborators[role],
          principal: {
            ...updatedCollaborators[role].principal,
            value: principalValue,
          },
        };
      });
      return updatedCollaborators;
    });
  };

  const handlesettotalGD = (value) => {
    settotalGD(value);
  };

  const handlesettotalGE = (value) => {
    settotalGE(value);
  };

  const handlesettotalSE = (value) => {
    settotalSE(value);
  };

  const handlesettotalsupervisor = (value) => {
    settotalsupervisor(value);
  };

  const handleSaveClientInformation = (data) => {
    setUpdatedData(data);
  };

  const handleTacheStepBudget = (step) => {
    return (
      <div>
        <TaskManagement
          title={"Répartition des tâches par rôle"}
          descrption={"Petite description à prévoir ici..."}
          subTitle={"Total heures estimées"}
          preferences={preferences}
          data={data}
          year={2022}
          prevYear={2023}
          columnNumber={5}
          isGestionTask={true}
          setUpdated={setUpdated}
          filteredOptions={filteredOptions}
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
          selectedModelOption={selectedModelOption}
          setSelectedModelOption={setSelectedModelOption}
          settotalGD={settotalGD}
          settotalGE={settotalGE}
          settotalSE={settotalSE}
          settotalsupervisor={settotalsupervisor}
          settotalScanOp={settotalScanOp}
          totalGD={totalGD}
          totalGE={totalGE}
          totalSE={totalSE}
          totalSupervisor={totalSupervisor}
          totalScanOp={totalScanOp}
          totalGDdelegue={totalGDdelegue}
          settotalGDdelegue={settotalGDdelegue}
          totalGEdelegue={totalGEdelegue}
          settotalGEdelegue={settotalGEdelegue}
          totalSEdelegue={totalSEdelegue}
          settotalSEdelegue={settotalSEdelegue}
          totalSupervisorDelegue={totalSupervisorDelegue}
          settotalsupervisorDelegue={settotalsupervisorDelegue}
          totalScanOpDelegue={totalScanOpDelegue}
          settotalScanOpDelegue={settotalScanOpDelegue}
          updatedCategoryRoleList={updatedCategoryRoleList}
          setUpdatedCategoryRoleList={setUpdatedCategoryRoleList}
          handlesettotalGD={handlesettotalGD}
          handlesettotalGE={handlesettotalGE}
          handlesettotalSE={handlesettotalSE}
          handlesettotalsupervisor={handlesettotalsupervisor}
          updatedCollaboratorsList={updatedCollaboratorsList}
          handleChangeRolesValues={handleChangeRolesValues}
          existingRoles={existingRoles}
          setUpdatedCollaboratorsList={setUpdatedCollaboratorsList}
        />
      </div>
    );
  };

  const handleChangeStep = (stepId) => {
    if (activeStep === 1 && (updated || statusMapping[budgetStatus] <= 1)) {
      handleSaveGlobalInformations();
    } else if (
      activeStep === 3 &&
      (updated && statusMapping[budgetStatus] <= 3)
    ) {
      handleSaveTaskManagementBudget();
      handleSaveCollaboratorsRoleBudget();
    } else if (
      activeStep === 4 &&
      (updated || statusMapping[budgetStatus] <= 4)
    ) {
      handleSaveCollaboratorsRoleBudget();
    } else if (activeStep === 5) {
      handleSaveClientBudgetSetting();
    }
    if (stepId !== 6) {
      if (
        activeStep !== 2 ||
        (activeStep === 2 &&
          updatedDataCategoryBudget &&
          updatedDataCategoryBudget[0] &&
          updatedDataCategoryBudget[0][year] !== 0 &&
          updatedDataOffre &&
          updatedDataOffre[0] &&
          updatedDataOffre[0]["value"] !== 0)
      )
        if (activeStep === 2) {
          setBudgetStatus("MANAGEMENT_TACHES_COMPLETED");
        }
      if (activeStep === 1 && !isAccessAllowedToBudget) {
        setactiveStep(3);
      } else setactiveStep(stepId);
    }
  };

  const handleSaveGlobalInformations = () => {
    let squadId = null;
    let buId = null;
    let clusterId = null;

    updatedClientInformationData.map((element, i) => {
      if (element.property === "Squad") {
        squadId = element.value;
      } else if (element.property === "BU") {
        buId = element.value;
      } else if (element.property === "Cluster") {
        clusterId = element.value;
      }
    });
    saveBudgetVolumetrie(
      data.client_id,
      updatedDataVolumetrie,
      auth.navCommunity.id,
      squadId,
      clusterId,
      buId,
      statusMapping[budgetStatus] <= 1 ? "INFORMATIONS_CLIENT_COMPLETED" : "",
      year,
      auth.token
    )
      .then((resp) => {
        if (resp.data.result === "OK") {
          if (statusMapping[budgetStatus] <= 1) {
            setBudgetStatus("INFORMATIONS_CLIENT_COMPLETED");
          }
          toast.success(_("successfully_added"));
        }
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      });
    setUpdated(false);
  };

  const handleSaveTaskManagementBudget = () => {
    saveBudgetOffre(
      data.client_id,
      auth.navCommunity.id,
      updatedDataOffre,
      // budgetCategory,
      [],
      updatedCategoryRoleList,
      updatedCollaboratorsList,
      statusMapping[budgetStatus] <= 1 ? "MANAGEMENT_TACHES_COMPLETED" : "",
      year,
      auth.token
    )
      .then((resp) => {
        if (resp.data.result === "OK") {
          if (statusMapping[budgetStatus] <= 3) {
            setBudgetStatus("MANAGEMENT_TACHES_COMPLETED");
          }
          toast.success(_("successfully_added"));
        }
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      });
    setUpdated(false);
  };

  const handleSaveCollaboratorsRoleBudget = () => {
    saveBudgetOffre(
      data.client_id,
      auth.navCommunity.id,
      null,
      null,
      null,
      updatedCollaboratorsList,
      "BUDGET_COMPLETED",
      year,
      auth.token
    )
      .then((resp) => {
        if (resp.data.result === "OK") {
          if (statusMapping[budgetStatus] <= 4) {
            setBudgetStatus("BUDGET_COMPLETED");
          }
          toast.success(_("successfully_added"));
        }
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      });
    setUpdated(false);
  };
  const handleSaveClientBudgetSetting = () => {
    let startDatesConfig = null;
    let departDatesConfig = null;
    let leavingRiskObservationText = null;

    if (departDatesActivated) {
      departDatesConfig = {
        activated: departDatesActivated,
        depart_invoice: departDatesFacturation.format('DD-MM-YYYY'),
        depart_time_sheet: departDatesTask.format('DD-MM-YYYY'),
        communicated_departure: communicatedDeparture.format('DD-MM-YYYY'),
      };
    }

    if (startDatesActivated) {
      startDatesConfig = {
        activated: startDatesActivated,
        start_invoice: startDatesFacturation.format('DD-MM-YYYY'),
        start_time_sheet: startDatesTask.format('DD-MM-YYYY'),
      };
    }

    if (atRiskClientActivated) {
      leavingRiskObservationText = leavingRiskObservation;
    }

    let voyant = {
      normal: normalVoyant,
      medium: { from: mediumVoyantFrom, to: mediumVoyantTo },
      high: highVoyant,
    };
    saveClientBudgetSetting(
      data.client_id,
      auth.navCommunity.id,
      startCategories,
      departCategories,
      startDatesConfig,
      departDatesConfig,
      preferentialTariff,
      voyant,
      year,
      auth.token,
      leavingRiskObservationText,
      departYear
    )
      .then((resp) => {
        if (resp.data.result === "OK") {
          setBudgetStatus("BUDGET_COMPLETED");
          dispatch(fetchClientBudget({ clientID, year }));
          toast.success(_("successfully_added"));
        }
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      });
    setUpdated(false);
  };

  useEffect(() => {
    setIsAccessAllowed(preferences.allowAccessToClientInformations === "1");
    setIsAccessAllowedToBudget(preferences.allowAccessToGlobalBudget === "1");

    if (!isAccessAllowed && isAccessAllowedToBudget) {
      setactiveStep(2);
    }
    if (isAccessAllowed) {
      setactiveStep(1);
    }
    if (!isAccessAllowedToBudget && !isAccessAllowed) {
      setactiveStep(3);
    }
    setStepsFiltred(
      steps.filter(
        (i) =>
          (i.id !== 1 && i.id !== 2) ||
          (i.id === 1 && isAccessAllowed) ||
          (i.id === 2 && isAccessAllowedToBudget)
      )
    );
  }, [isAccessAllowed, isAccessAllowedToBudget]);

  const handleShowTabContent = () => {
    if (isSettingsTabActive) {
      return (
        <div className={styles.bloc}>
          <Settings
            preferences={preferences}
            categories={data.categories}
            dataSettings={data.settings}
            startCategories={startCategories}
            setStartCategories={setStartCategories}
            departCategories={departCategories}
            setDepartCategories={setDepartCategories}
            selectedStartDates={selectedStartDates}
            setSelectedStartDates={setSelectedStartDates}
            selectedDepartDates={selectedDepartDates}
            setSelectedDepartDates={setSelectedDepartDates}
            setUpdated={setUpdated}
            setStartDatesActivated={setStartDatesActivated}
            startDatesActivated={startDatesActivated}
            atRiskClientActivated={atRiskClientActivated}
            setAtRiskClientActivated={setAtRiskClientActivated}
            setDepartDatesActivated={setDepartDatesActivated}
            departDatesActivated={departDatesActivated}
            startDatesFacturation={startDatesFacturation}
            setStartDatesFacturation={setStartDatesFacturation}
            startDatesTask={startDatesTask}
            setStartDatesTask={setStartDatesTask}
            departDatesFacturation={departDatesFacturation}
            setDepartDatesFacturation={setDepartDatesFacturation}
            departDatesTask={departDatesTask}
            setDepartDatesTask={setDepartDatesTask}
            preferentialTariff={preferentialTariff}
            setPreferentialTariff={setPreferentialTariff}
            setNormalVoyant={setNormalVoyant}
            setMediumVoyantFrom={setMediumVoyantFrom}
            setMediumVoyantTo={setMediumVoyantTo}
            setHighVoyant={setHighVoyant}
            normalVoyant={normalVoyant}
            mediumVoyantFrom={mediumVoyantFrom}
            mediumVoyantTo={mediumVoyantTo}
            highVoyant={highVoyant}
            communicatedDeparture={communicatedDeparture}
            setCommunicatedDeparture={setCommunicatedDeparture}
            leavingRiskObservation={leavingRiskObservation}
            setLeavingRiskObservation={setLeavingRiskObservation}
          />
        </div>
      );
    }

    if (activeStep === 1 && isAccessAllowed) {
      return (
        <div className={styles.bloc} style={{ height: "500px" }}>
          <ClientInformations
            preferences={preferences}
            updatedClientInformationData={updatedClientInformationData}
            setUpdatedClientInformationData={
              setUpdatedClientInformationData
            }
            setUpdated={setUpdated}
            updatedDataVolumetrie={updatedDataVolumetrie}
            setUpdatedDataVolumetrie={setUpdatedDataVolumetrie}
            handleSaveClientInformation={handleSaveClientInformation}
            squads={squads}
            bu={bu}
            clusters={clusters}
          />
        </div>
      );
    }

    if (isAccessAllowedToBudget &&
      (activeStep === 2 || (activeStep === 1 && !isAccessAllowed))) {
      return (
        <div className={styles.bloc}>
          {updatedDataOffre.length !== 0 && (
            <GlobalBudget
              data={updatedData}
              preferences={preferences}
              clientName={updatedData.client_name}
              updatedDataOffre={updatedDataOffre}
              setUpdatedDataOffre={setUpdatedDataOffre}
              updatedDataCategoryBudget={updatedDataCategoryBudget}
              setUpdatedDataCategoryBudget={
                setUpdatedDataCategoryBudget
              }
              updatedCategoryRoleList={updatedCategoryRoleList}
              setUpdatedCategoryRoleList={setUpdatedCategoryRoleList}
              updatedCollaboratorsList={updatedCollaboratorsList}
              setUpdatedCollaboratorsList={setUpdatedCollaboratorsList}
              totalGD={totalGD}
              totalGE={totalGE}
              totalSE={totalSE}
              totalScanOp={totalScanOp}
              totalSupervisor={totalSupervisor}
              totalGDdelegue={totalGDdelegue}
              totalGEdelegue={totalGEdelegue}
              totalSEdelegue={totalSEdelegue}
              totalScanOpDelegue={totalScanOpDelegue}
              totalSupervisorDelegue={totalSupervisorDelegue}
              settotalGD={settotalGD}
              settotalGE={settotalGE}
              settotalSE={settotalSE}
              settotalScanOp={settotalScanOp}
              settotalsupervisor={settotalsupervisor}
              handleChangeRolesValues={handleChangeRolesValues}
              organizationId={auth.navCommunity.id}
              token={auth.token}
              year={year}
            />
          )}
        </div>
      );
    }
    if (activeStep === 3 ||
      (activeStep === 2 && !isAccessAllowedToBudget) ||
      (activeStep === 1 && !isAccessAllowed)) {
      return (<div className={styles.bloc}>{handleTacheStepBudget()}</div>);
    }

    if (activeStep === 4) {
      return (
        <div className={styles.bloc}>
          <RolesManagement
            preferences={preferences}
            updatedCollaboratorsList={updatedCollaboratorsList}
            setUpdatedCollaboratorsList={setUpdatedCollaboratorsList}
            collaborators={collaborators}
            totalGD={totalGD}
            totalGE={totalGE}
            totalSE={totalSE}
            totalSupervisor={totalSupervisor}
            totalScanOp={totalScanOp}
            setUpdated={setUpdated}
          />
        </div>
      );
    }
  }

  return preferences && preferences.allowConfigurateBudget === "1" ? (
    <div>
      {updatedData.length !== 0 && (
        <div>
          <PageHeader
            header={_("gestion de budget client")}
            subHeader={updatedData.client_name}
            //   icon={ICONS.BUDGET_PAGE_ICON}
          />

          <div className={styles.page_content}>
            <div className={classNames(styles.content, "grid-x")}>
              <div className={styles.sub_titles}>
                {stepsFiltred.length > 0 &&
                  stepsFiltred.map((i) => {
                    return <div>{handleStepsBudget(i)}</div>;
                  })}

                <div className={styles.settings_sub_title}>
                <div
                  className={styles.step}
                  onClick={() => setIsSettingsTabActive(true)}
                >
                  <div className={styles.text}>
                    <div
                      className={classNames({
                        [styles.label]: true,
                        [styles.active_label]: isSettingsTabActive
                      })}
                    >
                      Paramètre client
                    </div>
                  </div>
                </div>
                </div>
              </div>
                {handleShowTabContent()}
            </div>
          </div>
        </div>
      )}
      <div className={styles.actionBar}>
        <div className="grid-container">
          <div className={styles.footer_actions}>
            {!(
              activeStep === 3 &&
              !isAccessAllowedToBudget &&
              !isAccessAllowed
            ) &&
              !(
                activeStep === 2 &&
                isAccessAllowedToBudget &&
                !isAccessAllowed
              ) &&
              activeStep > 1 && !isSettingsTabActive && (
                <button
                  className={styles.button_prev}
                  onClick={() =>
                    activeStep === 3 && !isAccessAllowedToBudget
                      ? setactiveStep(activeStep - 2)
                      : setactiveStep(activeStep - 1)
                  }
                >
                  Retour
                </button>
              )}

            {!isSettingsTabActive && (
              <button
                className={classNames(
                  styles.button_next,
                  (activeStep === 4 || isSettingsTabActive) && styles.last_step,
                  activeStep === 2 &&
                    ((updatedDataCategoryBudget &&
                      updatedDataCategoryBudget[0] &&
                      updatedDataCategoryBudget[0][year] === 0) ||
                      (updatedDataOffre &&
                        updatedDataOffre[0] &&
                        updatedDataOffre[0]["value"] === 0)) &&
                    styles.enabled_button
                )}
                style={{
                  marginLeft:
                    (activeStep === 1 ||
                      (activeStep === 3 &&
                        !isAccessAllowedToBudget &&
                        !isAccessAllowed) ||
                      (activeStep === 2 &&
                        isAccessAllowedToBudget &&
                        !isAccessAllowed)) &&
                    "64%",
                }}
                onClick={() =>
                  activeStep <= 5 && handleChangeStep(activeStep + 1)
                }
                disabled={
                  activeStep === 2 &&
                  updatedDataCategoryBudget &&
                  updatedDataCategoryBudget[0] &&
                  updatedDataCategoryBudget[0][year] === 0
                }
              >
                {activeStep === 4 ? "Enregistrer" : "Continuer"}
              </button>
            )}

            {isSettingsTabActive && (
              <button
                className={classNames({
                  [styles.button_next]: true,
                  [styles.last_step]: true,
                })}
                style={{ marginLeft: "64%" }}
                onClick={() => handleSaveClientBudgetSetting()}
              >
                Enregistrer
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="no_data">
      <div className="title">{_("Oops")}</div>
      <div className="subTitle">{_("Accès non autorisé!")}</div>
    </div>
  );
};

export default Budget;
