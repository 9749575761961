import axios from "axios";
import { graphConfig } from "../config/msal-config";

export async function getCalendars(accessToken) {
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  return axios.get(graphConfig.getCalendarsEndpoint, options)
  .then(response => response.data)
  .catch(error => console.log(error));
}

export async function createEvent(accessToken, eventDetails) {
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  return axios.post(
    graphConfig.createEventEndpoint,
    JSON.stringify(eventDetails),
    options
  )
  .then(response => response.data)
  .catch(error => console.log(error));
}

export async function updateEvent(accessToken, eventId, eventDetails) {
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  const updateEventEndpoint = graphConfig.updateEventEndpoint.replace("@@EVENT_ID@@", eventId);

  return axios.patch(
    updateEventEndpoint,
    JSON.stringify(eventDetails),
    options
  )
  .then(response => response.data)
  .catch(error => console.log(error));
}

export async function deleteEvent(accessToken, eventId){
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  const deleteEventEndpoint = graphConfig.deleteEventEndpoint.replace("@@EVENT_ID@@", eventId);

  return axios.delete(
      deleteEventEndpoint,
      options
  )
  .then(response => response.data)
  .catch(error => console.log(error));
}

export async function getEventCategories(accessToken) {
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  return axios.get(graphConfig.getEventCategoriesEndpoint, options)
  .then(response => response.data)
  .catch(error => console.log(error));
}

export async function createEventCategory(accessToken, categoryDetails) {
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  return axios.post(
      graphConfig.getEventCategoriesEndpoint,
      JSON.stringify(categoryDetails),
      options
  )
  .then(response => response.data)
  .catch(error => console.log(error));
}
