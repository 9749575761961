import axios from "axios";
import { TTP_API_URL } from "../config";

export const getCollaboratorsEcheance = (
  token,
  organizationId,
  partner,
  year,
  zoneId
) => {
  const requestUrl = `${TTP_API_URL}/team/collaboratorsBalance`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organizationId,
      partner: partner,
      year: year,
      zoneId: zoneId,
    },
  });
};

export const getBalancePlannerRecap = (
  token,
  organizationId,
  partner,
  year,
  collaborator,
  keyWord,
  status
) => {
  const requestUrl = `${TTP_API_URL}/team/balance-planner-recap`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organizationId,
      partner: partner,
      year: year,
      collaboratorId: collaborator,
      keyWord: keyWord,
      status: status,
    },
  });
};

export const getCollaboratorBalance = (
  token,
  organizationId,
  partner,
  year,
  collaboratorId,
  zoneId,
  typeBalance,
  closingDate,
  searchWord
) => {
  const requestUrl = `${TTP_API_URL}/team/balance`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organizationId,
      partner: partner,
      collaboratorId: collaboratorId,
      year: year,
      typeBalance: typeBalance,
      closingDate: closingDate,
      zoneId: zoneId,
    },
  });
};

export const getCollaboratorsBonuses = (token, organizationId, filter) => {
  const requestUrl = `${TTP_API_URL}/team/collaborators_bonuses`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organizationId,
      partner: filter.partner,
      year: filter.year,
      limit: filter.pageSize || 10,
      start: !filter.paginationPage
        ? 0
        : (filter.paginationPage - 1) * filter.pageSize,
      zoneId: 1,
    },
  });
};
