import ColumnHeader from "../ColumnHeader/ColumnHeader";
import './tables.scss';
import styles from './RespectProcessingDeadlines.module.scss';
import { DEPOT_TABLE_BY_CLIENT_COLUMNS, DEPOT_TABLE_BY_DEPOT_COLUMNS } from "./constants";
import { Table, Tabs, Tooltip } from "antd";
import { useEffect, useState } from "react";
import BorderedCard from "../BorderedCard/BorderedCard";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getCollaboratorDocsProcessingData } from "../../../../../../api";
import { formatAmount } from "../../../../../../utils";
import { InfoCircleFilledIcon } from "../../../../../common/Icons";

export default function RespectProcessingDeadlines({
  collaboratorId,
  period,
  setDeserveBonus
}) {
  const { token, navCommunity} = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [dataByClients, setDataByClients] = useState([]);
  const [totals, setTotals] = useState({
    respected: 0,
    notProcessedYet: 0
  });

  const getCollaboratorDocsProcessingDataQuery = useQuery(
    ["getCollaboratorDocsProcessingData", token, navCommunity, collaboratorId, period],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorDocsProcessingData({
            token,
            organizationId: navCommunity.id,
            collaboratorId,
            period
          });

          setData(response.data.data.result);
          setDataByClients(response.data.data.resultByClient)
          setTotals({
            respected: response.data.data.docsProcessingRespectedCount,
            notProcessedYet: response.data.data.docsDontProcessedYetCount
          });
        } catch (e) {
          return null;
        }
      }
    }
  );

  useEffect(() => {
    setDeserveBonus(totals.notProcessedYet === 0 && totals.respected === data.length);
  }, [data, dataByClients, totals]);

  useEffect(() => {
    getCollaboratorDocsProcessingDataQuery.refetch();
  }, [collaboratorId, period]);

  const renderByDocumentTable = () => {
    return (
      <Table
        className='deposit_docs_by_document_table'
        columns={DEPOT_TABLE_BY_DEPOT_COLUMNS}
        dataSource={data}
        size='small'
        bordered
        pagination={{
          pageSize: 5
        }}
      />
    );
  };

  const renderByClientTable = () => {
    return (
      <Table
        className='deposit_docs_by_client_table'
        columns={DEPOT_TABLE_BY_CLIENT_COLUMNS}
        dataSource={dataByClients}
        size='small'
        bordered
        pagination={{
          pageSize: 5,
        }}
        summary={(pageData) => {
          let totalDeposits = 0;
          let totalRespectedDeposits = 0;
          let totalNonRespectedDeposits = 0;

          dataByClients.forEach((item) => {
            totalDeposits += item.totalDeposits;
            totalRespectedDeposits += item.totalRespectedDeposits;
            totalNonRespectedDeposits += item.totalNonRespectedDeposits;
          });

          const percentageRespectedDeposits = totalDeposits === 0 ? 0 : totalRespectedDeposits * 100 / totalDeposits;
          const percentageNonRespectedDeposits = totalDeposits === 0 ? 0 : totalNonRespectedDeposits * 100 / totalDeposits;

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} align="left">
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {totalDeposits}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  {totalRespectedDeposits}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  {totalNonRespectedDeposits}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  {percentageRespectedDeposits === 0 || percentageRespectedDeposits === 100 ? percentageRespectedDeposits : formatAmount(percentageRespectedDeposits, false)}%
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  {percentageNonRespectedDeposits === 0 || percentageNonRespectedDeposits === 100 ? percentageNonRespectedDeposits : formatAmount(percentageNonRespectedDeposits, false)}%
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    );
  };

  return (
    <div className={styles.container}>
      <ColumnHeader
        titleValue={data.length === 0 ? 100 : totals.respected * 100 / data.length}
        isTitleValuePositif={data.length === 0 || totals.respected * 100 / data.length === 100}
        title="Respect des délais de traitement"
        subtitleValue={totals.notProcessedYet}
        isSubtitleValuePositif={totals.notProcessedYet === 0}
        subtitle="Nombre de factures restantes"
        price="250"
      />

      <BorderedCard>
        <div className={styles.content}>
          <span className={styles.info_icon}>
            <Tooltip title="Traitement des documents dans un délai maximal de quatre semaines après leur date de
                dépôt">
              <InfoCircleFilledIcon />
            </Tooltip>
          </span>

          <h4>Respect des délais de traitement des documents comptables</h4>

          <Tabs
            className={styles.tabs}
            items={[
              {
                key: '1',
                label: 'Par document',
                children: renderByDocumentTable(),
              },
              {
                key: '2',
                label: 'Par client',
                children: renderByClientTable(),
              },
            ]}
          />

        </div>
      </BorderedCard>
    </div>
  )
}
