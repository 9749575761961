import { useSelector } from "react-redux";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useQuery } from "react-query";
import { getCollaboratorFoldersQuality } from "../../../../../../api";
import { useEffect, useState } from "react";
import styles from './FoldersQuality.module.scss';
import BorderedCard from "../BorderedCard/BorderedCard";
import ColumnHeader from "../ColumnHeader/ColumnHeader";
import { Tooltip } from "antd";
import { ChartIcon, TableIcon } from "../../../../../common/Icons";
import { Bar, CartesianGrid, ComposedChart, LabelList, Legend, XAxis, YAxis, Tooltip as ChartTooltip } from "recharts";
import moment from "moment";

const TABLE_VIEW = 'TABLE_VIEW';
const CHART_VIEW = 'CHART_VIEW';

export default function FoldersQuality({
  collaboratorId,
  period,
  setDeserveBonus: changeDeserveBonusValue
}) {
  const { token, navCommunity} = useSelector((state) => state.auth);
  const [dataView, setDataView] = useState(TABLE_VIEW);
  const [totals, setTotals] = useState({
    totalClients: 0,
    totalClientsWithErrors: 0,
    totalErrorsCount: 0,
    totalSeriousErrorsCount: 0
  });
  const [errorsData, setErrorsData] = useState([]);
  const [deserveBonus, setDeserveBonus] = useState(true);
  const [columnTotalPercentage, setColumnTotalPercentage] = useState(0);
  const [tableExpandedRows, setTableExpandedRows] = useState([]);
  const [graphData, setGraphData] = useState({});

  let foldersQualityQuery = useQuery(
    [("getCollaboratorFoldersQuality", token, navCommunity, collaboratorId, period)],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorFoldersQuality({
              token,
              organizationId: navCommunity.id,
              collaboratorId,
              period
            });
          setErrorsData(response.data.data.result);
          setGraphData(response.data.data.resultByMonths);
          setTotals({
            totalClients: response.data.data.totalClients,
            totalClientsWithErrors: response.data.data.totalClientsWithErrors,
            totalErrorsCount: response.data.data.totalErrorsCount,
            totalSeriousErrorsCount: response.data.data.totalSeriousErrorsCount
          })
        } catch (e) {
          console.error(e);
          return null;
        }
      }
    }
  );

  useEffect(() => {
    console.log({graphData});
  }, [graphData])

  useEffect(() => {
    foldersQualityQuery.refetch();
  }, [collaboratorId, period]);

  useEffect(() => {
    if (errorsData) {
      // the collaborator deserve the bonus if he hasn't a grave error
      // and the number of normal errors < 4

      const nbrOfSeriousErrors = errorsData.filter(error => error.numberOfSeriousErrors).length;
      const nbrOfNormalErrors = errorsData.filter(error => !error.numberOfSeriousErrors).length;

      // console.log({nbrOfSeriousErrors, nbrOfNormalErrors, errorsData});
      if (nbrOfSeriousErrors > 0 || nbrOfNormalErrors > 3) {
        setDeserveBonus(false);
        changeDeserveBonusValue(false);
      } else {
        setDeserveBonus(true);
        changeDeserveBonusValue(true);
      }

      setColumnTotalPercentage(totals.totalClients > 0 ? (100 - (totals.totalClientsWithErrors * 100 / totals.totalClients)) : 100)
    }
  }, [errorsData, totals]);

  const headerTemplate = (data) => {
    return (
      <span>{data.clientName}</span>
    );
};


  return (
    <div className={styles.container}>
      <ColumnHeader
        titleValue={columnTotalPercentage}
        title="Qualité des dossiers"
        isTitleValuePositif={deserveBonus}
        subtitleValue={totals.totalErrorsCount}
        subtitle="Nbr d'erreurs"
        isSubtitleValuePositif={deserveBonus}
        price="500"
      />

      <BorderedCard>
        <div className={styles.content}>
          <div className={styles.data_view_type}>
            <Tooltip title="Tableau">
              <TableIcon
                size={18}
                stroke={dataView === TABLE_VIEW ? '#1a90ff' : "#000"}
                style={{ cursor: 'pointer' }}
                onClick={() => setDataView(TABLE_VIEW)}
              />
            </Tooltip>
            <Tooltip title="Graphes">
              <ChartIcon
                size={18}
                stroke={dataView === CHART_VIEW ? '#1a90ff' : "#000"}
                style={{ cursor: 'pointer' }}
                onClick={() => setDataView(CHART_VIEW)}
              />
            </Tooltip>
          </div>

          {dataView === TABLE_VIEW && (
            <DataTable
              value={errorsData}
              rowGroupMode="subheader"
              groupRowsBy="clientName"
              expandableRowGroups
              expandedRowIcon={
                <span>-</span>
              }
              collapsedRowIcon={
                <span>+</span>
              }
              rowGroupHeaderTemplate={headerTemplate}
              expandedRows={tableExpandedRows}
              onRowToggle={(e) => setTableExpandedRows(e.data)}
              sortField="clientName"
              size="small"
              cellClassName={styles.errors_table_cell}
              tableClassName={styles.errors_table}
               // paginator
              // paginatorClassName={styles.errors_table_paginator}
              // alwaysShowPaginator={false}
              // rows={10}
            >
              {/* <Column field="clientName" header="Client"></Column> */}
              <Column
                field="reasonCategoryName"
                header="Errors"
                style={{
                  width: '114px'
                }}
              />
              <Column
                field="numberOfReports"
                header="Nbr de rapports"
              />
              <Column
                field="numberOfSeriousErrors"
                header="Nbr des erreurs grave"
              />
              {/* <Column field="reportStatus" header="Statut de rapport"></Column> */}
            </DataTable>
          )}

          {dataView === CHART_VIEW && (
            <ComposedChart
              width={300}
              height={220}
              data={graphData}
              margin={{
                top: 0,
                right: 0,
                left: -40,
                bottom: 0,
              }}
              barSize={16}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="monthAbbr" />
              <YAxis />
              <ChartTooltip content="Bonjour" />
              <Legend align="right" />
              <Bar
                name="Le nombre total des erreurs"
                dataKey="numberTotalOfErrors"
                stackId="a"
                fill="#FBA834"
                label="Nombre total des erreurs"
              >
                <LabelList dataKey="numberTotalOfErrors"  fontSize={8} fill="white" />
              </Bar>
              <Bar
                name="Le nombre total des erreurs graves "
                dataKey="numberTotalOfSeriousErrors"
                stackId="a"
                fill="#EB5757"
              >
                <LabelList dataKey="numberTotalOfSeriousErrors"  fontSize={8} fill="white" />
              </Bar>
            </ComposedChart>
          )}
        </div>
      </BorderedCard>
    </div>
  )
}
