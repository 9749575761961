import { React, useState, useEffect } from "react";
import styles from "./Budget.module.scss";
import './calendar.scss';
import classNames from "classnames";
import _ from "../../i18n";
import moment from "moment";

// import DatePicker from "react-datepicker"
import { CalendarIcon } from "../common/Icons";
import { Calendar } from "primereact/calendar";
import { DatePicker, Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";
const Settings = ({
  preferences,
  dataSettings,
  startCategories,
  setStartCategories,
  departCategories,
  setDepartCategories,
  selectedStartDates,
  setSelectedStartDates,
  selectedDepartDates,
  setSelectedDepartDates,

  setStartDatesActivated,
  departActivated,
  startDatesActivated,
  atRiskClientActivated,
  setAtRiskClientActivated,
  departDatesActivated,
  setDepartDatesActivated,
  startDatesFacturation,
  setStartDatesFacturation,
  startDatesTask,
  setStartDatesTask,
  departDatesFacturation,
  setDepartDatesFacturation,
  departDatesTask,
  setDepartDatesTask,
  preferentialTariff,
  setPreferentialTariff,
  setUpdated,
  setNormalVoyant,
  setMediumVoyantFrom,
  setMediumVoyantTo,
  setHighVoyant,
  normalVoyant,
  mediumVoyantFrom,
  mediumVoyantTo,
  highVoyant,
  communicatedDeparture,
  setCommunicatedDeparture,
  leavingRiskObservation,
  setLeavingRiskObservation
}) => {
  const [selectedTab, setSelectedTab] = useState("NewClient");
  // const [isNewClientChecked, setIsNewClientChecked] = useState(startDatesFacturation || startDatesTask);
  // const [isClientAtRiskChecked, setIsClientAtRiskChecked] = useState(leavingRiskObservation !== "" && leavingRiskObservation !== null);
  // const [isDepartClientChecked, setIsDepartClientChecked] = useState(communicatedDeparture || departDatesFacturation || departDatesTask);
  const [isPreferentialTariffChecked, setIsPreferentialTariffChecked] = useState(preferentialTariff !== 0);

  const handleChangeValues = (rowIndex, column, list) => {
    if (list === "start") {
      setStartCategories((prevStartCategories) => {
        return prevStartCategories.map((item, index) => {
          if (index === rowIndex) {
            return {
              ...item,
              [column]: !item.checked,
            };
          } else {
            return item;
          }
        });
      });
    } else {
      setDepartCategories((prevDepartCategories) => {
        return prevDepartCategories.map((item, index) => {
          if (index === rowIndex) {
            return {
              ...item,
              [column]: !item.checked,
            };
          } else {
            return item;
          }
        });
      });
    }
    setUpdated(true);
  };

  const renderActiveSettingContent = () => {
    switch(selectedTab) {
      case 'NewClient': return (
        <>
          <div className={styles.settings}>
            <div className={styles.title_settings}>
              Nouveau client
            </div>
            <div>
              <div className={styles.calendar_content}>
                <div className={styles.date_title}>
                  Début de facturation
                </div>
                <DatePicker
                  className="calendar_input"
                  format="DD/MM/YYYY"
                  value={startDatesFacturation || moment()}
                  locale="fr"
                  allowClear={false}
                  placeholder="Selectionner une date"
                  popupClassName="datepicker_popup"
                  onSelect={(date) => {
                    if (moment(date).isValid()) {
                      setStartDatesFacturation(date);
                    } else {
                      setStartDatesFacturation(moment());
                    }
                  }}
                />
              </div>
              <div className={styles.calendar_content}>
                <div className={styles.date_title}>
                  Début de prestation
                </div>
                <DatePicker
                  className="calendar_input"
                  format="DD/MM/YYYY"
                  value={startDatesTask || moment()}
                  locale="fr"
                  allowClear={false}
                  placeholder="Selectionner une date"
                  popupClassName="datepicker_popup"
                  onSelect={(date) => {
                    if (moment(date).isValid()) {
                      setStartDatesTask(date);
                    } else {
                      setStartDatesTask(moment());
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.categories_list}>
            <div className={styles.categories}>
              categories
              <div>
                {startCategories.map((item, i) => {
                    return (
                      <div className={styles.category_item}>
                        <span>{item.label}</span>
                        <div className={styles.switch}>
                          <Switch
                            checked={item.checked}
                            onChange={() =>
                              handleChangeValues(i, "checked", "start")
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </>
      )
      case 'AtRisk': return (
        <>
          <div className={styles.settings} style={{ height: '100%', width: '100%' }}>
            <div className={styles.title_settings}>
              Client à risque
            </div>
            <div>
              <div className={styles.date_title}>
                Remarque
              </div>
              <TextArea
                style={{ resize: 'none', width: '100%' }}
                rows={7}
                value={leavingRiskObservation}
                onChange={(e) => setLeavingRiskObservation(e.target.value)}
              />
            </div>
          </div>
        </>
      )
      case 'Depart': return (
        <>
          <div className={styles.settings}>
            <div className={styles.title_settings}>
              Depart client
            </div>
            <div>
              <div className={styles.calendar_content}>
                <div className={styles.date_title}>
                  Fin de facturation
                </div>
                <DatePicker
                  className="calendar_input"
                  format="DD/MM/YYYY"
                  value={departDatesFacturation || moment()}
                  locale="fr"
                  allowClear={false}
                  placeholder="Selectionner une date"
                  popupClassName="datepicker_popup"
                  onSelect={(date) => {
                    if (moment(date).isValid()) {
                      setDepartDatesFacturation(date);
                    } else {
                      setDepartDatesFacturation(moment());
                    }
                  }}
                />
              </div>
              <div className={styles.calendar_content}>
                <div className={styles.date_title}>
                  Fin de prestation
                </div>
                <DatePicker
                  className="calendar_input"
                  format="DD/MM/YYYY"
                  value={departDatesTask || moment()}
                  locale="fr"
                  allowClear={false}
                  placeholder="Selectionner une date"
                  popupClassName="datepicker_popup"
                  onSelect={(date) => {
                    if (moment(date).isValid()) {
                      setDepartDatesTask(date);
                    } else {
                      setDepartDatesTask(moment());
                    }
                  }}
                />
              </div>
              <div className={styles.calendar_content}>
                <div className={styles.date_title}>
                  Date de depart
                </div>
                <DatePicker
                  className="calendar_input"
                  format="DD/MM/YYYY"
                  value={communicatedDeparture || moment()}
                  locale="fr"
                  allowClear={false}
                  placeholder="Selectionner une date"
                  popupClassName="datepicker_popup"
                  onSelect={(date) => {
                    if (moment(date).isValid()) {
                      setCommunicatedDeparture(date);
                    } else {
                      setCommunicatedDeparture(moment());
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.categories_list}>
            <div className={styles.categories}>
              categories
              <div>
                {
                  departCategories.map((item, i) => {
                    return (
                      <div className={styles.category_item}>
                        <span>{item.label}</span>
                        <div className={styles.switch}>
                          <Switch
                            checked={item.checked}
                            onChange={() =>
                              handleChangeValues(i, "checked", "depart")
                            }
                          />
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </>
      )
      default: return null
    }
  }

  return (
    <div>
      <div className={styles.title}>Paramètre client</div>
      <div className="grid-x">
        <div className="cell small-4 medium-4 large-3">
          {(preferences.allowUpdatingNewClients==="1") &&
            <div
              className={classNames(
                styles.group_info,
                selectedTab === "NewClient" && styles.selected_info
              )}
              onClick={() => setSelectedTab("NewClient")}
            >
              <div className={styles.info}>
                <div className={styles.info_content}>
                  <span>Nouveau client :</span>
                  <div>
                    <Switch
                      checked={startDatesActivated}
                      onChange={() =>
                        setStartDatesActivated(!startDatesActivated)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          }
          {(preferences.allowUpdatingDepartClients==="1") &&
            <div
              className={classNames(
                styles.group_info,
                selectedTab === "AtRisk" && styles.selected_info
              )}
              onClick={() => setSelectedTab("AtRisk")}
            >
              <div className={styles.info}>
                <div className={styles.info_content}>
                  <span>Client à risk :</span>
                  <div>
                    <Switch
                      checked={atRiskClientActivated}
                      onChange={() => setAtRiskClientActivated(!atRiskClientActivated)}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
          {(preferences.allowUpdatingDepartClients==="1") &&
            <div
              className={classNames(
                styles.group_info,
                selectedTab === "Depart" && styles.selected_info
              )}
              onClick={() => setSelectedTab("Depart")}
            >
              <div className={styles.info}>
                <div className={styles.info_content}>
                  <span>Départ client :</span>
                  <div>
                    <Switch
                      checked={departDatesActivated}
                      onChange={() => setDepartDatesActivated(!departDatesActivated)}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
          {/* {(preferences.allowUpdatingTarifClients==="1") && (
            <div
              className={classNames(
                styles.group_info,
                selectedTab === "PreferentialTariff" && styles.selected_info
              )}
              onClick={() => setSelectedTab("PreferentialTariff")}
            >
              <div className={styles.info}>
                <div className={styles.info_content}>
                  <span>Tarif préférentiel/ friendzone :</span>
                  <div>
                    <Switch
                      checked={isPreferentialTariffChecked}
                      onChange={() => setIsPreferentialTariffChecked(!isPreferentialTariffChecked)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )} */}
          <div className={styles.info}>
            <div className={styles.info_content}>
              <span>Personne lié :</span>
              {dataSettings.attached.length > 0 &&
                dataSettings.attached.map((item, i) => {
                  return <div>{item.name}</div>;
                })}
            </div>
          </div>
          {(preferences.allowUpdatingBillingView==="1") &&
            <div className={styles.info} style={{ height: "auto" }}>
              <div className={styles.info_content}>
                <span>Voyant de facturation :</span>
                <div className={styles.voyant_goup}>
                  <span
                    className={styles.span}
                    style={{ background: "#06D9B1" }}
                  ></span>
                  <div className={styles.voyant}>
                    {"< "}
                    <input
                      type="number"
                      style={{ width: "46px" }}
                      className={classNames(styles.input)}
                      value={normalVoyant}
                      onChange={(event) => setNormalVoyant(event.target.value)}
                    />
                    {" %"}
                  </div>
                </div>
                <div className={styles.voyant_goup}>
                  <span
                    className={styles.span}
                    style={{ background: "#EA8124" }}
                  ></span>{" "}
                  <div className={styles.voyant}>
                    {"de "}
                    <input
                      type="number"
                      style={{ width: "46px" }}
                      className={classNames(styles.input)}
                      value={mediumVoyantFrom}
                      onChange={(event) => setMediumVoyantFrom(event.target.value)}
                    />
                    {" %"}
                  </div>
                  <div className={styles.voyant}>
                    {"a "}
                    <input
                      type="number"
                      style={{ width: "46px" }}
                      className={classNames(styles.input)}
                      value={mediumVoyantTo}
                      onChange={(event) => setMediumVoyantTo(event.target.value)}
                    />
                    {" %"}
                  </div>
                </div>
                <div className={styles.voyant_goup}>
                  <span
                    className={styles.span}
                    style={{ background: "#EB5757" }}
                  ></span>{" "}
                  <div className={styles.voyant}>
                    {" > "}
                    <input
                      type="number"
                      style={{ width: "46px" }}
                      className={classNames(styles.input)}
                      value={highVoyant}
                      onChange={(event) => setHighVoyant(event.target.value)}
                    />
                    {" %"}
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        {selectedTab !== "" && (
          <div
            className={classNames("cell small-4 medium-4 large-9")}
            style={{}}
          >
            <div
              className={styles.line}
              style={{
                display: "flex",
                height: '100%'
              }}
            >
              {renderActiveSettingContent()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
