import React, { useState } from "react";
import "./Planner.local.scss";
import _ from "../../../i18n";
import { formatDuration } from "../../../utils";
import * as XLSX from "xlsx";
import { IconChevronDown } from "../../common/Icons";
import { Link } from "react-router-dom";
import Table from "../../Reporting/BalancesRecap/Table";

const ListView = ({
  organisationId,
  renderCustomAddOn,
  tableVueSelected,
  collaboratorId,
  collaboratorName,
  selectedParentId,
  setSelectedParentId,
  childrens,
  setChildrens,
  schemaTypeTabs,
  selectedShemaType,
  setSelectedShemaType,
  transformedData,
  transformedDataClients,
  handleNextWeek,
  handlePreviousWeek,
  startDate,
  endDate,
  totalTimeByWeek,
  totalRealTimeByWeek,
  totalRealTimeNFByWeek,
  overtimeByWeek,
  countDevelopementCardAndReports,
}) => {
  const [filterRecapBalances, setFilterRecapBalances] = useState({
    year: new Date().getFullYear().toString(),
    keyWord: "",
    collaborator: collaboratorId,
    status: "",
  });
  const calendarTabs = [
    {
      id: 1,
      label: "Vue Time sheet",
      title: "Vue Time sheet",
    },
    {
      id: 2,
      label: "balances",
      title: "balances",
    },
  ];
  const lineTimeSheet = (item, isParent) => {
    return (
      <div
        className={`line_time_sheet ${
          isParent
            ? selectedParentId === item.id
              ? "selected_line"
              : ""
            : "selected_child"
        }`}
        onClick={() => {
          if (isParent) {
            setChildrens(item.childrens);
            setSelectedParentId(item.id);
          }
        }}
      >
        <div className={`info ${!isParent && "child_info"}`}>{item.label}</div>
        <div className="tag" style={{ width: "86px" }}>
          <div className="content">{formatDuration(item.estimated)}</div>
        </div>
        <div className="tag">
          <div
            className="content tag-success"
            style={{ width: "86px", marginLeft: "45px" }}
          >
            {formatDuration(item.realized)}
          </div>
        </div>
        <div className="tag">
          <div
            className={`${
              item.realized > item.estimated
                ? "content tag-danger"
                : "content tag-success"
            }`}
            style={{ width: "96px" }}
          >
            {formatDuration(item.ecart)}
          </div>
        </div>
        <div className="action">{isParent && <IconChevronDown />}</div>
      </div>
    );
  };

  const exportToExcel = (data) => {
    const flattenedData = data.flatMap((category) => {
      const categoryRow =
        selectedShemaType === 1
          ? {
              Category: category.label,
              Client: "total",
              Estimé: category.estimated,
              Réalisé: category.realized,
              Ecart: category.ecart,
            }
          : {
              Client: category.label,
              Category: "total",
              Estimé: category.estimated,
              Réalisé: category.realized,
              Ecart: category.ecart,
            };

      const childrenRows = category.childrens.map((child) =>
        selectedShemaType === 1
          ? {
              Category: "",
              Client: child.label,
              Estimé: child.estimated,
              Réalisé: child.realized,
              Ecart: child.ecart,
            }
          : {
              Client: "",
              Category: child.label,
              Estimé: child.estimated,
              Réalisé: child.realized,
              Ecart: child.ecart,
            }
      );

      return [categoryRow, ...childrenRows];
    });

    const ws = XLSX.utils.json_to_sheet(flattenedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");

    const fileName =
      selectedShemaType === 1
        ? "Planning_client_time_sheet.xlsx"
        : "Planning_category_time_sheet.xlsx";
    XLSX.writeFile(wb, fileName);
  };

  return (
    <div>
      {/* {renderCustomAddOn(tableVueSelected, calendarTabs, "balances")} */}

      {tableVueSelected === 2 ? (
        <Table filter={filterRecapBalances} />
      ) : (
        <div>
          <div className="calendar-header line_content">
            <div onClick={() => handlePreviousWeek()}>Semaine précédente</div>
            <div className="line_content calendar_informations">
              Du
              <h3>{startDate.format("dddd D MMMM")} </h3>au
              <h3> {endDate.format("dddd D MMMM YYYY")}</h3>
            </div>
            <div onClick={() => handleNextWeek()}>Semaine Suivante</div>
          </div>
          <div>
            <div className="calendar_informations">
              <div className="informations">Total des heures à réaliser : </div>
              <div>
                {totalTimeByWeek.totalHours} h{totalTimeByWeek.remainingMinutes}{" "}
                min
              </div>
            </div>

            <div className="calendar_informations">
              <div className="informations">Total des heures réalisées : </div>
              <div>
                {totalRealTimeByWeek.totalHours} h
                {totalRealTimeByWeek.remainingMinutes} min
              </div>
            </div>

            <div className="calendar_informations">
              <div className="informations"> Heures supplémentaires : </div>
              <div>
                {overtimeByWeek.totalHours} h{overtimeByWeek.remainingMinutes}{" "}
                min
              </div>
            </div>
          </div>
          <div className="calendar_informations">
            <div className="informations">
              Total des heures non facturées réalisées :{" "}
            </div>
            <div>
              {totalRealTimeNFByWeek.totalHours} h
              {totalRealTimeNFByWeek.remainingMinutes} min
            </div>
          </div>
          <div className="calendar_informations">
            <div className="informations">
              {" "}
              Nombre de cartes de correction :{" "}
            </div>
            <div>{countDevelopementCardAndReports.countDevelopementCard}</div>
          </div>
          <div className="calendar_informations">
            <div className="informations"> Nombre de rapports d'erreurs : </div>
            <div>{countDevelopementCardAndReports.countErrorReport}</div>
          </div>
          <div className="calendar-header line_content">
            <div
              style={{
                display: "flex",
                width: "100%",
                right: "0px",
                alignItems: "center",
              }}
            >
              Schéma :
              {schemaTypeTabs.map((tab, index) => (
                <div
                  key={tab.id}
                  className={`line_content ${
                    selectedShemaType === tab.id ? "active_type" : ""
                  }`}
                  onClick={() => setSelectedShemaType(tab.id)}
                >
                  <div
                    className={`tab_list line_content ${
                      selectedShemaType === tab.id ? "active_type" : ""
                    }`}
                  >
                    {index === 1
                      ? selectedShemaType === 1
                        ? "Client"
                        : "Catégory"
                      : selectedShemaType === 1
                      ? "Catégory"
                      : "Client"}
                  </div>
                  <div
                    className={`tab_list line_content ${
                      selectedShemaType === tab.id ? "active_type" : ""
                    }`}
                  >
                    {index === 0 && "->"}
                  </div>
                </div>
              ))}
              <div
                className="order"
                onClick={() => {
                  setSelectedShemaType(selectedShemaType === 1 ? 2 : 1);
                }}
              >
                Inverser l’arborescence
              </div>
              <div className="export_action">
                <button
                  onClick={() =>
                    exportToExcel(
                      selectedShemaType === 1
                        ? transformedData
                        : transformedDataClients
                    )
                  }
                >
                  Export to Excel
                </button>
              </div>
              <div className="export_action">
                {console.log("startDatee====", startDate, endDate)}
                <Link
                  to={`/fr/time-sheet/${collaboratorId}/${collaboratorName}/${startDate.toISOString()}/${endDate.toISOString()}`}
                >
                  <div className="action">
                    <button>Go to time Sheet</button>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className={"line_time_sheet"}>
            <div className={`info`}></div>

            <div className="tag">
              <div className="title_tabs_ti">Estimé</div>
            </div>
            <div className="tag">
              <div className="title_tabs_ti ">Réalisé</div>
            </div>
            <div className="tag">
              <div className="title_tabs_ti ">Ecart</div>
            </div>

            <div className="action"></div>
          </div>

          {selectedShemaType === 1 &&
            (transformedData.length > 0 ? (
              transformedData.map((item) => (
                <div key={item.id}>
                  {lineTimeSheet(item, true)}

                  {selectedParentId === item.id &&
                    childrens &&
                    childrens.map((child) => lineTimeSheet(child, false))}
                </div>
              ))
            ) : (
              <div className="no_data">
                <div className="title">{_("noDataFound")}</div>
                <div className="subTitle">{_("noClientsFound")}</div>
              </div>
            ))}
          {selectedShemaType === 2 &&
            (transformedDataClients.length > 0 ? (
              transformedDataClients.map((item) => (
                <div key={item.id}>
                  {lineTimeSheet(item, true)}

                  {selectedParentId === item.id &&
                    childrens &&
                    childrens.map((child) => lineTimeSheet(child, false))}
                </div>
              ))
            ) : (
              <div className="no_data">
                <div className="title">{_("noDataFound")}</div>
                <div className="subTitle">{_("noClientsFound")}</div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default ListView;
